import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class FCToasterService {
  constructor(private toaster: ToastrService) { }

  showError(message: string) {
    if (message !== null && message !== undefined)
      this.toastClear();
    return this.toaster.error(message, "", {
      enableHtml: true,
      closeButton: true,
      timeOut: 10000,
      tapToDismiss: false,
      progressBar: true
    });
  }

  showSuccess(message: string) {
    if (message !== null && message !== undefined)
      this.toastClear();
    return this.toaster.success(message, "", {
      enableHtml: true,
      closeButton: true,
      timeOut: 10000,
      tapToDismiss: false,
      progressBar: true
    });
  }

  showWarning(message: string) {
    if (message !== null && message !== undefined)
      this.toastClear();
    return this.toaster.warning(message, "", {
      enableHtml: true,
      closeButton: true,
      timeOut: 10000,
      tapToDismiss: false,
      progressBar: true
    });
  }

  showInformation(message: string) {
    if (message !== null && message !== undefined)
      this.toastClear();
    return this.toaster.info(message, "", {
      enableHtml: true,
      closeButton: true,
      timeOut: 10000,
      tapToDismiss: false,
      progressBar: true
    });
  }

  toastClear() {
    this.toaster.clear();
  }
}
