<div id="unithistory-main-wraper">
    <div class="col-12 page-heading p-0">
        <h5>Unit History</h5>
    </div>
    <div class="col-12 search-ft p-0 mt-3">
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label name="lblUnitSrch" class="control-label">Unit Number</label>
                    <input type="text" class="form-control" name="txtUnitSrch" [(ngModel)]="txtUnitSrch" maxlength="20"
                        tabindex="1" (keydown.enter)="onKeyEnter()">
                </div>
            </div>
            <div class="col-sm-3 d-inline-flex p-0">
                <div class="col d-flex align-items-end-new">
                    <div class="form-group">
                        <button class="btn btn-primary mr-2" name="btnUnitSearch" (click)="getGridDataOnSearch()"
                            tabindex="2">Search</button>
                        <button class="btn btn-outline-primary mr-2" name="btnUnitReset" (click)="resetFilters()"
                            tabindex="3">Reset</button>
                    </div>
                </div>
            </div>
            <div id="gridActions" class="ml-auto">
                <div> </div>
                <label class="mr-2" name="lblPRExport" (click)="exportExcel()" tabindex="4"><span>
                        <em class="fa fa-download"></em></span> Export</label>
            </div>
        </div>
    </div>
</div>
<div>
    <ag-grid-angular #agGridGateway [enableCellTextSelection]="true" class="ag-theme-material mt-2 unithistory-layout"
        [rowData]="rowData" [columnDefs]="columnDefs" [gridOptions]="gridOptions" tabindex="5">
    </ag-grid-angular>
</div>