import { Component } from '@angular/core';
import { OAuthService, NullValidationHandler } from 'angular-oauth2-oidc';
import { HttpClient } from '@angular/common/http';
import { Spinkit } from 'ng-http-loader';
import { Service } from './service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppConfigService } from './services/app-config.service';
import { AuthConfig } from 'angular-oauth2-oidc';
import { AppVersion } from './common/models/appversion';
import { LoggingService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FCWeb';
  isSortMenu = false;
  isAutenticate: boolean = false;
  spinnerStyle = Spinkit;
  loginName: string = ""
  loginEmail: string = "";
  userDetails = {};
  claimObj: any = [];
  isAdmin: boolean = false;
  timestamp: number;
  currentTime: number;
  closeResult: string;
  authConfig: AuthConfig = new AuthConfig();
  discoveryDocumentUrl: string;
  appVersion: AppVersion = new AppVersion();
  returnUrl: string = "";

  constructor(private oauthService: OAuthService, private apiService: Service, private router: Router,
    private modalService: NgbModal, private httpClient: HttpClient, private appConfigService: AppConfigService,
    private loggingService: LoggingService, private route: ActivatedRoute) {
    let url = window.location.href.toString();
    this.userDetails = {};



    if (url.indexOf(appConfigService.passwordResetErrorCode) > 0) {
      window.location.href = appConfigService.passwordResetUrl;
    }
    else {
      this.authConfig.redirectUri = appConfigService.redirectUri;
      this.authConfig.responseType = appConfigService.responseType;
      this.authConfig.issuer = appConfigService.issuer;
      this.authConfig.strictDiscoveryDocumentValidation = appConfigService.strictDiscoveryDocumentValidation;
      this.authConfig.tokenEndpoint = appConfigService.tokenEndpoint;
      this.authConfig.loginUrl = appConfigService.loginUrl;
      this.authConfig.clientId = appConfigService.clientId;
      this.authConfig.scope = appConfigService.scope;
      this.authConfig.skipIssuerCheck = appConfigService.skipIssuerCheck;
      this.authConfig.clearHashAfterLogin = appConfigService.clearHashAfterLogin;
      this.authConfig.oidc = appConfigService.oidc;

      this.discoveryDocumentUrl = appConfigService.DiscoveryDocumentUrl;

      this.configure();

      //Access token refresh in background before token expiry time
      this.oauthService.setupAutomaticSilentRefresh();
    }      
  }
  
  ngOnDestroy(): void {
    sessionStorage.clear();
  }

  login() {
    this.oauthService.initImplicitFlow();    
  }

  logout() {
    this.isAutenticate = false;
    this.oauthService.logOut();
    sessionStorage.clear();
  }

  get allClaims() {
    let claims = this.oauthService.getIdentityClaims();
    if (claims !== null && claims !== undefined && this.isAutenticate === false) {      
      this.isAutenticate = true;
      this.getLoggedInUserDetails(claims["oid"]);
      this.claimObj = claims;      
    }
    return claims ? claims : null;
  }

  configure() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    if (this.oauthService.getAccessTokenExpiration()) {
      this.timestamp = this.oauthService.getAccessTokenExpiration();
      this.currentTime = Date.now();
      if (this.currentTime >= this.timestamp) {
        this.oauthService.logOut();
        this.isAutenticate = false;
      }
    }

    if (this.oauthService.getAccessToken() === null) {
      this.oauthService.loadDiscoveryDocument(this.discoveryDocumentUrl).then
        (
          (success) => {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
            if(this.returnUrl != undefined || this.returnUrl != null) {
               sessionStorage.setItem('returnUrl', this.returnUrl);
            }
            this.oauthService.tryLoginImplicitFlow();
          },
          (onrejected) => {
          });
    }
    else {
      this.oauthService.tryLoginImplicitFlow();
      this.oauthService.restartSessionChecksIfStillLoggedIn();
    }
  }

  //Get login user details from DB
  getLoggedInUserDetails(aadObjId: string) {
    if (this.userDetails !== null && this.userDetails !== undefined) {
      this.apiService
        .getUserDetails(aadObjId)
        .subscribe((data: any) => {
          if (data != null) {
            this.userDetails = data;
            this.loginName = this.userDetails["displayName"];
            this.loginEmail = this.userDetails["emailId"];
            this.isAdmin = this.apiService.isAdmin();
            sessionStorage.setItem('role', this.userDetails["role"]);
            sessionStorage.removeItem('returnUrl');
          }
        }, (error) => {
        });
    }
  }

  //Open the Verison Info popup
  open(content) {
    this.apiService
        .getVersionInfo()
        .subscribe((data: any) => {
          if (data != null) {
            this.appVersion.AppName=data.appName;
            this.appVersion.AppVersion=data.appVersion;
            this.appVersion.CIMSVersion=data.cimsVersion;
            this.appVersion.G3MSVersion=data.g3MSVersion;
            this.appVersion.BuildDate=data.buildDate;
          }
        }, (error) => {
      });
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  //Closing the modal popup
  modalClose(content) {
    this.modalService.dismissAll();
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openUserManual() {
    this.httpClient.get("assets/usermanual/UserManual.pdf", { responseType: 'blob' }).subscribe(data => {
      if (data != null) {
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL, '_blank');
      }
    });
  }
}
