import { CpibDeviceTypes } from './../common/models/cpibdevicetypes';
import { BIDataProcess } from './../common/models/bidataprocess';
import { GridOptions } from '@ag-grid-community/all-modules';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { FCToasterService } from '../common/toaster/FCToasterService';
import { Service } from '../service';
import * as XLSX from "xlsx";

@Component({
  selector: 'app-biprovision',
  templateUrl: './biprovision.component.html',
  styleUrls: ['./biprovision.component.scss']
})
export class BiprovisionComponent implements OnInit {
  @ViewChild("agGridBiProvision", { static: false }) agGridBiProvision: AgGridAngular;  
  rowData: any[];
  columnDefs: any[];
  gridOptions: GridOptions;
  isRowSelected: boolean = false;
  rowSelectedData: {};
  deviceTypeError: string = "";
  LDdeviceTypeError: string = "";
  UDdeviceTypeError: string = "";
  closeResult: string;
  txtUnitSrch: string;
  selectedUnitsInfo = [];
  selectedUnits = [];
  commPaths = [];
  commPathSelectedId: number;
  commPathSelectedValue: string;
  deviceTypes = [];
  deviceTypelist: any[];
  LDdeviceTypes: any[];
  UDdeviceTypes: any[];
  deviceTypeSelectedId: number;
  deviceTypeSelectedValue: string;
  LDdeviceTypeSelectedId: number;
  UDdeviceTypeSelectedId: number;
  selectedDeviceTypeId: number;
  biDataProcess = {} as BIDataProcess;
  txtFilePath: string;
  fileToUpload: File;
  biModData = [];
  excelBuffer: any;
  updatedFile: File;
  fileUpload: any;
  unitFound: boolean = false;
  unitFoundFile: boolean = false;
  isDoubleDeckUnit: boolean = false;
  disableDD: boolean = false;
  deviceTypeId2: number;
  LDUdeviceTypeSelectedId: number;
  UDUdeviceTypeSelectedId: number;
  LDUdeviceTypeError: string = "";
  UDUdeviceTypeError: string = "";

  constructor(private apiService: Service, private modalService: NgbModal,
    private toastr: FCToasterService) { }

  ngOnInit(): void {
    this.gridOptions = {
      defaultColDef: { filter: true },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true
    }
    this.bindGridOnPageLoad();
    this.bindDefaultValuesOnPageLoad();
    this.getCommunicationPaths();
  }

  // Assign default values to variables on Page load
  bindDefaultValuesOnPageLoad() {
    this.isRowSelected = false;
    this.rowSelectedData = {};
    this.deviceTypeError = "";
    this.rowData = [];
    this.selectedUnits = [];
    this.selectedUnitsInfo = [];
    this.txtUnitSrch = "";
    this.commPaths = [];
    this.commPathSelectedId = 0;
    this.commPathSelectedValue = "";
    this.deviceTypes = [];
    this.deviceTypeSelectedId = 0;
    this.deviceTypeSelectedValue = "";
    this.selectedDeviceTypeId = 0;
    this.txtFilePath = "";
    this.fileToUpload = null;
    this.biModData = [];
    this.excelBuffer = "";
    this.updatedFile = null;
    this.fileUpload = "";
    this.unitFound = false;
    this.unitFoundFile = false;    
    this.clearDDFields();
  }

  //Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "", field: "cbox", checkboxSelection: true, width: 70 },
      { headerName: "File Id", field: "fileId", sortable: true, width: 140, tooltipField: "fileId", headerTooltip: "File Id" },
      { headerName: "Unit Number", field: "elevatorUnitNumber", sortable: true, width: 180, tooltipField: "elevatorUnitNumber", headerTooltip: "Unit Number" },
      { headerName: "Elevator Type", field: "elevatorType", sortable: true, width: 170, resizable: true, tooltipField: "elevatorType", headerTooltip: "Elevator Type" },      
      { headerName: "Building Name", field: "buildingName", sortable: true, width: 300, tooltipField: "buildingName", headerTooltip: "Building Name" },
      { headerName: "Building Address", field: "address", sortable: true, width: 350, resizable: true, tooltipField: "address", headerTooltip: "Building Address" },
      { headerName: "City", field: "city", sortable: true, width: 200, resizable: true, tooltipField: "city", headerTooltip: "City" },
      { headerName: "Postal Code", field: "postalCode", sortable: true, width: 220, resizable: true, tooltipField: "postalCode", headerTooltip: "Postal Code" },
      { headerName: "Contract Number", field: "contractNumber", sortable: true, width: 250, resizable: true, tooltipField: "contractNumber", headerTooltip: "Contract Number" }
      
    ];
    this.rowData = [];
    this.getMasterData();
  }

  getMasterData() {
    this.getCommunicationPaths();
    this.getDeviceTypes();
  }
  //Get the communication paths
  getCommunicationPaths() {
    this.apiService
      .getCommunicationPaths()
      .subscribe((data: any) => {
        this.commPaths = data;
      });
  }

  //Get the Device Types
  getDeviceTypes() {
    this.apiService
      .getDeviceTypes()
      .subscribe((data: any) => {
        this.deviceTypelist = data;
        this.deviceTypes = data.filter(t=>t.elevatorTypeId === 1);
        this.bindDeviceTypeDefaultIds();  
        
      });
  }

  bindDeviceTypeDefaultIds()
  {
    if(this.deviceTypelist != undefined){
    this.LDdeviceTypes = this.deviceTypelist.filter(t=>t.elevatorTypeId === 2 && t.deckTypeId === 1);
    this.LDdeviceTypeSelectedId = this.LDUdeviceTypeSelectedId = this.LDdeviceTypes.filter(t=>t.id)[0]["deviceTypeId"];
    this.UDdeviceTypes = this.deviceTypelist.filter(t=>t.elevatorTypeId === 2 && t.deckTypeId === 2);
    this.UDdeviceTypeSelectedId = this.UDUdeviceTypeSelectedId = this.UDdeviceTypes.filter(t=>t.id)[0]["deviceTypeId"];
    }    
  }

  clearDDFields()
  {
    this.disableDD = false;
    this.isDoubleDeckUnit = false;
    this.bindDeviceTypeDefaultIds();
    this.deviceTypeId2 = 0;
    this.LDdeviceTypeError = "";
    this.UDdeviceTypeError = "";
    this.LDUdeviceTypeError = "";
    this.UDUdeviceTypeError = "";
  }
  
  onRowSelected(event) {
    if (event.node.selected) {
      this.isRowSelected = true;
      this.selectedUnitsInfo = [];
      this.selectedUnitsInfo.push(event.node.data);
      this.selectedUnits.push(event.node.data.elevatorUnitNumber);
      this.rowSelectedData = this.selectedUnitsInfo;
      var elevatorType;
      elevatorType = this.rowSelectedData[0].elevatorType.toUpperCase().replace(/ /g, '');
      if(elevatorType.includes("DOUBLEDECK"))
      {
        this.isDoubleDeckUnit = true; this.disableDD = true;  
      }      
    }
    else {
      this.isRowSelected = false;
      this.rowSelectedData = {};
      var index = this.selectedUnitsInfo.indexOf(event.node.data);
      if (index >= 0) {
        this.selectedUnitsInfo.splice(index, 1);
        this.selectedUnits.splice(index, 1);
      }
    }
  }

  //Open the upload, Process, Delete modal popup
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onKeyEnter() {
    this.getGridDataOnSearch();    
  }

  modalClose() {
    this.deviceTypeError = "";
    this.commPathSelectedId = 0;
    this.commPathSelectedValue = "";
    this.deviceTypeSelectedId = 0;
    this.deviceTypeSelectedValue = "";
    this.modalService.dismissAll();    
    this.clearDDFields();
  }

  //Get the g3ms unit data on search
  getGridDataOnSearch() {
    this.rowData = [];
    this.isRowSelected = false;
    this.rowSelectedData = {};
    this.unitFound = false;
    this.unitFoundFile = false;
    if (this.txtUnitSrch.trim() !== null && this.txtUnitSrch.trim() !== undefined && this.txtUnitSrch.trim() !== "") {
      this.apiService
        .getBIProvisionUnitBySearch(this.txtUnitSrch)
        .subscribe((data: any) => {
          if (data[0] != null) {
            if (data[0] == "Unit already exist in G3MS") {
              this.toastr.showError("Unit Number already exist in G3MS");
              return;
            }
            this.rowData = data;
            this.unitFound = true;
          }
          else {
            if (this.txtFilePath.trim() !== null && this.txtFilePath.trim() !== undefined && this.txtFilePath.trim() !== "") {
              const reader: FileReader = new FileReader();              
              reader.readAsArrayBuffer(this.fileToUpload);
              reader.onload = (e: any) => {
                const bstr: string = e.target.result;
                const biWorkBook: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
                const biWSName = biWorkBook.SheetNames[0];
                if (biWSName.toUpperCase() !== "BI DATA") {
                  this.toastr.showError("Sheet Name should be BI Data in the selected file");
                  return;
                }
                const ws: XLSX.WorkSheet = biWorkBook.Sheets[biWSName];
                let biData: any[] = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
                let j: number = -1;
                let k: number = -1;
                let stopsInd: number = -1;
                for (let i = 0; i < biData[0].length; i++) {
                  if (biData[0][i] == 'Machine') {
                    j = i;
                  }else if (biData[0][i] == 'Controller Description') {
                    k = i;
                  }
                  else if (biData[0][i] == 'Number Of Stops') {
                    stopsInd = i;
                    break;
                  }
                }
                if (j == -1) {
                  this.toastr.showError("Machine column doesn't exist in the selected file");
                  return;
                }
                for (let i = 1; i < biData.length; i++) {
                  if (this.txtUnitSrch.toUpperCase() == biData[i][j].toUpperCase()) {
                    this.unitFound = true;
                    var controllerDesc = biData[i][k];
                    var stops = biData[i][stopsInd];
                    if(controllerDesc.replace(/\s/g, "").toUpperCase().includes("DOUBLEDECK"))
                    {
                      this.isDoubleDeckUnit = true;
                      if(stops < 3)
                      {
                        this.toastr.showError("Landings should be 3 or more for Double Deck Elevators");
                        break;
                      }
                    }
                    else
                    {
                      this.isDoubleDeckUnit = false;
                    }
                    this.biModData.push(biData[0]);
                    this.biModData.push(biData[i]);
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.biModData, { skipHeader: true });
                    const workbook: XLSX.WorkBook = { Sheets: { 'BI Data': worksheet }, SheetNames: ['BI Data'] };
                    this.excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.updatedFile = new File([this.excelBuffer], this.fileToUpload.name, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    
                    this.unitFoundFile = true;
                    this.toastr.showInformation("Unit Number exist in the Selected File, click Upload button to proceed");
                    
                    if(this.isDoubleDeckUnit)
                    {                      
                      this.unitFoundFile = false;                    
                      let lblctrl = document.getElementById('lblopen') as HTMLElement;
                      lblctrl.click();                      
                    }
                    break;
                  }
                }
                if (!this.unitFound) {
                  this.checkUnitFound();
                }
              }
            }
            else {
              this.checkUnitFound();
            }

          }

        });
    }
    else {
      this.toastr.showError("Please enter Elevator Unit Number");
    }
  }
  checkUnitFound() {
    if (!this.unitFound) {
      this.rowData = [];
      this.toastr.showError("Unit Number doesn't exist in G3MS, FC BI Data and Selected File. ");
    }
  }
  //Closing the modal popup
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //Rest fileters in the page
  onResetFilters() {
    this.bindGridOnPageLoad();
    this.bindDefaultValuesOnPageLoad();
  }

  //File process for a selected file
  onBIUnitProcess($event) {
    if ($event !== null && this.rowSelectedData && this.rowSelectedData[0].elevatorUnitNumber !== null) {
      if (this.onBIDataProcessValidations()) {
        this.biDataProcess.elevatorUnitNumber = this.rowSelectedData[0].elevatorUnitNumber;
        this.biDataProcess.fileId = this.rowSelectedData[0].fileId;
        this.biDataProcess.communicationPath = this.commPathSelectedId !== 0 ? this.commPathSelectedValue : "";
        if(this.isDoubleDeckUnit)
        {
          this.biDataProcess.elevatorTypeId = 2;          
          this.biDataProcess.deviceTypeId = this.LDdeviceTypeSelectedId;  
          this.biDataProcess.deviceTypeId2 = this.UDdeviceTypeSelectedId;       
        }
        else
        {
          this.biDataProcess.elevatorTypeId = 1;          
          this.biDataProcess.deviceTypeId = this.selectedDeviceTypeId;        
        }
        this.apiService.BIUnitProcess(this.biDataProcess).subscribe(data => {
          if (data === true) {
            this.toastr.showSuccess("Unit: " + this.rowSelectedData[0].elevatorUnitNumber + " Successfully Processed to G3MS !!");
            this.modalService.dismissAll();
            this.onResetFilters();
          }
          else {
            this.modalService.dismissAll();
          }
        });
      }
    }
    else {
      this.toastr.showError("Selected file should be in UnProcessed or Partially Processed status");
    }
  }

  onBIDataProcessValidations(): boolean {
    if(this.isDoubleDeckUnit)
      {
        this.UDdeviceTypeError = "";
        this.UDdeviceTypeError = "";
        this.UDUdeviceTypeError = "";
        this.UDUdeviceTypeError = "";
          
        if (this.UDdeviceTypeSelectedId === 0) {
          this.UDdeviceTypeError = "Please Select Upper Deck Device Type";
          return false;
        }
        else if (this.LDdeviceTypeSelectedId === 0) {
          this.LDdeviceTypeError = "Please Select Upper Deck Device Type";
          return false;
        }
        else {
          return true;
        }
      }
    else if (this.deviceTypeSelectedId === 0) {
      this.deviceTypeError = "Please Select Device Type";
      return false;
    }
    else {
      this.deviceTypeError = "";
      return true;
    }
  }

  commPathSelectChanged($event) {
    this.commPathSelectedId = $event.target.selectedIndex;
    this.commPathSelectedValue = $event.target.options[$event.target.selectedIndex].text;
  }

  deviceTypeSelectChanged($event) {
    this.deviceTypeSelectedId = this.selectedDeviceTypeId = $event.target.value;
    this.deviceTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
    if (this.deviceTypeSelectedId !== 0) {
      this.deviceTypeError = "";
    }
  }
  LDdeviceTypeSelectChanged($event) {
    this.LDdeviceTypeSelectedId = $event.target.value;
    if (this.LDdeviceTypeSelectedId !== 0) {
      this.LDdeviceTypeError = "";
    }
  }
  UDDeviceTypeSelectChanged($event) {
    this.UDdeviceTypeSelectedId = $event.target.value;
    if (this.UDdeviceTypeSelectedId !== 0) {
      this.UDdeviceTypeError = "";
    }
  }
  LDUploadSelectChanged($event) {
    this.LDUdeviceTypeSelectedId = $event.target.value;
    if (this.LDUdeviceTypeSelectedId !== 0) {
      this.LDUdeviceTypeError = "";
    }
  }
  UDUploadSelectChanged($event) {
    this.UDUdeviceTypeSelectedId = $event.target.value;
    if (this.UDdeviceTypeSelectedId !== 0) {
      this.UDUdeviceTypeError = "";
    }
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length > 1) {
      this.toastr.showError("Multiple files are not allowed");
      return;
    }
    else {
      this.txtFilePath = target.files[0].name;
      this.fileToUpload = target.files[0];
    }
  }
  
  onFileUpload() {
    if (this.updatedFile !== null && this.updatedFile !== undefined) {
      var deviceTypeId, deviceTypeId2, elevatorTypeId = 1;
      if(this.isDoubleDeckUnit)
      {
        deviceTypeId = this.LDUdeviceTypeSelectedId;
        deviceTypeId2 = this.UDUdeviceTypeSelectedId;
        elevatorTypeId = 2;
      }
      else
      {
        deviceTypeId = this.deviceTypeSelectedId;
        deviceTypeId2 = 0;
      }
      this.apiService.uploadFile(this.updatedFile, deviceTypeId, false, elevatorTypeId, deviceTypeId2).subscribe(data => {
        if (data > 0) {
          this.toastr.showSuccess("Successfully Uploaded!!");
          this.modalService.dismissAll();            
          this.getBIProvisionUnitBySearch();
        }
        else {
          this.toastr.showError("Failed to upload the file");
        }
      });

    }
    else {
      this.toastr.showError("Please select a file to upload");
    }
  }
  getBIProvisionUnitBySearch() {
    this.apiService
      .getBIProvisionUnitBySearch(this.txtUnitSrch)
      .subscribe((data: any) => {
        if (data[0] != null) {
          if (data[0] == "Unit already exist in G3MS") {
            this.toastr.showError("Unit Number already exist in G3MS");
            return;
          }
          this.rowData = data;
          this.unitFound = true;
        }        
        else {
          this.rowData = [];
          this.toastr.showError("Unit Number doesn't exist in G3MS, FC BI Data and Selected File. ");
        }
      });
  }
}
