export const environment = {
    production: false,
    serverUrl: "https://fa-fcapi-stag-naa-bghgzgxaokhxs.azurewebsites.net",
    ApiUrl: "https://fa-fcapi-stag-naa-bghgzgxaokhxs.azurewebsites.net",
    APPINSIGHTS_INSTRUMENTATIONKEY: "84216458-232b-4ef2-839f-04c6f65e65e5",
    APPLICATIONINSIGHTS_CONNECTION_STRING: "InstrumentationKey=84216458-232b-4ef2-839f-04c6f65e65e5",
    ApplicationInsightsAgent_EXTENSION_VERSION: "~2",    
    ClearHashAfterLogin: true,
    ClientId: "cbbbac89-d936-46e2-ab86-3ac2f3f386de",
    DiscoveryDocumentUrl: "https://fcstagb2c.b2clogin.com/fcstagb2c.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1_Signin",
    graphProfileApiScope: "user.read",
    graphProfileApiUrl: "https://graph.microsoft.com/v1.0/",
    Issuer: "https://fcstagb2c.b2clogin.com/bb1b2aa4-d7d3-493f-8193-2b8f5383868e/v2.0/",
    LoginUrl: "https://fcstagb2c.b2clogin.com/fcstagb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1_signin",
    Oidc: true,
    PasswordResetErrorCode: "AADB2C90118",
    PasswordResetUrl: "https://fcstagb2c.b2clogin.com/fcstagb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=cbbbac89-d936-46e2-ab86-3ac2f3f386de&nonce=defaultNonce&redirect_uri=https%3A%2F%2Ffc-naa-stag.otiselevator.com&scope=openid&response_type=id_token&prompt=login",
    ResponseType: "token id_token",
    Scope: "https://fcstagb2c.onmicrosoft.com/fcapi-stag-naa/user_impersonation",
    SkipIssuerCheck: true,
    TokenEndpoint: "https://fcstagb2c.b2clogin.com/fcstagb2c.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1_signin",
    WEBSITE_RUN_FROM_PACKAGE: "1",
    XDT_MicrosoftApplicationInsights_Mode: "recommended",
    WEBSITE_ENABLE_SYNC_UPDATE_SITE: true,
    XDT_MicrosoftApplicationInsights_BaseExtensions: "disabled",
    XDT_MicrosoftApplicationInsights_PreemptSdk: "1"
  };
  