import { Component, OnInit, ViewChild } from '@angular/core';
import { Service } from '../service';
import { FCToasterService } from '../common/toaster/FCToasterService';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-unithistory',
  templateUrl: './unithistory.component.html',
  styleUrls: ['./unithistory.component.scss']
})
export class UnithistoryComponent implements OnInit {
  title = 'Unit History';
  @ViewChild("agGridUnitHistory", { static: false }) agGridGateway: AgGridAngular;
  rowData: any[];
  unitNumber: string;
  columnDefs: any[];
  txtUnitSrch: any;
  gridOptions: GridOptions;

  constructor(private apiService: Service, private toastr: FCToasterService) { }
  ngOnInit(): void {
    this.bindGridOnPageLoad();
    this.bindDefaultValuesOnPageLoad();
    this.gridOptions = {
      defaultColDef: { filter: false },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      paginationAutoPageSize: true
    }
  }

  //Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "#", valueGetter: "node.rowIndex + 1", width: 70 },
      { headerName: "UnitLogId", field: "unitLogId", sortable: true, width: 100, tooltipField: "unitLogId", headerTooltip: "UnitLogId", hide: true },
      { headerName: "ActivityId", field: "activityId", sortable: true, width: 100, tooltipField: "activityId", headerTooltip: "Activity Id", hide: true },
      { headerName: "Activity Name", field: "activityName", sortable: true, width: 230, tooltipField: "activityName", headerTooltip: "Activity Name" },
      { headerName: "Details", field: "details", sortable: true, width: 450, tooltipField: "details", headerTooltip: "Details" },
      { headerName: "Created By", field: "createdBy", sortable: true, width: 250, tooltipField: "createdBy", headerTooltip: "Created By" },
      { headerName: "Created Date", field: "createdDate", sortable: true, width: 170, tooltipField: "createdDate", headerTooltip: "Created Date" },
    ];
    this.rowData = [];
  }

  // Assign default values to variables on Page load
  bindDefaultValuesOnPageLoad() {
    this.txtUnitSrch = "";
  }

  onKeyEnter() {
    this.getGridDataOnSearch();
  }

  //Get the unit history data on search
  getGridDataOnSearch() {
    if (this.txtUnitSrch === null || this.txtUnitSrch === undefined || this.txtUnitSrch === "") {
      this.toastr.showError("Please enter the Unit Number");
    }
    else {
      this.apiService
        .getUnitHistory(this.txtUnitSrch)
        .subscribe((data: any) => {
          if (data != null) {
            this.unitNumber = data.unitNumber;
            this.rowData = data.unitActivity;
          }
          else {
            this.rowData = [];
          }
        });
    }
  }

  //Reset filters
  resetFilters() {
    this.bindDefaultValuesOnPageLoad();
    this.bindGridOnPageLoad();
  }

  exportExcel() {
    if (this.rowData !== null && this.rowData.length > 0) {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: this.unitNumber
      };
      this.gridOptions.api.exportDataAsCsv(params);
    }
  }
}