import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GatewayComponent } from './gateway/gateway.component';
import { CommissioningComponent } from './commissioning/commissioning.component';
import { HomeComponent } from './home/home.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { ProvisioningComponent } from './provisioning/provisioning.component';
import { AuthGuard } from './auth/auth.guard';
import { OpenlicenseComponent } from './openlicense/openlicense.component';
import { G3msunitsearchComponent } from './g3msunitsearch/g3msunitsearch.component';
import { EpalcommissionComponent } from './epalcommission/epalcommission.component';
import { BiprovisionComponent } from './biprovision/biprovision.component';
import { ReportsComponent } from './reports/reports.component';
import { EdiordersComponent } from './ediorders/ediorders.component';
import { UnithistoryComponent } from './unithistory/unithistory.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    component: AppComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'provisioning',
    component: ProvisioningComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'biprovision',
    component: BiprovisionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gateway',
    component: GatewayComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'commissioning',
    component: CommissioningComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'g3msunitsearch',
    component: G3msunitsearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'usermanagement',
    component: UsermanagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'license',
    component: OpenlicenseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'epalcommission',
    component: EpalcommissionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ediorders',
    component: EdiordersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    component: UnithistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**', redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
