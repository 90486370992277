<div id="reports-main-wraper">
  <div class="col-12 page-heading p-0">
    <h5>Reports</h5>
  </div>
  <div class="col-12 search-ft p-0 mt-3">
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label name="lblPRFileType" class="control-label required">File Type </label>
          <select name="ddPRFileType" class="form-control" [(ngModel)]="selectedFileType.id"
            (change)="fileTypeSelectChanged($event)" tabindex="2">
            <option value="0" selected> --- Select File Type --- </option>
            <option *ngFor="let fileType of fileTypes" [ngValue]="fileType.id">{{fileType.name}}</option>
          </select>
        </div>
        <div class="lblerror" *ngIf="lblErrorFileType">
          {{ this.lblErrorFileType }}
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <div class="input-group">
            <label class="control-label col-12 p-0" name="lblPRUploadFrmDt">
              Uploaded From Date </label>
            <input class="form-control" placeholder="Select From Date" name="dpPRFrmDt" ngbDatepicker
              [(ngModel)]="dpPRFrmDate" #d1="ngbDatepicker">
            <div class="input-group-append dtToggle">
              <button class="btn btn-outline-secondary calendar" type="button" (click)="d1.toggle()" tabindex="4">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <div class="input-group">
            <label class="control-label col-12 p-0" name="lblPRUploadToDt">
              Uploaded To Date </label>
            <input class="form-control" placeholder="Select To Date" name="dpPRToDt" ngbDatepicker
              [(ngModel)]="dpPRToDate" #d2="ngbDatepicker">
            <div class="input-group-append dtToggle">
              <button class="btn btn-outline-secondary calendar" type="button" (click)="d2.toggle()" tabindex="5">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 d-inline-flex p-0">
        <div class="col d-flex align-items-end">
          <div class="form-group">
            <button class="btn btn-primary mr-2" name="btnPRSearch" (click)="getGridDataByFilterSearch()"
              tabindex="6">Search</button>
            <button class="btn btn-outline-primary mr-2" name="btnPRReset" (click)="onResetFilters()"
              tabindex="7">Reset</button>
          </div>
          <div id="gridActions" class="ml-auto">
            <label class="mr-2" name="lblPRExport" (click)="exportExcel()" tabindex="7"><span>
                <em class="fa fa-download"></em></span> Export</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <ag-grid-angular #agGridProvision [enableCellTextSelection]="true" class="ag-theme-material mt-2 provision-layout"
    [rowData]="rowData" [columnDefs]="columnDefs" [gridOptions]="gridOptions">
  </ag-grid-angular>
</div>