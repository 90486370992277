export class Config {
    ClientId: string;
    Issuer: string;
    TokenEndpoint: string;
    LoginUrl: string;
    Scope: string;
    PasswordResetUrl: string;
    PasswordResetErrorCode: string;
    DiscoveryDocumentUrl: string;
    ResponseType: string;
    SkipIssuerCheck: boolean;
    ClearHashAfterLogin: boolean;
    Oidc: boolean;
    ApiUrl: string;
    AppName: string;
    AppVersion: string;
    CIMSVersion: string;
    G3MSVersion: string;
    BuildDate: string;
    APPINSIGHTS_INSTRUMENTATIONKEY: string;
}
