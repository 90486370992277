import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { Service } from '../service';
import { NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from '@ag-grid-community/all-modules';
import { ProvisionFilter } from '../common/models/provisionfilter';
import { NgbStringAdapter } from '../common/convertors/ngbstringadapter';
import { FCToasterService } from '../common/toaster/FCToasterService';

@Component({
  selector: 'app-provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: ['./provisioning.component.scss']
})

export class ProvisioningComponent implements OnInit {
  @ViewChild("agGridProvision", { static: false }) agGridProvision: AgGridAngular;
  rowData: any[];
  columnDefs: any[];
  fileTypes: any[];
  fileTypeSelectedId: number;
  fileTypeSelectedValue: any;
  fileStatusSelectedId: number;
  fileStatusSelectedValue: any;
  fileStatus: any[];
  dpPRFrmDate: NgbDateStruct;
  dpPRToDate: NgbDateStruct;
  fileName: string;
  closeResult: string;
  fileToUpload: File;
  fileUploadStatus: any;
  txtPRFileName: any;
  provisionSearchFilter = {} as ProvisionFilter;
  selectedFileType: any = {};
  selectedFileStatus: any = {};
  excelUnitType: any = {};
  defaultDeviceType: any ={};
  date = new Date();
  firstDayofMonth: Date;
  createdFromDate: Date;
  createdToDate: Date;
  gridOptions: GridOptions;
  isRowSelected: boolean = false;
  rowSelectedData: {};
  responseMessage: string = "";
  processFileName: any;
  processFileId: number;
  processFileUnitCount: number;
  processIdList: number[] = [];
  selectedFiles = [];
  deviceTypelist: any[];
  deviceTypes: any[];
  LDdeviceTypes: any[];
  UDdeviceTypes: any[];
  deviceTypeSelectedId: number;
  deviceTypeSelectedValue: string;
  selectedDeviceTypeId: number;
  deviceTypeError: string = "";
  excelUnitTypes: any[];
  excelUnitTypeSelectedId: number;
  excelUnitTypeSelectedValue: string;
  selectedExcelUnitTypeId: number;
  excelUnitTypeError: string = "";
  showExcelType = false;
  highRiseNewEqp = false;
  txtUnitNumbers : string;
  isDoubleDeckUnit = false;
  isBulkUpload: boolean = false;
  bulkUploadMessage : string;
  LDdeviceTypeSelectedId: number;
  UDdeviceTypeSelectedId: number;
  UDdeviceTypeError: string = "";
  LDdeviceTypeError: string = "";
  elevatorTypeId: number = 1;
  deviceTypeId2: number;

  constructor(private apiService: Service, private modalService: NgbModal,
    private ngbStringAdapter: NgbStringAdapter, private toastr: FCToasterService) { }

  ngOnInit(): void {
    this.gridOptions = {
      defaultColDef: {filter:true},
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true
    }
    this.bindGridOnPageLoad();
    this.bindMasterDataFilters();
    this.bindDefaultValuesOnPageLoad();
    this.showBulkUpload();
  }
  
  showBulkUpload()
  {
    this.apiService
        .getVersionInfo()
        .subscribe((data: any) => {
          if (data != null) {
            this.isBulkUpload = data.enableBulkUpload;
          }
        }, (error) => {
      });
  }
  //Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "", field: "cbox", checkboxSelection: true, width: 70 },
      { headerName: "File Id", field: "fileId", sortable: true, width: 120, tooltipField: "fileId", headerTooltip: "File Id" },
      { headerName: "File Name", field: "fileName", sortable: true, width: 340, resizable: true, tooltipField: "fileName", headerTooltip: "File Name" },
      { headerName: "File Type", field: "fileType", sortable: true, width: 160, tooltipField: "fileType", headerTooltip: "File Type" },
      { headerName: "File Status", field: "fileStatus", sortable: true, width: 170, tooltipField: "fileStatus", headerTooltip: "File Status" },
      { headerName: "Units Count", field: "unitsCount", sortable: true, width: 160, tooltipField: "unitsCount", headerTooltip: "Units Count" },
      { headerName: "Uploaded By", field: "createdBy", sortable: true, width: 170, tooltipField: "createdBy", headerTooltip: "Uploaded By" },
      { headerName: "Uploaded Date", field: "createdDate", sortable: true, width: 220, tooltipField: "createdDate", headerTooltip: "Uploaded Date" }
      
    ];
    this.getGridDataOnPageLoad();
  }

  //Bind master data in filetrs on page load
  bindMasterDataFilters() {
    this.getFileStatus();
    this.getFileTypes();
    this.getDeviceTypes();
    this.getExcelUnitTypes();    
  }

  // Assign default values to variables on Page load
  bindDefaultValuesOnPageLoad() {
    this.txtPRFileName = "";
    this.fileStatusSelectedValue = "";
    this.fileTypeSelectedValue = "";
    this.fileTypeSelectedId = 0;
    this.fileStatusSelectedId = 0;
    this.firstDayofMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.dpPRFrmDate = this.ngbStringAdapter.fromModel(this.firstDayofMonth);
    this.dpPRToDate = this.ngbStringAdapter.fromModel(this.date);
    this.isRowSelected = false;
    this.rowSelectedData = {};
    this.processFileName = "";
    this.processFileId = 0;
    this.processIdList = [];
    this.responseMessage = "";
    this.selectedFileType = { id: 0, name: "--- Select File Type ---" };
    this.selectedFileStatus = { id: 0, name: "--- Select File Status ---" };
    this.excelUnitType = { id: 0, name: "--- Select ---" };
    this.defaultDeviceType = { id: 0, name: "--- Select ---" };
    this.rowData = [];
    this.selectedFiles = [];
    this.processFileUnitCount = 0;
    this.deviceTypeSelectedId = 0;
    this.deviceTypeSelectedValue = "";
    this.selectedDeviceTypeId = 0;
    this.deviceTypeError = "";
    this.excelUnitTypeSelectedId = 0;
    this.excelUnitTypeSelectedValue = "";
    this.selectedExcelUnitTypeId = 0;
    this.excelUnitTypeError = "";
    this.highRiseNewEqp = false;
    this.bulkUploadMessage = "";
    this.clearDDFields();
  }

  onKeyEnter() {
    this.getGridDataByFilterSearch();    
  }
  //Get the grid data on page load
  getGridDataOnPageLoad() {
    this.provisionSearchFilter.fileName = "";
    this.provisionSearchFilter.fileType = "";
    this.provisionSearchFilter.Status = 0;
    this.provisionSearchFilter.createdFromDate = "";
    this.provisionSearchFilter.createdToDate = "";

    this.apiService
      .getFileDataOnLoad(this.provisionSearchFilter)
      .subscribe((data: any) => {
        if (data != null) {
          this.rowData = data;
        }
        else {
          this.rowData = [];
        }
      });
  }

  //Open the upload, Process, Delete modal popup
  open(content) {
    this.txtUnitNumbers = "";
    this.bulkUploadMessage = "";
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  modalClose(content) {
    this.responseMessage = "";
    this.fileToUpload = null;
    this.deviceTypeError = "";
    this.deviceTypeSelectedId = 0;
    this.deviceTypeSelectedValue = "";
    this.excelUnitTypeError = "";
    this.excelUnitTypeSelectedId = 0;
    this.excelUnitTypeSelectedValue = "";
    this.showExcelType = false;
    this.modalService.dismissAll();
    this.clearDDFields();
  }

  getGridDataByFilterSearch() {
    this.provisionSearchFilter.fileName = (this.txtPRFileName !== null && this.txtPRFileName !== undefined) ? this.txtPRFileName.trim() : "";
    this.provisionSearchFilter.fileType = this.fileTypeSelectedId !== 0 ? this.fileTypeSelectedValue : "";
    this.provisionSearchFilter.Status = this.fileStatusSelectedId;
    this.createdFromDate = this.ngbStringAdapter.toMinDate(this.dpPRFrmDate);
    this.createdToDate = this.ngbStringAdapter.toMaxDate(this.dpPRToDate);
    this.provisionSearchFilter.createdFromDate = this.ngbStringAdapter.formatToMinDate(this.createdFromDate);
    this.provisionSearchFilter.createdToDate = this.ngbStringAdapter.formatToMaxDate(this.createdToDate);
    if (this.createdFromDate <= this.createdToDate) {
      this.apiService
        .getFilesByFilterSearch(this.provisionSearchFilter)
        .subscribe((data: any) => {
          if (data != null) {
            this.rowData = data;
          }
          else {
            this.rowData = [];
          }
        });
    }
    else {
      this.toastr.showWarning("Uploaded From Date must be less than or equal to Uploaded To Date");
    }
    this.selectedFiles = [];
  }

  //Closing the modal popup
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  fileTypeSelectChanged($event) {
    this.fileTypeSelectedId = $event.target.selectedIndex;
    this.fileTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
  }

  fileStatusSelectChanged($event) {
    this.fileStatusSelectedId = $event.target.selectedIndex;
    this.fileStatusSelectedValue = $event.target.options[$event.target.selectedIndex].text;
  }

  //Get the file status master data list
  getFileStatus() {
    this.apiService
      .getFileStatus()
      .subscribe((data: any) => {
        this.fileStatus = data;
      });
  }

  //Get the file type master data list
  getFileTypes() {
    this.apiService
      .getFileType()
      .subscribe((data: any) => {
        this.fileTypes = data;
      });
  }

  //Get the Device Types
  getDeviceTypes() {
    this.apiService
      .getDeviceTypes()
      .subscribe((data: any) => {
        this.deviceTypelist = data;
        this.deviceTypes = data.filter(t=>t.elevatorTypeId === 1);
        this.bindDeviceTypeDefaultIds();        
      });
  }

  bindDeviceTypeDefaultIds()
  {
    if(this.deviceTypelist != undefined)
    {
      this.LDdeviceTypes = this.deviceTypelist.filter(t=>t.elevatorTypeId === 2 && t.deckTypeId === 1);
      this.LDdeviceTypeSelectedId = this.LDdeviceTypes.filter(t=>t.id)[0]["deviceTypeId"];
      this.UDdeviceTypes = this.deviceTypelist.filter(t=>t.elevatorTypeId === 2 && t.deckTypeId === 2);
      this.UDdeviceTypeSelectedId = this.UDdeviceTypes.filter(t=>t.id)[0]["deviceTypeId"];  
    }  
  }
  //Get the Device Types
  getExcelUnitTypes() {
    var types = [
      { id: 1, excelUnitType: "Service (OR) Mod Unit" },
      { id: 2, excelUnitType: "HighRise New Equipment Unit" },
      { id: 3, excelUnitType: "Double Deck Unit" }
    ];
    this.excelUnitTypes = types;
  }

  // File Upload to Server
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.responseMessage = "";
    this.excelUnitType = { id: 0, name: "--- Select ---" };
    this.defaultDeviceType = { id: 0, name: "--- Select ---" };
    if (this.fileToUpload["type"] == ("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "application/vnd.ms-excel")) {
      this.showExcelType = true;
    }
    else {
      this.showExcelType = false;this.isDoubleDeckUnit = false;      
    }    
  }

  clearDDFields()
  {
    this.isDoubleDeckUnit = false;
    this.elevatorTypeId = 1;
    this.bindDeviceTypeDefaultIds();
    this.deviceTypeId2 = 0;
    this.LDdeviceTypeError = "";
    this.UDdeviceTypeError = "";    
    this.excelUnitType = { id: 0, name: "--- Select ---" };    
    this.defaultDeviceType = { id: 0, name: "--- Select ---" };
  }

  onFileUpload() {
    if (this.fileToUpload !== null && this.fileToUpload !== undefined) {
      if (this.onFileUploadValidations()) {
        var deviceTypeSelId;
        if(this.isDoubleDeckUnit)
        {
            this.elevatorTypeId = 2;
            deviceTypeSelId = this.LDdeviceTypeSelectedId;
            this.deviceTypeId2 = this.UDdeviceTypeSelectedId; 
        }
        else
        {
          deviceTypeSelId = this.deviceTypeSelectedId;            
          this.elevatorTypeId = 1;
        }
        this.apiService.uploadFile(this.fileToUpload, deviceTypeSelId, this.highRiseNewEqp, this.elevatorTypeId, this.deviceTypeId2).subscribe(data => {  if (data > 0) {
            this.modalService.dismissAll();
            this.clearModalPopupClose();
            this.getGridDataByFilterSearch();
            this.toastr.showSuccess("Successfully Uploaded!!");
          }
          else {
            this.toastr.showError("Failed to upload the file");
            this.modalService.dismissAll();
          }
        });
      }      
    }
    else {
      this.responseMessage = "Please select a file to upload"
    }
  }
  onFileUploadValidations(): boolean {
    if (this.fileToUpload["type"] == ("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
      if (this.excelUnitTypeSelectedId != 0) {
        this.excelUnitTypeError = "";
      }
      else {
        this.excelUnitTypeError = "Please select ExcelUnitType";
        return false;
      }
    }
    if(this.isDoubleDeckUnit)
      {
        if(this.LDdeviceTypeSelectedId === null || this.LDdeviceTypeSelectedId === 0 || this.LDdeviceTypeSelectedId === undefined)
        {
            this.LDdeviceTypeError = "Please select Lower Deck Device Type";
            return false;
        }
        else if(this.UDdeviceTypeSelectedId === null || this.UDdeviceTypeSelectedId === 0 || this.UDdeviceTypeSelectedId === undefined)
        {
            this.UDdeviceTypeError = "Please select Upper Deck Device Type";
            return false;
        }   
      }
    return true;
  }

  //Rest fileters in the page
  onResetFilters() {
    this.bindDefaultValuesOnPageLoad();
    this.getGridDataOnPageLoad();
  }

  //Grid row selection event
  onRowSelected(event) {
    if (event.node.selected) {
      this.selectedFiles.push(event.node.data);
    }
    else {
      var index = this.selectedFiles.indexOf(event.node.data);
      this.selectedFiles.splice(index, 1);
    }

    if (this.selectedFiles.length === 1) {
      this.isRowSelected = true;
      this.rowSelectedData = this.selectedFiles[0];
      this.processFileName = this.rowSelectedData["fileName"];
      this.processFileId = this.rowSelectedData["fileId"];
      this.processFileUnitCount = this.rowSelectedData["unitsCount"];
      if (this.rowSelectedData["fileStatus"].toUpperCase().trim() === "UNPROCESSED" || this.rowSelectedData["fileStatus"].toUpperCase().trim() === "PARTIALLY PROCESSED") {
        this.processIdList.push(this.processFileId);
      }
    }

    else {
      this.rowSelectedData = {};
      this.isRowSelected = false;
      this.processFileName = "";
      this.processFileId = 0;
      this.processIdList = [];
    }
  }

  deviceTypeSelectChanged($event) {
    this.deviceTypeSelectedId = this.selectedDeviceTypeId = $event.target.value;
    this.deviceTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
    if (this.deviceTypeSelectedId !== 0) {
      this.deviceTypeError = "";
    }
  }

  excelTypeSelectChanged($event) {
    this.highRiseNewEqp = false;
    this.isDoubleDeckUnit = false;
    this.excelUnitTypeSelectedId = this.selectedExcelUnitTypeId = $event.target.value;
    this.excelUnitTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
    if (this.excelUnitTypeSelectedId !== 0) {
      this.excelUnitTypeError = "";
    }
    if (this.excelUnitTypeSelectedId == 2) {
      this.highRiseNewEqp = true;
    }
    if (this.excelUnitTypeSelectedId == 3) {
      this.isDoubleDeckUnit = true;

    }
  }
  LDdeviceTypeSelectChanged($event) {
    this.LDdeviceTypeSelectedId = $event.target.value;
    if (this.LDdeviceTypeSelectedId !== 0) {
      this.LDdeviceTypeError = "";
    }
  }

  UDDeviceTypeSelectChanged($event) {
    this.UDdeviceTypeSelectedId = $event.target.value;
    if (this.UDdeviceTypeSelectedId !== 0) {
      this.UDdeviceTypeError = "";
    }
  }
  // File Delete for a selected file
  onFileDelete() {
    if (this.rowSelectedData != null && this.rowSelectedData["fileStatus"].toUpperCase().trim() === "UNPROCESSED") {
      this.apiService.deleteFile(this.rowSelectedData["fileId"]).subscribe(data => {
        if (data === true) {
          this.modalService.dismissAll();
          this.toastr.showSuccess("Successfully deleted the file !!");
          this.getGridDataByFilterSearch();
          this.clearModalPopupClose();
        }
        else {
          this.modalService.dismissAll();
        }
      });
    }
    else {
      this.toastr.showError("Selected file should be in UnProcessed status");
    }
  }

  //Clear fields in modal popup
  clearModalPopupClose() {
    this.isRowSelected = false;
    this.rowSelectedData = {};
    this.processFileId = 0;
    this.processIdList = [];
    this.selectedFiles = [];
    this.fileToUpload = null;
    this.processFileUnitCount = 0;
    this.excelUnitTypeError = "";
    this.showExcelType = false;
    this.clearDDFields();
  }

  //File process for a selected file
  onFileProcess($event) {
    if ($event !== null && this.processIdList.length >= 1) {
      if (this.processFileUnitCount < 25) {
        this.apiService.processFile(this.processIdList).subscribe(data => {
          if (data === true) {
            this.toastr.showSuccess("File is Being Processed !!");
            this.modalService.dismissAll();
            this.bindGridOnPageLoad();
            this.getGridDataByFilterSearch();
            this.clearModalPopupClose();
            this.bindDeviceTypeDefaultIds();
          }
          else {
            this.modalService.dismissAll();
          }
        });
      }
      else {
        this.toastr.showError("Selected file should not contain more than 25 units")
      }
    }

    else {
      this.toastr.showError("Selected file should be in UnProcessed or Partially Processed Status")
    }
  }
  
  onClearBulkUpload()
  {
    this.txtUnitNumbers = "";
    this.bulkUploadMessage = "";
  }
  onBulkUpload()
  {
    if(this.txtUnitNumbers === "")
    {
      this.bulkUploadMessage = "Please enter unit numbers to upload";
    }else if(this.txtUnitNumbers.split(',').length > 4)
    {
      this.bulkUploadMessage = "A maximum of 4 units can be bulk uploaded at once";
    }
    else
    {
      this.apiService.bulkUpload(this.txtUnitNumbers)
      .subscribe((data: any) => {
        if (data != null) {
          this.txtUnitNumbers = '';
          this.toastr.showSuccess("Unit Numbers are Successfully Processed !!");
        }        
      });      
    }
  }
}

