import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { NgbStringAdapter } from '../common/convertors/ngbstringadapter';
import { FCToasterService } from '../common/toaster/FCToasterService';
import { Service } from '../service';
import { GridOptions } from '@ag-grid-community/all-modules';
import { ReportsFilter } from '../common/models/reportsfilter';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  @ViewChild("agGridReports", { static: false }) agGridProvision: AgGridAngular;
  gridOptions: GridOptions;
  rowData: any[];
  columnDefs: any[];
  dpPRFrmDate: NgbDateStruct;
  dpPRToDate: NgbDateStruct;
  fileTypes: any[];
  fileTypeSelectedId: number;
  fileTypeSelectedValue: any;
  firstDayofMonth: Date;
  createdFromDate: Date;
  createdToDate: Date;
  date = new Date();
  reportsSearchFilter = {} as ReportsFilter;
  lblErrorFileType: string;
  selectedFileType: any = {};

  constructor(private apiService: Service, private modalService: NgbModal,
    private ngbStringAdapter: NgbStringAdapter, private toastr: FCToasterService) { }

  ngOnInit(): void {
    this.gridOptions = {
      defaultColDef: { filter: true },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true
    }

    this.bindGridOnPageLoad();
    this.bindMasterDataFilters();
    this.bindDefaultValuesOnPageLoad();
  }

  //Bind Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "File Id", field: "fileId", sortable: true, width: 120, tooltipField: "fileId", headerTooltip: "File Id" },
      { headerName: "File Type", field: "fileType", sortable: true, width: 250, tooltipField: "fileType", headerTooltip: "File Type" },
      { headerName: "Building Id", field: "buildingId", sortable: true, width: 160, tooltipField: "buildingId", headerTooltip: "Building Id" },
      { headerName: "Building Name", field: "buildingName", sortable: true, width: 240, tooltipField: "buildingName", headerTooltip: "Building Name" },
      { headerName: "Unit Number", field: "elevatorUnitNumber", sortable: true, width: 170, tooltipField: "Unit Number", headerTooltip: "Unit Number" },
      { headerName: "Deck Type", field: "deckType", sortable: true, width: 170, tooltipField: "deckType", headerTooltip: "Deck Type" },
      { headerName: "Elevator Type", field: "elevatorType", sortable: true, width: 170, tooltipField: "elevatorType", headerTooltip: "Elevator Type" },
      { headerName: "Unit Status", field: "unitStatus", sortable: true, width: 170, tooltipField: "Unit Status", headerTooltip: "Unit Status" },
      { headerName: "CPIB-UUID", field: "cpibUuid", sortable: true, width: 170, tooltipField: "cpibUuid", headerTooltip: "cpibuuid" },
      { headerName: "Uploaded By", field: "uploadedBy", sortable: true, width: 170, tooltipField: "uploadedBy", headerTooltip: "Uploaded By" },
      { headerName: "Uploaded Date", field: "uploadedDate", sortable: true, width: 220, tooltipField: "uploadedDate", headerTooltip: "Uploaded Date" },
      { headerName: "Address", field: "address", sortable: true, width: 220, tooltipField: "address", headerTooltip: "Address" },
      { headerName: "City", field: "city", sortable: true, width: 220, tooltipField: "city", headerTooltip: "City" },
      { headerName: "State", field: "state", sortable: true, width: 220, tooltipField: "state", headerTooltip: "State" },
      { headerName: "Country", field: "country", sortable: true, width: 220, tooltipField: "country", headerTooltip: "Country" },
      { headerName: "PostalCode", field: "postalCode", sortable: true, width: 220, tooltipField: "postalCode", headerTooltip: "PostalCode" },
      { headerName: "Stops", field: "stops", sortable: true, width: 220, tooltipField: "stops", headerTooltip: "Stops" },
      { headerName: "CommunicationPath", field: "communicationPath", sortable: true, width: 220, tooltipField: "communicationPath", headerTooltip: "CommunicationPath" },
      { headerName: "INFOT", field: "infot", sortable: true, width: 220, tooltipField: "infot", headerTooltip: "INFOT" },
      { headerName: "CarNumber", field: "carNumber", sortable: true, width: 220, tooltipField: "carNumber", headerTooltip: "CarNumber" },
      { headerName: "CpibDeviceTypeId", field: "cpibDeviceTypeId", sortable: true, width: 220, tooltipField: "cpibDeviceTypeId", headerTooltip: "CpibDeviceTypeId" },
      { headerName: "TPATYP", field: "tpatyp", sortable: true, width: 220, tooltipField: "tpatyp", headerTooltip: "TPATYP" },
      { headerName: "UIUpload", field: "uiUpload", sortable: true, width: 220, tooltipField: "uiUpload", headerTooltip: "UIUpload" },
      { headerName: "BranchName", field: "branchName", sortable: true, width: 220, tooltipField: "branchName", headerTooltip: "BranchName" },
      { headerName: "CustomerName", field: "customerName", sortable: true, width: 220, tooltipField: "customerName", headerTooltip: "CustomerName" },
      { headerName: "ElevatorDeviceId", field: "elevatorDeviceId", sortable: true, width: 220, tooltipField: "elevatorDeviceId", headerTooltip: "ElevatorDeviceId" },
      { headerName: "ISOAlpha3Code", field: "isoAlpha3Code", sortable: true, width: 220, tooltipField: "isoAlpha3Code", headerTooltip: "ISOAlpha3Code" },      
      { headerName: "SIMCardICCID1", field: "simCardIccId1", sortable: true, width: 220, tooltipField: "simCardIccId1", headerTooltip: "SIMCardICCID1" },
      { headerName: "SIMCardICCID2", field: "simCardIccId2", sortable: true, width: 220, tooltipField: "simCardIccId2", headerTooltip: "SIMCardICCID2" },
      { headerName: "GatewayMacAddress", field: "gatewayMacAddress", sortable: true, width: 220, tooltipField: "gatewayMacAddress", headerTooltip: "GatewayMacAddress" },
      { headerName: "GatewayType", field: "gatewayType", sortable: true, width: 220, tooltipField: "gatewayType", headerTooltip: "GatewayType" },
      { headerName: "Cpib2MacAddress", field: "cpib2MacAddress", sortable: true, width: 220, tooltipField: "cpib2MacAddress", headerTooltip: "Cpib2MacAddress" },
      { headerName: "ProcessedBy", field: "processedBy", sortable: true, width: 220, tooltipField: "processedBy", headerTooltip: "ProcessedBy" },
      { headerName: "ProcessedDate", field: "processedDate", sortable: true, width: 220, tooltipField: "processedDate", headerTooltip: "ProcessedDate" }
    ];
    this.rowData = [];
  }

  bindDefaultValuesOnPageLoad() {
    this.fileTypeSelectedValue = "";
    this.fileTypeSelectedId = 0;
    this.reportsSearchFilter.fileType = 0;
    this.reportsSearchFilter.createdFromDate = "";
    this.reportsSearchFilter.createdToDate = "";
    this.firstDayofMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.dpPRFrmDate = this.ngbStringAdapter.fromModel(this.firstDayofMonth);
    this.dpPRToDate = this.ngbStringAdapter.fromModel(this.date);
    this.selectedFileType = { id: 0, name: "--- Select File Type ---" };
    this.rowData = [];
    this.lblErrorFileType = "";
  }

  bindMasterDataFilters() {
    this.getFileTypes();
  }

  getFileTypes() {
    this.apiService
      .getFileType()
      .subscribe((data: any) => {
        this.fileTypes = data;
      });
  }

  getGridDataByFilterSearch() {
    if (this.OnFilterSearchValidation()) {
      this.reportsSearchFilter.fileType = this.fileTypeSelectedId !== 0 ? this.fileTypeSelectedId : 0;
      this.createdFromDate = this.ngbStringAdapter.toMinDate(this.dpPRFrmDate);
      this.createdToDate = this.ngbStringAdapter.toMaxDate(this.dpPRToDate);
      this.reportsSearchFilter.createdFromDate = this.ngbStringAdapter.formatToMinDate(this.createdFromDate);
      this.reportsSearchFilter.createdToDate = this.ngbStringAdapter.formatToMaxDate(this.createdToDate);
      if (this.createdFromDate <= this.createdToDate) {
        if (this.calculateDiff() > -90) {
          this.apiService
            .getUnitDetailsReport(this.reportsSearchFilter)
            .subscribe((data: any) => {
              if (data != null) {
                this.rowData = data;
              }
              else {
                this.rowData = [];
              }
            });
        }
        else {
          this.toastr.showWarning("Uploaded From Date and Uploaded To Date values timespan should be Lessthan or Equal to 90 Days");
        }
      }
      else {
        this.toastr.showWarning("Uploaded From Date must be less than or equal to Uploaded To Date");
      }
    }
  }

  OnFilterSearchValidation(): Boolean {
    if (this.fileTypeSelectedId === 0) {
      this.lblErrorFileType = "Please select the File Type"
      return false;
    }
    else {
      this.lblErrorFileType = ""
    }
    return true;
  }

  onResetFilters() {
    this.bindDefaultValuesOnPageLoad();
  }

  fileTypeSelectChanged($event) {
    this.fileTypeSelectedId = $event.target.selectedIndex;
    this.fileTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
  }

  exportExcel() {
    if (this.rowData !== null) {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: this.rowData[0].fileType + this.rowData[0].buildingName + "_" + this.createdFromDate.toLocaleDateString("es-CL") + "To" + this.createdToDate.toLocaleDateString("es-CL")
      };
      this.gridOptions.api.exportDataAsCsv(params);
    }
  }

  modalClose(content) {
    this.modalService.dismissAll();
  }

  calculateDiff() {
    var fromDate = this.createdFromDate;
    var toDate = this.createdToDate;
    return Math.floor((Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()) - Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())) / (1000 * 60 * 60 * 24));
  }
}