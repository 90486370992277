import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Service } from '../service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private service: Service, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
         if(state.url == "/usermanagement" && sessionStorage.getItem('role') != null && sessionStorage.getItem('role').toUpperCase() == 'ADMIN')
            return true;
        else if (state.url != "/usermanagement" && sessionStorage.getItem('access_token') != null) {
            return true;
        }
            
        // not logged in so redirect to login page with the return url
        if(state.url != "/usermanagement")
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }  
}
