<mat-tab-group (selectedTabChange)="onChange($event)" #tabGroup class="edi-tabs-wrapper" mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="0">
    <mat-tab label="EDI Orders Search" style="width: 25%;">
        <div id="ediorders-main-wraper">
        <div class="col-12 search-ft p-0 mt-3">
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label name="lblEdiOrdersPOSrch" class="control-label">PO Number</label>
                        <input type="text" class="form-control" name="txtEdiOrdersPOSrch" [(ngModel)]="txtEdiOrdersPOSrch"
                            maxlength="30" tabindex="1" (keydown.enter)="onKeyEnter()">
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label name="lblEdiOrdersUNSrch" class="control-label">Unit Number</label>
                        <input type="text" class="form-control" name="txtEdiOrdersUNSrch" [(ngModel)]="txtEdiOrdersUNSrch"
                            maxlength="12" tabindex="2" (keydown.enter)="onKeyEnter()">
                    </div>
                </div>
                <div class="col-sm-3 d-inline-flex p-0">
                    <div class="col d-flex align-items-start">
                        <div class="form-group">
                            <button class="btn btn-primary mr-2" name="btnEdiOrdersSrch" tabindex="3"
                                (click)="getGridDataOnSearch()">Search</button>
                            <button class="btn btn-outline-primary mr-2" name="btnEdiOrdersReset" (click)="resetFilters()"
                                tabindex="4">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <ag-grid-angular #agGridEdiOrders [enableCellTextSelection]="true" class="ag-theme-material mt-2 ediorders-layout" [rowData]="rowData"
                [columnDefs]="columnDefs" [gridOptions]="gridOptions" tabindex="4">
            </ag-grid-angular>
        </div>
    </div>
    </mat-tab>
    <mat-tab label="EDI Orders Priority" style="width: 25%;">
        <div id="ediorders-main-wraper">
            <div class="col-12 search-ft p-0 mt-3">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label name="lblPEdiOrdersUNSrch" class="control-label">PO / Order / Machine</label>
                            <input type="text" class="form-control" name="txtPriEdiOrdersSrch" [(ngModel)]="txtPriEdiOrdersSrch"
                                maxlength="20" tabindex="2" (keydown.enter)="onPriorityKeyEnter()">
                        </div>
                    </div>
                    <div class="col-sm-3 d-inline-flex p-0">
                        <div class="col d-flex align-items-start">
                            <div class="form-group">
                                <button class="btn btn-primary mr-2" name="btnPriEdiOrdSrch" tabindex="3"
                                    (click)="getFilteredSortedGrid()">Search</button>
                                <button class="btn btn-outline-primary mr-2" name="btnPEdiOrdersReset" (click)="resetPFilters()"
                                    tabindex="4">Reset</button>
                            </div>
                        </div>
                    </div>
                    <div id="gridActions" class="col-6 d-flex align-items-end">
                        <label class="mr-10" name="lblPRUpload" (click)="open(uploadModal)" tabindex="9"><span>
                            <em class="fa fa-upload"></em></span> Upload</label>&nbsp;&nbsp;
                        <label class="mr-2" name="lblPRExport" (click)="exportExcel()" tabindex="7"><span>
                            <em class="fa fa-download"></em></span> Export</label>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="row">
                <ag-grid-angular #agPGridEdiOrders [enableCellTextSelection]="true" class="ag-theme-material mt-2 ediorders-layout" [rowData]="PriorityRowData"
                    [columnDefs]="columnPDefs" [gridOptions]="PrioritygridOptions" tabindex="4">            
                </ag-grid-angular>
            </div>
            <div>
                <div class="divfloatleft">
                    <label name="lblCount" class="control-label lblCountAlign">No.of Records :{{ lblEdiOrderCount }}</label>
                </div>
                <div class="divfloatright">
                    <button class="btn btn-primary mr-2" name="btnSave" (click)="btnPrioritySave()">Save</button>
                </div>
            </div>
        </div>        
    </mat-tab>
    <mat-tab></mat-tab>            
    <mat-tab></mat-tab>            
</mat-tab-group>
    
    
    <!--Template to upload excel file-->
    <ng-template #uploadModal let-modal>
        <div class="modal-header">
          <h5 class="modal-title" id="modal-basic-title">File Upload</h5>
          <button type="button" class="close" aria-label="Close" (click)="modalClose('cross click')">
            <em class="fa fa-window-close"></em>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-12 search-ft p-2 mt-3">       
              <div class="col-12">
                <div class="form-group">
                  <input type="file" title="Choose a file" id="fileUpload" name="fileUpload"
                    accept=".xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    (change)="handleFileInput($event.target.files)">
                </div>
                <div class="lblerror">
                    {{ this.excelUnitTypeError }}
                  </div>
              </div>          
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary mr-2" (click)="onFileUpload()">Upload</button>
          <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('cancel')">Cancel</button>
        </div>        
      </ng-template>