import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { GatewayDecommission } from './common/models/gatewaydecommission';
import { EpalDecomAndComm } from './common/models/EpalDecomAndComm';
import { EpalCommissionFilter } from './common/models/epalcommissionfilter';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FileStatus } from './common/models/filestatus';
import { FileType } from './common/models/filetype';
import { ProvisionFilter } from './common/models/provisionfilter';
import { GatewayFilter } from './common/models/gatewayfilter';
import { GatewayType } from './common/models/gatewaytype';
import { AssignGateway } from './common/models/assigngateway';
import { Commission } from './common/models/commission';
import { UserFilter } from './common/models/userfilter';
import { AddUser } from './common/models/adduser';
import { AppConfigService } from './services/app-config.service';
import { ProductType } from './common/models/producttype';
import { EpalCommission } from './common/models/epalcommission';
import { CommunicationPath } from './common/models/communicationpath';
import { BIDataProcess } from './common/models/bidataprocess';
import { CpibDeviceTypes } from './common/models/cpibdevicetypes';
import { GatewayFirmware } from './common/models/gatewayfirmware';
import { CPIBFirmware } from './common/models/CPIBFirmware';
import { TwilioPhoneNumber } from './common/models/twilioPhoneNumber';
import { Countries } from './common/models/countries';
import { EloviewAppContent } from './common/models/eloviewappcontent';
import { EpalRebootRequest } from './common/models/epalRebootRequest';
import { EloviewDevices } from './common/models/eloviewDevices';
import { AddContentToDevice } from './common/models/addcontenttodevice';
import { ReportsFilter } from './common/models/reportsfilter';
import { CPIBRcnInfo } from './common/models/CpibRcnInfo';
import { DeviceGatewayTypes } from './common/models/DeviceGatewayTypes';
import { EpalGatewayDecommission } from './common/models/epalgatewaydecommission';
import { AssignEpalGateway } from './common/models/assignepalgateway';
import { OtisLinePhonenumber } from './common/models/otislinephonenumber';
import { CPIBTestStationPhNumber } from './common/models/CPIBTestStationPhNumber';
import { ediorderpriority } from './common/models/ediorderpriority';

@Injectable({
  providedIn: 'root'
})
export class Service {
  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }

  //GET, POST, DELETE File Api's
  getFileStatusApi: string = this.appConfigService.serverUrl + '/api/v1/file/status';
  getFileTypeApi = this.appConfigService.serverUrl + '/api/v1/file/type';
  getFilesBySearchFilterApi = this.appConfigService.serverUrl + '/api/v1/file/search';
  deleteFileApi = this.appConfigService.serverUrl + '/api/v1/file';
  uploadFileApi = this.appConfigService.serverUrl + '/api/v1/file/upload';
  processFileApi = this.appConfigService.serverUrl + '/api/v1/file/process';

  //GET, POST Gateway Unit Api's
  getGatewayUnitsBySearchFilterApi = this.appConfigService.serverUrl + '/api/v1/unit/gateway/';
  getGatwayTypeApi = this.appConfigService.serverUrl + '/api/v1/unit/type';
  assignGatewayApi = this.appConfigService.serverUrl + '/api/v1/unit/gateway';
  decommissionGatewayApi = this.appConfigService.serverUrl + '/api/v1/unit/gatewaydecommission';
  addGatewayFirmwareApi = this.appConfigService.serverUrl + '/api/v1/unit/GatewayFirmware';
  getGatwayFirmwareVersionApi = this.appConfigService.serverUrl + '/api/v1/unit/GetGatewayFirmware';
  getDigiFirmwareVersionApi = this.appConfigService.serverUrl + '/api/v1/unit/GetDigiFirmwareVersion';
  getDeviceGatwayTypeApi = this.appConfigService.serverUrl + '/api/v1/unit/DeviceGatewayType';
  getBuildingUnitsApi = this.appConfigService.serverUrl + '/api/v1/unit/building';
  getUnitDeviceGatewayApi = this.appConfigService.serverUrl + '/api/v1/unit/GetUnitDeviceGateway';
  bulkXmlUploadApi = this.appConfigService.serverUrl + '/api/v1/unit/bulkuploadunits';

  //GET, POST Commission Unit Api's
  getCommissionUnitsBySearchFilterApi = this.appConfigService.serverUrl + '/api/v1/unit/commissioning/';
  commissionApi = this.appConfigService.serverUrl + '/api/v1/unit/commissioning';
  endcpibtestApi = this.appConfigService.serverUrl + '/api/v1/unit/endcpibtest';
  getunitvvconfigApi = this.appConfigService.serverUrl + '/api/v1/unit/unitvvconfiguration';
  decommissionApi = this.appConfigService.serverUrl + '/api/v1/unit/decommissioning';
  getProductTypeApi = this.appConfigService.serverUrl + '/api/v1/unit/producttype';
  getOtisLinePhNumApi = this.appConfigService.serverUrl + '/api/v1/unit/getotislinephonenumber';
  getBuildingDetailsBySearchFilterApi = this.appConfigService.serverUrl + '/api/v1/epal/search';
  ePALCommissionApi = this.appConfigService.serverUrl + '/api/v1/epal/commission';
  getUnitsByBuildingIdApi = this.appConfigService.serverUrl + '/api/v1/unit/unitdetails';
  getEpalGatewayInfoByBuildingIdApi = this.appConfigService.serverUrl + '/api/v1/epal/EpalGatewayInfo';
  ePALGatewayDeCommissionApi = this.appConfigService.serverUrl + '/api/v1/epal/DecommissionEpalGateway';
  getBuildingUnitDeatilsByePalHwIdApi = this.appConfigService.serverUrl + '/api/v1/epal/buildingunits';
  ePALDeCommissionApi = this.appConfigService.serverUrl + '/api/v1/epal/decommission';
  ePALDecomAndCommissionApi = this.appConfigService.serverUrl + '/api/v1/epal/DecommAndCommEpalHwID';
  rebootEloViewDeviceApi = this.appConfigService.serverUrl + '/api/v1/unit/RebootDevice';
  getCommunicationPathApi = this.appConfigService.serverUrl + '/api/v1/unit/communicationpath';
  getDeviceTypesApi = this.appConfigService.serverUrl + '/api/v1/unit/cpibdevicetypes';
  getCountriesApi = this.appConfigService.serverUrl + '/api/v1/unit/country';
  getCPIBFirmwareApi = this.appConfigService.serverUrl + '/api/v1/unit/CPIBFirmwareVersion';
  addCPIBGatewayFirmwareApi = this.appConfigService.serverUrl + '/api/v1/unit/SaveCPIBFirmware';
  getCPIBRunningFirmwareApi = this.appConfigService.serverUrl + '/api/v1/unit/cpibrcn';
  getUnitWriteConfigApi = this.appConfigService.serverUrl + '/api/v1/unit/GetUnitConfigStatus';
  ePALAssignGatewayApi = this.appConfigService.serverUrl + '/api/v1/epal/AssignEpalGateway';
  getUnitDetailsApi = this.appConfigService.serverUrl + '/api/v1/unit/building';
  getUnitInfoApi = this.appConfigService.serverUrl + '/api/v1/unit/unitinfo';
  saveOtisLinePhonenumbersapi = this.appConfigService.serverUrl + '/api/v1/unit/otislinephonenumber';
  getUnitVVAlarmApi = this.appConfigService.serverUrl + '/api/v1/unit/GetAlarmHistoryStatus';
  saveTestStationPhonenumberApi = this.appConfigService.serverUrl + '/api/v1/unit/SaveTestStationPhoneNumber';
  getTestStationPhonenumberApi = this.appConfigService.serverUrl + '/api/v1/unit/GetTestStationPhoneNumber';
  deleteTestStationPhonenumberApi = this.appConfigService.serverUrl + '/api/v1/unit/DeleteTestStationPhoneNumber';
  
  //GET, POST, DELETE User Mangement  Api's
  getUsersApi: string = this.appConfigService.serverUrl + '/api/v1/user/search';
  userApi = this.appConfigService.serverUrl + '/api/v1/user';

  //GET G3MS Unit data Api's
  getG3MSUnitDetailsApi = this.appConfigService.serverUrl + '/api/v1/unit/g3ms';

  //GET G3MS Unit data Api's
  getBiProvisionSearchApi = this.appConfigService.serverUrl + '/api/v1/unit/SearchBIUnit';
  processBIUnitApi = this.appConfigService.serverUrl + '/api/v1/unit/BIUnitProvision';


  //GET Twilio phone number data Api's
  getSearchTwilioPhoneNumberApi = this.appConfigService.serverUrl + '/api/v1/unit/SearchTwilioPhoneNumber';
  purchaseTwilioPhoneNumber = this.appConfigService.serverUrl + '/api/v1/unit/PurchaseTwilioPhoneNumber';
  releaseTwilioPhoneNumber = this.appConfigService.serverUrl + '/api/v1/unit/ReleaseTwilioPhoneNumber';


  //GET EloView Api's
  getAppContentApi = this.appConfigService.serverUrl + '/api/v1/unit/EloViewAppContent';
  getEloViewDevicesApi = this.appConfigService.serverUrl + '/api/v1/unit/EloViewDevices';
  addContentToDeviceApi = this.appConfigService.serverUrl + '/api/v1/unit/InstallContentToDevice';

  //GET Reports Api's
  getunitsReportApi = this.appConfigService.serverUrl + '/api/v1/unit/UnitsReport';

  //GET EDI Orders Data Api's
  getEDIOrdersApi = this.appConfigService.serverUrl + '/api/v1/ediorder/orderdetails';

  //Get App Version Api's
  getVersionInfoApi = this.appConfigService.serverUrl + '/api/v1/master/appInfo';

  //GET Unit History Api's
  getUnitHistoryApi = this.appConfigService.serverUrl + '/api/v1/unit/unithistory';

  //EDI Order Priority Api's
  uploadEDIOrderApi = this.appConfigService.serverUrl + '/api/v1/ediorder/upload';
  getEDIOrderDetailsApi = this.appConfigService.serverUrl + '/api/v1/ediorder/GetEDIOrderInfo';
  eDIOrderDetailsUpdateApi = this.appConfigService.serverUrl + '/api/v1/ediorder/updatepriority';

  userInfo = {};

  getFileStatus() {
    return this.httpClient.get(this.getFileStatusApi)                                                     .
      pipe(
        map((data: FileStatus[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getFileType() {
    return this.httpClient.get(this.getFileTypeApi).
      pipe(
        map((data: FileType[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getFileDataOnLoad(filterBody: ProvisionFilter) {
    return this.httpClient.post(this.getFilesBySearchFilterApi, filterBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getFilesByFilterSearch(filterBody: ProvisionFilter) {
    return this.httpClient.post(this.getFilesBySearchFilterApi, filterBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  uploadFile(fileToUpload: File, deviceTypeId: number, highRiseNewEqp: boolean, elevatorTypeId: number, deviceTypeId2: number) {
    const formData: FormData = new FormData();
    formData.append('formFile', fileToUpload, fileToUpload.name);
    return this.httpClient.post(this.uploadFileApi + "?deviceTypeId=" + deviceTypeId + "&uiUpload=true&highRiseNewEqp=" + highRiseNewEqp + "&elevatorTypeId=" + elevatorTypeId + "&deviceTypeId2=" + deviceTypeId2, formData).
      pipe(
        map((data: any) => {
          return data;
        })
      )
  }

  deleteFile(id: number) {
    return this.httpClient.delete(this.deleteFileApi + "/" + id).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  processFile(idList: number[]) {
    return this.httpClient.post(this.processFileApi, idList).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  bulkUpload(unitNumbers: string) {
    return this.httpClient.post(this.bulkXmlUploadApi + "?unitNumbers=" + unitNumbers, unitNumbers).
      pipe(
        map((data: any) => {
          return data;
        })
      )
  }

  getUnitsByFilterSearch(filterBody: GatewayFilter) {
    return this.httpClient.get(this.getGatewayUnitsBySearchFilterApi + filterBody.searchField).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getGatewayType() {
    return this.httpClient.get(this.getGatwayTypeApi).
      pipe(
        map((data: GatewayType[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  addCPIBGatewayFirmware(requestBody: CPIBFirmware) {
    return this.httpClient.post(this.addCPIBGatewayFirmwareApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getCPIBRunningFirmware(CPIBMacAddress: string) {
    return this.httpClient.get(this.getCPIBRunningFirmwareApi + "?cpibMacAddress=" + CPIBMacAddress).
      pipe(
        map((data: CPIBRcnInfo[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getCPIBFirmware() {
    return this.httpClient.get(this.getCPIBFirmwareApi).
      pipe(
        map((data: GatewayType[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  assignGateway(requestBody: AssignGateway) {
    return this.httpClient.post(this.assignGatewayApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getDigiFirmwareVersion(gatewayMacAddress: any) {
    return this.httpClient.get(this.getDigiFirmwareVersionApi + '?gatewayMacAddress=' + gatewayMacAddress).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getCommissionUnitsByFilterSearch(srchUnitNumber: any) {
    return this.httpClient.get(this.getCommissionUnitsBySearchFilterApi + srchUnitNumber).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  startCPIBTest(requestBody: Commission) {
    return this.httpClient.post(this.commissionApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  endCPIBTest(requestBody: Commission) {
    return this.httpClient.post(this.endcpibtestApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getunitvvconfiguration(unitnumber: string, elevatorDeviceId: number) {
    return this.httpClient.get(this.getunitvvconfigApi + "?unitnumber=" + unitnumber +"&elevatorDeviceId=" + elevatorDeviceId).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  deCommissioning(requestBody: Commission) {
    return this.httpClient.patch(this.decommissionApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getUsers(requestBody: UserFilter) {
    return this.httpClient.post(this.getUsersApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  AddUser(requestBody: AddUser) {
    return this.httpClient.post(this.userApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  userStatusChange(id: number, status: string, aadObjId: string) {
    return this.httpClient.patch(this.userApi + "/" + id + "/" + status + "/" + aadObjId, "").
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  userRoleUpdate(id: number, roleId: number) {
    return this.httpClient.patch(this.userApi + "/" + roleId + "/" + id, "").
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getUserDetails(id: string) {
    return this.httpClient.get(this.userApi + "/" + id).
      pipe(
        map((data: any) => {
          this.userInfo = data;
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getG3MSUnitData(txtG3MSSrch: string) {
    return this.httpClient.get(this.getG3MSUnitDetailsApi + "/" + txtG3MSSrch).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getProductTypes() {
    return this.httpClient.get(this.getProductTypeApi).
      pipe(
        map((data: ProductType[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getOtisLinePhonenumbers() {
    return this.httpClient.get(this.getOtisLinePhNumApi).
      pipe(
        map((data: OtisLinePhonenumber[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getBuildingDetailsByFilterSearch(filterBody: EpalCommissionFilter) {
    return this.httpClient.post(this.getBuildingDetailsBySearchFilterApi, filterBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getUnitDetailsByBuildingId(buildingId: string) {
    return this.httpClient.get(this.getUnitsByBuildingIdApi + "?buildingId=" + buildingId).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getEpalGatewayInfoByBuildingId(buildingId: string) {
    return this.httpClient.get(this.getEpalGatewayInfoByBuildingIdApi + "?buildingId=" + buildingId).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  ePALCommissioning(requestBody: EpalCommission) {
    return this.httpClient.post(this.ePALCommissionApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  ePALDeCommissioning(ePALHardwareId: string, buildingId: string) {
    return this.httpClient.delete(this.ePALDeCommissionApi + "?EpalHardwareId=" + ePALHardwareId + "&BuildingId=" + buildingId).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  ePALDeCommAndCommission(requestBody: EpalDecomAndComm) {
    return this.httpClient.post(this.ePALDecomAndCommissionApi, requestBody).
      pipe(
        map((data: string) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  ePALGatewayDeCommission(decommission: EpalGatewayDecommission) {
    return this.httpClient.delete(this.ePALGatewayDeCommissionApi + "?gatewayMacAddress=" + decommission.gatewayMacAddress + "&buildingId=" + decommission.buildingId + "&deviceGatewayTypeId=" + decommission.deviceGatewayTypeId).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getBuildingUnitDetailsByePALHwId(ePAlHwId: string) {
    return this.httpClient.get(this.getBuildingUnitDeatilsByePalHwIdApi + "?EpalHardwareId=" + ePAlHwId).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getBIProvisionUnitBySearch(elevatorUnitNumber: string) {
    var data = [];
    return this.httpClient.get(this.getBiProvisionSearchApi + "?ElevatorUnitNumber=" + elevatorUnitNumber).
      pipe(
        map((response: any) => {
          data[0] = response;
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  BIUnitProcess(requestBody: BIDataProcess) {
    return this.httpClient.post(this.processBIUnitApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getCommunicationPaths() {
    return this.httpClient.get(this.getCommunicationPathApi).
      pipe(
        map((data: CommunicationPath[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getDeviceTypes() {
    return this.httpClient.get(this.getDeviceTypesApi).
      pipe(
        map((data: CpibDeviceTypes[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  gatewayDeCommission(requestBody: GatewayDecommission) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: requestBody
    };

    return this.httpClient.delete(this.decommissionGatewayApi, httpOptions).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }
  addGatewayFirmware(requestBody: GatewayFirmware) {
    return this.httpClient.post(this.addGatewayFirmwareApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getGatewayFirmwareVersion() {
    return this.httpClient.get(this.getGatwayFirmwareVersionApi).
      pipe(
        map((data: GatewayFirmware[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  public isAdmin(): boolean {
    return (this.userInfo["role"] !== null && this.userInfo["role"] !== undefined && this.userInfo["role"].toUpperCase().trim() === "ADMIN")
  }

  getSearchTwilioPhoneNumber(areaCode: string, countryCode: string, region: string, unitNumber:string ) {
    return this.httpClient.get(this.getSearchTwilioPhoneNumberApi + "?countryCode=" + countryCode 
    + "&areaCode=" + areaCode + "&region=" + region + "&unitNumber=" + unitNumber).
      pipe(
        map((data: GatewayFirmware[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  PurchaseTwilioPhoneNumber(requestBody: TwilioPhoneNumber) {
    return this.httpClient.post(this.purchaseTwilioPhoneNumber, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getCountries() {
    return this.httpClient.get(this.getCountriesApi).
      pipe(
        map((data: Countries[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  ReleaseTwilioPhoneNumber(requestBody: TwilioPhoneNumber) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: requestBody
    };
    return this.httpClient.delete(this.releaseTwilioPhoneNumber, httpOptions).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getEloViewAppContent() {
    return this.httpClient.get(this.getAppContentApi).
      pipe(
        map((data: EloviewAppContent[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getEloViewDevices(eloViewSerialNumber: string, lblMdlBldName: string) {
    return this.httpClient.get(this.getEloViewDevicesApi + "?eloViewSerialNumber=" + eloViewSerialNumber + "&buildingName=" + lblMdlBldName).
      pipe(
        map((data: EloviewDevices[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  addContentToDevice(requestBody: AddContentToDevice) {
    return this.httpClient.patch(this.addContentToDeviceApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  eloViewDeviceReboot(requestBody: EpalRebootRequest) {
    return this.httpClient.post(this.rebootEloViewDeviceApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getUnitDetailsReport(reportsfilter: ReportsFilter) {
    return this.httpClient.get(this.getunitsReportApi + "?fileType=" + reportsfilter.fileType + "&fromDate=" + reportsfilter.createdFromDate + "&toDate=" + reportsfilter.createdToDate).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getDeviceGateway() {
    return this.httpClient.get(this.getDeviceGatwayTypeApi).
      pipe(
        map((data: DeviceGatewayTypes[]) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getUnitDeviceGatewayType(unitNumber: string) {
    return this.httpClient.get(this.getUnitDeviceGatewayApi + "?unitNumber=" + unitNumber).
      pipe(
        map((data: string) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getBuildingUnits(unitNumber: string) {
    return this.httpClient.get(this.getBuildingUnitsApi + "?unitNumber=" + unitNumber).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getCPIBWriteConfigStatus(ElevatorUnitNumber: string, deckType: string) {
    return this.httpClient.get(this.getUnitWriteConfigApi + "?unitNumber=" + ElevatorUnitNumber + "&deckType=" + deckType).
      pipe(
        map((data: string) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getVVAlarmStatus(ElevatorUnitNumber: string, CountryCode: string, ElevatorDeviceId: number) {
    return this.httpClient.get(this.getUnitVVAlarmApi + "?unitNumber=" + ElevatorUnitNumber + "&CountryCode=" + CountryCode + "&ElevatorDeviceId=" + ElevatorDeviceId).
      pipe(
        map((data: string) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  ePALAssignGateway(requestBody: AssignEpalGateway) {
    return this.httpClient.post(this.ePALAssignGatewayApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getUnitInfo(ElevatorUnitNumber: string) {
    return this.httpClient.get(this.getUnitInfoApi + "?unitNumber=" + ElevatorUnitNumber).
      pipe(
        map((data: string) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  saveOtisLinePhonenumbers(requestBody: OtisLinePhonenumber) {
    return this.httpClient.post(this.saveOtisLinePhonenumbersapi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  saveTestStationOtisLinePh(requestBody: CPIBTestStationPhNumber) {
    return this.httpClient.post(this.saveTestStationPhonenumberApi, requestBody).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  deleteTestStationOtisLinePh() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.delete(this.deleteTestStationPhonenumberApi, httpOptions).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getTestStationOtisLinePh() {
    return this.httpClient.get(this.getTestStationPhonenumberApi).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }
  
  getEDIOrdersData(txtEdiOrdersPOSrch: string, txtEdiOrdersUNSrch: string) {
    return this.httpClient.get(this.getEDIOrdersApi + "?PONumber=" + txtEdiOrdersPOSrch + "&UnitNumber=" + txtEdiOrdersUNSrch).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getVersionInfo() {
    return this.httpClient.get(this.getVersionInfoApi).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  getUnitHistory(txtUnitSrch: string) {
    return this.httpClient.get(this.getUnitHistoryApi + "?unitNumber=" + txtUnitSrch).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  uploadEDIPriorityFile(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('formFile', fileToUpload, fileToUpload.name);
    return this.httpClient.post(this.uploadEDIOrderApi, formData).
      pipe(
        map((data: any) => {
          return data;
        })
      )
  }

  getEDIOrdersPrirorityData() {
    return this.httpClient.get(this.getEDIOrderDetailsApi).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          return throwError(error);
        })
      )
  }

  updateEDIPriorityData(PriorityRowData: ediorderpriority[]){
    return this.httpClient.patch(this.eDIOrderDetailsUpdateApi, PriorityRowData).    
    pipe(
      map((data: any) => {
        return data;
      })
    )
  }
}