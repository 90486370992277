<div id="biprovisioning-main-wraper">
  <div class="col-12 page-heading p-0">
    <h5>Service & MOD BI Data Provisioning</h5>
  </div>
  <div class="col-12 search-ft p-0 mt-3">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label name="lblBiPrvsnSrch" class="control-label">Elevator Unit Number</label>
          <input type="text" class="form-control" name="txtUnitSrch" maxlength="10" [(ngModel)]="txtUnitSrch"
            tabindex="1" (keydown.enter)="onKeyEnter()"/>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblFilePath" class="control-label">File Path</label>
          <input type="text" class="form-control" name="txtFilePath" [(ngModel)]="txtFilePath" tabindex="2" disabled />
        </div>
      </div>
      <div class="col choose-file-wrapper">
        <div class="form-group">
          <input type="file" title="Choose file" id="fileUpload" name="fileUpload" [(ngModel)]="fileUpload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            (change)="onFileChange($event)" tabindex="3">
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-sm-12 d-inline-flex p-0">
        <div class="col d-flex align-items-start">
          <div class="form-group">
            <button class="btn btn-primary mr-2" name="btnBiPrvsnSrch" tabindex="4" (click)="getGridDataOnSearch()">
              Search
            </button>
            <button class="btn btn-outline-primary mr-2" name="btnBiPrvsnReset" tabindex="5" (click)="onResetFilters()">
              Reset
            </button>
          </div>         
          
        </div>
        <div id="gridActions" class="ml-auto">
          <label *ngIf="unitFoundFile" class="mr-2" name="lblPRUpload" (click)="onFileUpload()" tabindex="7"><span>
              <em class="fa fa-upload"></em></span> Upload</label>
          <label *ngIf="!unitFoundFile" class="mr-2" name="lblPRProcess" title="Select a File to Upload"
            tabindex="6"><span>
              <em class="fa fa-upload"></em></span>
            Upload</label>
          <label *ngIf="isRowSelected" class="mr-2" name="lblPRProcess" (click)="open(processModal)" tabindex="4"><span>
              <em class="fa fa-angle-double-right"></em></span>
            Process</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblPRProcess" title="Select a unit to Process"
            tabindex="7"><span> <em class="fa fa-angle-double-right"></em></span>
            Process</label>
            </div>
      </div>
    </div>
  </div>
  <div>
    <ag-grid-angular #agGridBiProvision [enableCellTextSelection]="true" class="ag-theme-material mt-2 biprovision-layout" [rowData]="rowData"
      [columnDefs]="columnDefs" [gridOptions]="gridOptions" tabindex="8" (rowSelected)="onRowSelected($event)">
    </ag-grid-angular>
  </div>
  
  <label class="mr-2" id="lblopen" name="lblPRProcess" (click)="open(ddunitupload)" tabindex="4" [hidden]="true"><span>
    <em class="fa fa-angle-double-right"></em></span>
  Open</label>

  <ng-template #ddunitupload let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Double Deck BI Unit Upload</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header"> 
            <h5>Lower Deck</h5>
          </div>
          <div class="card-body d-inline-flex">
          <div class="col-12">
              <label name="lblNLDDeviceType" class="control-label">Device Type
              </label>
                
              <select name="ddNLDDeviceType" class="form-control" (change)="LDUploadSelectChanged($event)">
                <option *ngFor="let deviceType of LDdeviceTypes">
                  {{ deviceType.deviceType }}
                </option>
              </select>
              <div class="lblerror">
                {{ this.LDUdeviceTypeError }}
              </div>
            </div>                                                      
              
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header"> 
              <h5>Upper Deck</h5>
            </div>
            <div class="card-body d-inline-flex">
            <div class="col-12">
                <label name="lblNUDDeviceType" class="control-label">Device Type
                </label>
                  
                <select name="ddNUDDeviceType" class="form-control" (change)="UDUploadSelectChanged($event)">
                  <option *ngFor="let deviceType of UDdeviceTypes">
                    {{ deviceType.deviceType }}
                  </option>
                </select>
                <div class="lblerror">
                  {{ this.UDUdeviceTypeError }}
                </div>
              </div>                                                      
                
              </div>
            </div>
          </div>
    </div>
    <button type="button" class="btn btn-primary mr-2 mt-2" (click)="onFileUpload()">
      Upload
    </button>
    <button type="button" class="btn btn-outline-primary mr-2 mt-2" (click)="modalClose()">
      Close
    </button>
      </div>
    </div>
  </ng-template>
  <!-- BI Provision Process Modal Popup HTML -->
  <ng-template #processModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">BI Unit Process</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft">
        <div class="row">
          <div class="col">
            <div class="">
                  Are you sure want to process the below selected unit .. ?
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="">        
                  Unit Number :
                  <strong>{{
                    this.rowSelectedData[0].elevatorUnitNumber
                    }}</strong>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class=""> 
                  File Id :
                  <strong>{{ this.rowSelectedData[0].fileId }}</strong>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class=""> 
                  Building Name:
                  <strong>{{ this.rowSelectedData[0].buildingName }}</strong>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class=""> 
                  Building Address:
                  <strong>{{ this.rowSelectedData[0].address }}</strong>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class=""> 
                  <label name="lblCommPath" class="control-label">Communication Path
                  </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col  pr-0 pb-2">
            <div class=""> 
                  <select name="ddCommPath" class="form-control col-12" (change)="commPathSelectChanged($event)">
                    <option value="0" selected>--- Select ---</option>
                    <option *ngFor="let commPath of commPaths" [selected]="commPath.id === commPathSelectedId"
                      [value]="commPath.id">
                      {{ commPath.name }}
                    </option>
                  </select>
                 </div>
              </div>
              <div class="col-6 d-inline-flex align-items-center">
                <input type="checkbox" class="d-flex cuscheckbox" [disabled]="disableDD" name="checkDD" [(ngModel)]="isDoubleDeckUnit">
                <label name="lblDD" class="control-label d-flex mb-0 ml-2">Double Deck</label>          
              </div>
            </div>
            <div class="row" *ngIf="!isDoubleDeckUnit">
              <div class="col">
                <div class=""> 
                  <label name="lblDeviceType" class="control-label required">Device Type
                  </label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!isDoubleDeckUnit">
              <div class="col">
                <div class=""> 
                  <select name="ddDeviceType" class="form-control col-6" (change)="deviceTypeSelectChanged($event)">
                    <option value="0" selected>--- Select ---</option>
                    <option *ngFor="let deviceType of deviceTypes"
                      [selected]="deviceType.devicetypeid === deviceTypeSelectedId" [value]="deviceType.deviceTypeId">
                      {{ deviceType.deviceType }}
                    </option>
                  </select>
                  <div class="lblerror">
                    {{ this.deviceTypeError }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="isDoubleDeckUnit">
              <div class="col">
                <div class="card">
                  <div class="card-header"> 
                    <h5>Lower Deck</h5>
                  </div>
                  <div class="card-body d-inline-flex">
                  <div class="col-12">
                      <label name="lblLDDeviceType" class="control-label">Device Type
                      </label>
                        
                      <select name="ddLDDeviceType" class="form-control" (change)="LDdeviceTypeSelectChanged($event)">
                        <option *ngFor="let deviceType of LDdeviceTypes">
                          {{ deviceType.deviceType }}
                        </option>
                      </select>
                      <div class="lblerror">
                        {{ this.LDdeviceTypeError }}
                      </div>
                    </div>                                                      
                      
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-header"> 
                      <h5>Upper Deck</h5>
                    </div>
                    <div class="card-body d-inline-flex">
                    <div class="col-12">
                        <label name="lblUDDeviceType" class="control-label">Device Type
                        </label>
                          
                        <select name="ddUDDeviceType" class="form-control" (change)="UDDeviceTypeSelectChanged($event)">
                          <option *ngFor="let deviceType of UDdeviceTypes">
                            {{ deviceType.deviceType }}
                          </option>
                        </select>
                        <div class="lblerror">
                          {{ this.UDdeviceTypeError }}
                        </div>
                      </div>                                                      
                        
                      </div>
                    </div>
                  </div>
            </div>   
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="onBIUnitProcess($event)">
        Process
      </button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose()">
        Close
      </button>
    </div>
  </ng-template>
</div>