<div id="commissioining-main-wraper">
  <div class="col-12 page-heading p-0 d-inline-flex">
    <h5>CPIB2 Commissioning</h5>
  </div>
  <div class="col-12 search-ft p-0 mt-3">
    <div class="row">
      <div>
        <div class="form-group searchAlign">
          <label name="lblCOMSrchUnit" class="control-label">Unit Number / Building Name /
            Address</label>
          <input type="text" class="form-control" name="txtCOMSrchUnit" [(ngModel)]="txtCOMSrchUnit" maxlength="20"
            tabindex="1" (keydown.enter)="onSearchKeyEnter()">
        </div>
      </div>
      <div class="col d-flex align-items-end">
        <div class="form-group buttonAlignments">
          <button class="btn btn-primary mr-2" name="btnCOMSearch" (click)="getGridDataByFilterSearch()"
            tabindex="2">Search</button>
          <button class="btn btn-outline-primary mr-2" name="btnCOMReset" (click)="resetFilters($event)"
            tabindex="3">Reset</button>
        </div>
        <div id="gridActions" class="ml-auto com-btn-test">
          <div class="row">
            <label class="mr-2" name="OtisLineNumber" *ngIf="isAdmin" (click)="open(otisLineNumber)"
              title="Click this link to show and modify Otisline Phone Numbers">
              <span><em class="fa fa-phone"></em></span>
              OtisLineNumber</label>
            <label class="mr-2" name="firmware" *ngIf="isAdmin" (click)="open(firmwareModal)"
              title="Click this link to show and modify CPIB2 Firmware"><span><em class="fa fa-download"></em></span>
              Firmware</label>
            <label *ngIf="isRowSelected" class="mr-2" name="lblEnaPhoneNum" (click)="open(phnModal)"><span>
                <em class="fa fa-mobile"></em></span> Twilio Phone Number</label>
            <label *ngIf="!isRowSelected" class="mr-2" name="lblDisPhoneNum"
              title="Select the unit to Twilio Phone Number"><span>
                <em class="fa fa-mobile"></em></span> Twilio Phone Number</label>

            <label *ngIf="isRowSelected" class="mr-2" name="lblEnaCpibCom" (click)="open(commModal)"><span>
                <em class="fa fa-tasks"></em></span> Commission</label>
            <label *ngIf="!isRowSelected" class="mr-2" name="lblDisCpibCom" title="Select the unit to commission"><span>
                <em class="fa fa-tasks"></em></span> Commission</label>

            <label *ngIf="isRowSelected" class="mr-2" name="lblEnaCpibDecom" (click)="open(DecommModal)"><span>
                <em class="fa fa-unlink"></em></span> Decommission</label>
            <label *ngIf="!isRowSelected" class="mr-2" name="lblDisCpibDecom"
              title="Select the unit to Decommission"><span>
                <em class="fa fa-unlink"></em></span> Decommission</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ag-grid-angular #agGridCommission [enableCellTextSelection]="true"
      class="ag-theme-material mt-2 commissioning-layout" [rowData]="rowData" [columnDefs]="columnDefs"
      [gridOptions]="gridOptions" (rowSelected)="onRowSelected($event)">
    </ag-grid-angular>
  </div>

  <!-- Commissioing Modal Popup HTML -->
  <ng-template #commModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">CPIB2 Commissioning</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose('Comm Cross click')">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-0 mt-3">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label name="lblCOMMlUnitNum" class="control-label">Unit Number</label>
              <input type="text" class="form-control" name="txtUnitNumNDeck" [value]="txtUnitNumNDeck" disabled>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblCOMMlUuid" class="control-label">CP-ID/UUID</label>
              <input type="text" class="form-control" name="txtCOMMlUuid" [value]="txtCOMMlUuid" disabled>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblCOMMlCPIBHwId" class="control-label required">CPIB2 MAC
                Address</label>
              <input [disabled]="isCommissionDisabled" type="text" class="form-control" name="txtCOMMlCPIBHwId"
                [(ngModel)]="txtCOMMlCPIBHwId" maxlength="25">
            </div>
            <div class="lblerror" *ngIf="lblErrorCOMMLCPIBHwId">
              {{ this.lblErrorCOMMLCPIBHwId }}
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblCCType" class="control-label">ePAL UUID</label>
              <input [disabled]="isCommissionDisabled" type="text" class="form-control" name="txtePALUUID"
                [(ngModel)]="txtePALUUID">
            </div>
          </div>
        </div>
        <div class="row align-items-baseline">
          <div class="col">
            <div class="form-group">
              <label name="lblCOMMVersion" class="control-label">OTIS ONE Compatible
                Firmware</label>
              <input disabled type="text" class="form-control" name="txtCOMMVersion" [(ngModel)]="txtCOMMVersion"
                maxlength="25">
            </div>
            <div class="lblerror" *ngIf="lblErrorCOMMLCPIBVersion">
              {{ this.lblErrorCOMMLCPIBVersion }}
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblCOMMRunningFirmware" class="control-label">Running Firmware
                Version from CP Dashboard</label>
              <input disabled type="text" class="form-control" name="txtSuggestedVersion"
                [(ngModel)]="txtSuggestedVersion" maxlength="25">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblCCType" class="control-label">Customer Call Type</label>
              <select [disabled]="!isCommissionDisabled" name="ddCCType" class="form-control"
                [(ngModel)]="CCTypeSelectedId">
                <option *ngFor="let type of CCTypes" [value]="type.id">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblCCType" class="control-label">eView/Otisone Phone Number</label>
              <input disabled type="text" class="form-control" name="txtOPN" [value]="txteViewOPN">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label name="lblOPhone1" class="control-label">OtisLine Phone 1</label>
              <input type="text" class="form-control" name="txtOPOne" [value]="txtOLPhone1" disabled>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblOCN" class="control-label">OtisLine Callback Number</label>
              <input type="text" class="form-control" name="txtOCN" [value]="txtOLCallbackNumber" disabled>
            </div>
          </div>
          <div class="col d-flex align-items-end-new">
            <div class="form-group">
              <input type="checkbox" class="cuscheckbox" name="checkDNCOL" [(ngModel)]="chkDNCOL">
              <label name="lblDNCOL" class="control-label">Do Not Call OtisLine</label>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group" *ngIf="lblVersionMessage" [ngClass]="isBtnGetFirmware ? 'greenlabel' : 'redlabel'">
        <label name="lblmessage">{{ lblVersionMessage }}</label>
      </div>
    </div>
    <div class="row">
      <div style="font-size: 14px;" class="form-group" *ngIf="lblWriteConfigMessage"
        [ngClass]="writeConfigStyle ? 'greenlabel' : 'redlabel'">
        Status of Field Options on CPIB2(G3MS Write Config) : <label style="font-weight: bold;" name="lblWCmessage">{{
          lblWriteConfigMessage }}</label>
      </div>
    </div>
    <div class="row">
      <div style="font-size: 14px;" class="form-group" *ngIf="lblAlarmStatusMessage"
        [ngClass]="alarmStatusStyle ? 'greenlabel' : 'redlabel'">
        <label [innerHTML]="lblAlarmStatusMessage" name="lblASmessage"></label>
      </div>
    </div>
    <div class="row">
      <div style="font-size: 14px;" class="form-group" *ngIf="lblTwilioErrorMessage" ngClass='redlabel'>
        <label name="lblTwiliomessage">{{ lblTwilioErrorMessage }}</label>
      </div>
    </div>
    <div class="modal-footer">
      <button [disabled]="isCommissionDisabled" type="button" class="btn btn-primary mr-2"
        (click)="onStartCommission($event)">Start Test</button>
      <button type="button" class="btn btn-primary mr-2" [disabled]="!isCommissionDisabled"
        (click)="onEndCPIBTest($event)">End Test</button>
      <button type="button" class="btn btn-primary mr-2" (click)="onGetRunningFirmwareAndtWriteConfigStatus($event)"
        [disabled]="!isCommissionDisabled">
        Get CPIB2 Details
      </button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('Cross click')">Close</button>
    </div>
  </ng-template>

  <!-- Decommission Modal Popup HTML -->
  <ng-template #DecommModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">CPIB2 Decommissioning</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-2 mt-3">
        <div class="row">
          <div class="col">
            <div class="form-group">
              Are you sure want to Decommission the selected Unit ..?
              <p class="text-style"></p>
              <p class="text-style">Unit Number : {{ txtUnitNumNDeck }}</p>
              <p class="text-style">CP-ID/UUID : {{ rowSelectedData["cpidUuid"] }}</p>
              <p class="text-style">CPIB2 MAC Address : {{ rowSelectedData["cpibHwid"] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" name="btnUMMLYes" class="btn btn-primary mr-2"
        (click)="OnDecommissioinCPIB()">Decommission</button>
      <button type="button" name="btnUMMLNo" class="btn btn-outline-primary mr-2"
        (click)="modalClose('Save click')">Close</button>
    </div>
  </ng-template>

  <!-- Commissioing Modal Popup HTML -->
  <ng-template #phnModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Twilio Phone Number</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-0 mt-3">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label name="lblCOMMlUnitNum" class="control-label">Unit Number</label>
              <input type="text" class="form-control" name="txtUnitNumNDeck" [value]="txtUnitNumNDeck" disabled>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label name="lblCOMMlCPIBHwId" class="control-label">CPIB2 MAC
                Address</label>
              <input type="text" class="form-control" name="txtCOMMlCPIBHwId" [(ngModel)]="txtCOMMlCPIBHwId"
                maxlength="25" disabled>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label name="txtCOMMLCountryName" class="control-label ">Country</label>
              <input type="text" class="form-control" name="txtCOMMLCountryName" [(ngModel)]="txtCOMMLCountryName"
                disabled>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label name="lblSearchType" class="control-label required">Search Type</label>
              <select [disabled]="purchaseBtnDisable" name="ddSearchType" class="form-control"
                (change)="searchTypeSelectChanged($event)">
                <option *ngFor="let searchType of searchTypes" [selected]="searchType.id === searchTypeSelectedId"
                  [value]="searchType.id">{{searchType.name}}</option>
              </select>
            </div>
            <div class="lblerror" *ngIf="lblErrorSearchType">
              {{ this.lblErrorSearchType }}
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label name="txtAreaOrRegion" class="control-label required">Area/Region</label>
              <input type="text" class="form-control" name="txtAreaOrRegion" [(ngModel)]="txtAreaOrRegion"
                maxlength="32" [disabled]="purchaseBtnDisable" (keydown.enter)="onPhoneSearchKeyEnter()">
            </div>
            <div class="lblerror" *ngIf="lblErrorAreaCode">
              {{ this.lblErrorAreaCode }}
            </div>
          </div>

          <div class="col col-2">
            <div class="form-group search-top">
              <button [disabled]="purchaseBtnDisable" type="button" class="btn btn-primary mr-2"
                (click)="onSearchPhoneNumber(txtAreaOrRegion)">Search</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label name="txtPhoneNumber" class="control-label">Phone Number</label>
              <input type="text" class="form-control" name="txtPhoneNumber" [(ngModel)]="txtPhoneNumber" disabled>
            </div>
            <div class="lblerror" *ngIf="lblErrorPhoneNum">
              {{ this.lblErrorPhoneNum }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button [disabled]="purchaseBtnDisable" type="button" class="btn btn-primary mr-2"
        (click)="onPurchaseTwilioPhoneNumber($event)">Purchase</button>
      <button [disabled]="releaseBtnDisable" type="button" class="btn btn-primary mr-2"
        (click)="onReleaseTwilioPhoneNumber($event)">Release</button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('Cross click')">Close</button>
    </div>
  </ng-template>

  <!-- Firm ware Modal Popup HTML -->
  <ng-template #firmwareModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Configuration</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-0 mt-6">
        <div class="row">
          <div class="col">
            <label name="lblGWMlUnitNum" class="control-label">Current Firmware Versions</label>
            <div style="width: 100%; overflow: auto">
              <table class="table-ml" border-width="1">
                <tr>
                  <th id="unitNum">IoT Device Type</th>
                  <th id="bldName">Firmware Version</th>
                  <th id="bldName">Created By</th>
                </tr>
                <tr *ngFor="let row of CPIBFirmware">
                  <td>{{ row.name }}</td>
                  <td>{{ row.firmwareVersion }}</td>
                  <td>{{ row.createdBy }}</td>
                </tr>
              </table>
              <br>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label name="lblCPIBMlMacAdd" class="control-label required">New CPIB2 Firmware
              </label>
              <input type="text" class="form-control" name="txtCPIBFirmwareVersion" [(ngModel)]="txtCPIBFirmwareVersion"
                maxlength="32" (focus)="clearErrorMessages()" />
            </div>
            <div class="lblerror" *ngIf="lblErrorCPIBFWVersionAdd">
              {{ this.lblErrorCPIBFWVersionAdd }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="onSaveCPIBFirmware()">
        Submit
      </button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('Cross click')">
        Close
      </button>
    </div>
  </ng-template>

  <!-- OtisLineNumber Modal Popup HTML -->
  <ng-template #otisLineNumber let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">OtisLine Phone Numbers</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-0 mt-6">
        <div class="row">
          <div class="col">
            <label name="lblOtisLinePhNum" class="control-label">Current OtisLine PhoneNumbers
            </label>
            <div style="width: 100%; overflow: auto">
              <table class="table-ml" border-width="1">
                <tr>
                  <th id="otisLinePhone1">OtisLine Phone1</th>
                  <th id="otisLineCallbackNumber">OtisLine Callback Number</th>
                  <th id="createdBy">Created By</th>
                </tr>
                <tr>
                  <td>{{ otisLinePhNum["otisLinePhone1"] }}</td>
                  <td>{{ otisLinePhNum["otisLineCallbackNumber"] }}</td>
                  <td>{{ otisLinePhNum["createdBy"] }}</td>
                </tr>
              </table>
              <br>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label name="lblOtisLinePhone1" class="control-label required">OtisLine Phone1
              </label>
              <input type="text" class="form-control" name="txtOtisLinePhon1" [(ngModel)]="txtOtisLinePhone1"
                maxlength="20" (focus)="clearErrorMessages()" />
            </div>
            <div class="lblerror" *ngIf="lblErrorOtisLinePhone1">
              {{ this.lblErrorOtisLinePhone1 }}
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label name="lblErrorOtisLineCallbackNum" class="control-label required"> OtisLine CallbackNumber
              </label>
              <input type="text" class="form-control" name="txtOtisLineCallbackNum" [(ngModel)]="txtOtisLineCallbackNum"
                maxlength="20" (focus)="clearErrorMessages()" />
            </div>
            <div class="lblerror" *ngIf="lblErrorOtisLineCallbackNum">
              {{ this.lblErrorOtisLineCallbackNum }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="onSaveOtisLinePhNumbers()">
        Submit
      </button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="ClearOtisPhNumModal()">
        Close
      </button>
    </div>
  </ng-template>

  <!-- Test Station Phone number Modal Popup HTML -->
  <ng-template #testStationModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Test Station Phone Number</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-0 mt-6">
        <div class="row">
          <div class="col">
            <label name="lblOtisLinePhNum" class="control-label">Current Test Station OtisLine Number
            </label>
            <div style="width: 100%; overflow: auto">
              <table class="table-ml" border-width="1">
                <tr>
                  <th id="otisLinePhone1">Test Station OtisLine Number</th>
                  <th id="TestStationDelink">Action</th>
                </tr>
                <tr *ngIf="txtTestStationPhoneNumber">
                  <td>{{ txtTestStationPhoneNumber }}</td>
                  <td> <a href="javascript:void(0);" (click)="onDeleteTestStationPhoneNum()">Delete</a></td>
                </tr>
              </table>
              <br>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label name="lblTestStation" class="control-label required">Test Station OtisLine Number
              </label>
              <input type="text" numbersOnly class="form-control" name="txtPTestStationNum"
                [(ngModel)]="txtPTestStationNum" maxlength="15" (focus)="clearErrorMessages()" />
            </div>
            <div class="lblerror" *ngIf="lblErrorTestStationNum">
              {{ this.lblErrorTestStationNum }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="onSaveTestStationNumber()">
        Submit
      </button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('Cross click')">
        Close
      </button>
    </div>
  </ng-template>