import { Injectable, OnInit } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppConfigService } from './app-config.service'

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  appInsights: ApplicationInsights;
  constructor(private appConfigService: AppConfigService) { 
    if(this.appConfigService.loaded)
    {
      this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey:this.appConfigService.GetAIInstrKey,
        enableAutoRouteTracking: true 
      }
      });
      this.appInsights.loadAppInsights();
    }    
  }
  logPageView(name?: string, url?: string) {
    if(this.appConfigService.loaded)
    { 
      this.appInsights.trackPageView({
      name: name,
      uri: url
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if(this.appConfigService.loaded) {
      this.appInsights.trackEvent({ name: name}, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if(this.appConfigService.loaded) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if(this.appConfigService.loaded) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if(this.appConfigService.loaded && this.appInsights == undefined) {
      this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey:this.appConfigService.GetAIInstrKey,
        enableAutoRouteTracking: true 
      }
      });
      this.appInsights.loadAppInsights();
    } 
    if(message==null || message=="")
     message="No request data available."
    this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information}, properties);
  }
}
