import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Service } from '../service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { UserFilter } from '../common/models/userfilter';
import { AddUser } from '../common/models/adduser';
import { FCToasterService } from '../common/toaster/FCToasterService';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent implements OnInit {

  closeResult: string;
  @ViewChild("agGridUserManagement", { static: false }) agGridUserManagement: AgGridAngular;

  rowData: any[];
  columnDefs: any[];
  userRoles: any[];
  userStatus: any[];
  gridOptions: GridOptions;
  txtUMFName: any;
  txtUMLName: any;
  txtUMEmail: any;
  txtUMMLFName: any;
  txtUMMLLName: any;
  txtUMMLDName: any;
  txtUMMLEmailId: any;
  isRowSelected: boolean = false;
  rowSelectedData: {};
  userRoleSelectedId: number;
  userRoleSelectedValue: any;
  userStatusSelectedId: number;
  userStatusSelectedValue: any;
  userRoleMLSelectedId: number;
  userRoleMLSelectedValue: any;
  selectedUserRole: any = {};
  selectedUserStatus: any = {};
  selectedMLUserRole: any = {};
  userSearchFilter = {} as UserFilter;
  addUser = {} as AddUser;
  selectedUser = [];
  lblErrMLFName: string;
  lblErrMLLName: string;
  lblErrMLDName: string;
  lblErrMLEmail: string;
  lblErrMLRole: string;
  selUserRole: string;
  updatedRoleId: number;
  isUserActive: boolean = false;
  
  constructor(private apiService: Service, private modalService: NgbModal, private toastr: FCToasterService) { }
  ngOnInit(): void {
    this.bindGridOnPageLoad();
    this.bindMasterDataFilters();
    this.bindDefaultValuesOnLoad();

    this.gridOptions = {
      defaultColDef: {filter:true},
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true
    }
  }

  //Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "", field: "cbox", checkboxSelection: true, width: 60 },
      { headerName: "ID", field: "id", hide: true },
      { headerName: "AAD Object ID", field: "aadObjectId", hide: true },
      { headerName: "First Name", field: "firstName", width: 170, tooltipField: "firstName", headerTooltip: "First Name" },
      { headerName: "Last Name", field: "lastName", sortable: true, width: 170, tooltipField: "lastName", headerTooltip: "Last Name" },
      { headerName: "Display Name", field: "displayName", width: 180, tooltipField: "displayName", headerTooltip: "Display Name" },
      { headerName: "Email Id", field: "emailId", sortable: true, width: 200, resizable: true, tooltipField: "emailId", headerTooltip: "Email Id" },
      { headerName: "Role", field: "role", sortable: true, width: 150, tooltipField: "role", headerTooltip: "Role" },
      { headerName: "Status", field: "status", sortable: true, width: 150, tooltipField: "status", headerTooltip: "Status" },
      { headerName: "Created By", field: "createdBy", sortable: true, width: 170, tooltipField: "createdBy", headerTooltip: "Created By" },
      { headerName: "Created Date", field: "createdDate", sortable: true, width: 200, resizable: true, tooltipField: "createdDate", headerTooltip: "Created Date" }
    ];
    this.rowData = [];
    this.getGridDataOnPageLoad();
  }

  //Bind master data in filetrs on page load
  bindMasterDataFilters() {
    this.userRoles = [
      { id: 1, name: "User" },
      { id: 2, name: "Admin" }
    ]

    this.userStatus = [
      { id: 0, name: "InActive" },
      { id: 1, name: "Active" }
    ]
  }

  // Assign default values to variables on Page load
  bindDefaultValuesOnLoad() {
    this.txtUMFName = "";
    this.txtUMLName = "";
    this.txtUMEmail = "";
    this.selectedUserRole = { id: 0, name: "--- Select Role ---" };
    this.selectedUserStatus = { id: -1, name: "--- Select Status ---" };
    this.selectedMLUserRole = { id: 0, name: "--- Select Role ---" };
    this.userSearchFilter = { firstName: "", lastName: "", emailId: "", role: "", status: "" };
    this.addUser = {};
    this.txtUMMLFName = "";
    this.txtUMMLLName = "";
    this.txtUMMLDName = "";
    this.txtUMMLEmailId = "";
    this.selectedUser = [];
    this.rowSelectedData = {};
    this.lblErrMLFName = "";
    this.lblErrMLLName = "";
    this.lblErrMLDName = "";
    this.lblErrMLEmail = "";
    this.lblErrMLRole = "";
    this.userRoleMLSelectedId = 0;
    this.userRoleSelectedId = 0;
    this.userStatusSelectedId = 0;
    this.isRowSelected = false;
  }

  //Get the default grid units data on load
  getGridDataOnPageLoad() {
    this.apiService
      .getUsers(this.userSearchFilter)
      .subscribe((data: any) => {
        if (data != null) {
          this.rowData = data;
        }
        else {
          this.rowData = [];
        }
      });
  }

  //Get Grid data on filter search
  getGridDataByFilterSearch() {
    this.userSearchFilter.firstName = (this.txtUMFName !== null && this.txtUMFName !== undefined) ? this.txtUMFName.trim() : "";
    this.userSearchFilter.lastName = (this.txtUMLName !== null && this.txtUMLName !== undefined) ? this.txtUMLName.trim() : "";
    this.userSearchFilter.emailId = (this.txtUMEmail !== null && this.txtUMEmail !== undefined) ? this.txtUMEmail.trim() : "";
    this.userSearchFilter.role = (this.userRoleSelectedId !== 0) ? this.userRoleSelectedValue : "";
    this.userSearchFilter.status = (this.userStatusSelectedId !== -1) ? this.userStatusSelectedValue : "";
        
    this.isRowSelected = false;
    this.rowSelectedData = {};
    this.selectedUser = [];

    this.apiService
      .getUsers(this.userSearchFilter)
      .subscribe((data: any) => {
        if (data != null) {
          this.rowData = data;
        }
        else {
          this.rowData = [];
        }
      });
  }

  //Reset Filters
  resetFilters() {
    this.bindDefaultValuesOnLoad();
    this.bindGridOnPageLoad();
  }

  //User Role Type drop down seletion changed in Search filter
  userRoleSelectChanged($event) {
    this.userRoleSelectedId = $event.target.selectedIndex;
    this.userRoleSelectedValue = $event.target.options[$event.target.selectedIndex].text;
  }

  //User Status drop down seletion changed in Search filter
  userStatusSelectChanged($event) {
    this.userStatusSelectedId = $event.target.selectedIndex;
    this.userStatusSelectedValue = $event.target.options[$event.target.selectedIndex].text;
  }

  //User Role Type drop down seletion changed in modal pop up
  userRoleModalSelectChanged($event) {
    this.userRoleMLSelectedId = $event.target.selectedIndex;
    this.userRoleMLSelectedValue = $event.target.options[$event.target.selectedIndex].text;
  }

  updatedUserRoleModalSelectChanged($event) {
    this.updatedRoleId = $event.target.selectedIndex;
  }

  // Commission Modal Popup
  open(content) {
    if (content._declarationTContainer.localNames[0] == 'userUpdateModal') {
      this.selUserRole = this.rowSelectedData["role"];
      
      let index = this.userRoles.findIndex( filterUserObj=> 
        filterUserObj['name'] === this.selUserRole);

      if (index !== -1) {
        this.userRoles.splice(index, 1);
      }
      this.updatedRoleId = this.userRoles[0].id;
      if (this.rowSelectedData["status"].toUpperCase().trim() === "INACTIVE") {
        this.lblErrMLRole = "Please make sure selected user should be in Active Status"
        this.isUserActive = true;
      }
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // Close Modal Popup
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //Grid row selection event
  onRowSelected(event) {
    if (event.node.selected) {
      this.selectedUser.push(event.node.data);
    }
    else {
      var index = this.selectedUser.indexOf(event.node.data);
      this.selectedUser.splice(index, 1);
    }

    if (this.selectedUser.length === 1) {
      this.isRowSelected = true;
      this.rowSelectedData = this.selectedUser[0];
    }
    else {
      this.isRowSelected = false;
      this.rowSelectedData = {};
    }
  }

  // Close Modal Popup
  modalClose(content) {
    this.modalService.dismissAll();
    this.resetModal();
    this.bindMasterDataFilters();
  }

  resetModal() {
    this.txtUMMLFName = "";
    this.txtUMMLLName = "";
    this.txtUMMLDName = "";
    this.txtUMMLEmailId = "";
    this.selectedMLUserRole = { id: 0, name: "--- Select Role ---" };
    this.userRoleMLSelectedId = 0;
    this.lblErrMLDName = "";
    this.lblErrMLEmail = "";
    this.lblErrMLFName = "";
    this.lblErrMLRole = "";
    this.lblErrMLLName = "";
    this.isUserActive = false;
  }

  userActive() {
    if (this.rowSelectedData["id"] !== null && this.rowSelectedData["id"] !== undefined && this.rowSelectedData["aadObjectId"] !== "") {
      if (this.rowSelectedData["status"].toUpperCase().trim() === "ACTIVE") {
        this.toastr.showWarning("Already selected user " + this.rowSelectedData["firstName"] + " is in Active status");
      }
      else {
        this.apiService
          .userStatusChange(this.rowSelectedData["id"], "1", this.rowSelectedData["aadObjectId"])
          .subscribe((data: any) => {
            if (data == true) {
              this.toastr.showSuccess("Succesfully activated the user !!");
              this.modalClose("modal close");
              this.resetFilters();
            }
          });
      }
    }
    else {
      this.toastr.showError("Please select the user to Activate");
    }
  }
  
  userRoleUpdate(){
    if (this.rowSelectedData["id"] !== null && this.rowSelectedData["id"] !== undefined && this.rowSelectedData["aadObjectId"] !== "") {
      this.apiService
        .userRoleUpdate(this.rowSelectedData["id"], this.updatedRoleId)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Succesfully Updated the user Role !!");
            this.modalClose("modal close");
            this.resetFilters();
          }
        });
    }
  }

  userInActive() {
    if (this.rowSelectedData["id"] !== null && this.rowSelectedData["id"] !== undefined && this.rowSelectedData["aadObjectId"] !== "") {
      if (this.rowSelectedData["status"].toUpperCase().trim() === "INACTIVE") {
        this.toastr.showError("Already selected user " + this.rowSelectedData["firstName"] + " is in InActive status");
      }
      else {
        this.apiService
          .userStatusChange(this.rowSelectedData["id"], "0", this.rowSelectedData["aadObjectId"])
          .subscribe((data: any) => {
            if (data == true) {
              this.toastr.showSuccess("Succesfully Inactivated the user !!");
              this.modalClose("modal close");
              this.resetFilters();
            }
          });
      }
    }
    else {
      this.toastr.showError("Please select the user to InActivate");
    }
  }

  createUser() {
    if (this.addUserValidations()) {
      this.addUser.firstName = this.txtUMMLFName.trim();
      this.addUser.lastName = this.txtUMMLLName.trim();
      this.addUser.displayName = this.txtUMMLDName.trim();
      this.addUser.emailId = this.txtUMMLEmailId.trim();
      this.addUser.role = this.userRoleMLSelectedValue;
      this.addUser.status = "Active";

      this.apiService
        .AddUser(this.addUser)
        .subscribe((data: any) => {
          if (data === true) {
            this.toastr.showSuccess("Successfully created user !!");
            this.modalClose("modal close");
            this.resetFilters();
          }
        });
    }
  }

  onKeyEnter() {
    this.getGridDataByFilterSearch();    
  }

  addUserValidations(): boolean {
    if (this.txtUMMLFName === null || this.txtUMMLFName === undefined || this.txtUMMLFName.trim() === "")
      this.lblErrMLFName = "Enter First Name"
    else
      this.lblErrMLFName = "";

    if (this.txtUMMLLName === null || this.txtUMMLLName === undefined || this.txtUMMLLName.trim() === "")
      this.lblErrMLLName = "Enter Last Name"
    else
      this.lblErrMLLName = "";

    if (this.txtUMMLDName === null || this.txtUMMLDName === undefined || this.txtUMMLDName.trim() === "")
      this.lblErrMLDName = "Enter Display Name"
    else
      this.lblErrMLDName = "";

    if (this.txtUMMLEmailId === null || this.txtUMMLEmailId === undefined || this.txtUMMLEmailId.trim() === "")
      this.lblErrMLEmail = "Enter Email Id"
    else
      this.lblErrMLEmail = "";

    if (this.userRoleMLSelectedId === 0)
      this.lblErrMLRole = "Select Role"
    else
      this.lblErrMLRole = "";

    if (this.lblErrMLFName.trim() === "" && this.lblErrMLLName.trim() === "" && this.lblErrMLDName.trim() === "" && this.lblErrMLEmail.trim() === "" && this.lblErrMLRole.trim() === "")
      return true;
    else
      return false;
  }
}
