import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  returnUrl: string = "";
  constructor(private router: Router) {
  }
  ngOnInit(): void {
    this.returnUrl = sessionStorage.getItem("returnUrl");
    if(this.returnUrl != undefined && this.returnUrl != null) {
     this.router.navigateByUrl(this.returnUrl);
    }
  }
}

