import { GridOptions } from '@ag-grid-community/all-modules';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { CPIBFirmware } from '../common/models/CPIBFirmware';
import { CPIBRcnInfo } from '../common/models/CPIBRcnInfo';
import { Commission } from '../common/models/commission';
import { TwilioPhoneNumber } from '../common/models/twilioPhoneNumber';
import { FCToasterService } from '../common/toaster/FCToasterService';
import { Service } from '../service';
import { OtisLinePhonenumber } from '../common/models/otislinephonenumber';
import { unitSearchService } from '../common/unitSearchService';
import { CPIBTestStationPhNumber } from '../common/models/CPIBTestStationPhNumber';

@Component({
  selector: 'app-commissioning',
  templateUrl: './commissioning.component.html',
  styleUrls: ['./commissioning.component.scss']
})

export class CommissioningComponent implements OnInit {

  closeResult: string;
  @ViewChild("agGridCommission", { static: false }) agGridCommission: AgGridAngular;

  rowData: any[];
  columnDefs: any[];
  productTypes: any[];
  twilioPhoneNumbers: any[];
  gridOptions: GridOptions;
  txtCOMMlCPIBHwId: any;
  txtCOMSrchUnit: any;
  txtCOMGWMacAdd: any;
  isRowSelected: boolean = false;
  isCommissionDisabled: boolean = false;
  rowSelectedData: {};
  productTypeSelectedId: number;
  txtCOMMLUnitNum: any;
  txtUnitNumNDeck: any;
  txtCOMMLGwMacAdd: any;
  txtCOMMlManfr: any;
  txtCOMMlIccId: any;
  txtCOMMlUuid: any;
  txtPhoneNumber: any;
  txtAreaOrRegion: any;
  lblErrorAreaCode = "";
  lblErrorPhoneNum = "";
  txtSid: any;
  txtCOMMLCountryName: any;
  selectedFileType: any = {};
  srchUnitNumber: any;
  commission = {} as Commission;
  lblErrorCOMMLCPIBHwId = ""
  selectedUnits = [];
  releaseBtnDisable: boolean = false;
  purchaseBtnDisable: boolean = true;
  twilioPhoneNumber = {} as TwilioPhoneNumber;
  twilioReleasePhNum = {} as TwilioPhoneNumber;
  txtCPIBFirmwareVersion: any;
  lblErrorCPIBFWVersionAdd = "";
  CPIBFirmware: any[];
  GWFMVersion: any;
  txtCOMMVersion: string;
  txtSuggestedVersion: string;
  addCPIBFirmware = {} as CPIBFirmware;
  isBtnGetFirmware: any;
  lblVersionMessage: any;
  isAdmin: boolean = false;
  lblErrorCOMMLCPIBVersion = "";
  objCPIBRcnInfo: CPIBRcnInfo;
  currentRcn: string;
  isSavedUpdated: boolean = false;
  lblWriteConfigMessage: any;
  writeConfigStyle: any;
  writeStatusTimer: any;
  tempCounter: number;
  searchTypes: Array<{ id: number, name: string }>;
  searchTypeSelectedId: number;
  searchTypeSelectedValue: string;
  lblErrorSearchType = "";
  defaultSearchType: any = {};
  txtOtisLinePhone1: any;
  lblErrorOtisLinePhone1 = "";
  txtOtisLineCallbackNum: any;
  lblErrorOtisLineCallbackNum = "";
  otisLinePhNum = {} as OtisLinePhonenumber;
  CCTypes: any[];
  CCTypeSelectedId: number = 1;
  chkDNCOL: boolean;
  txteViewOPN: string = "";
  txtOLPhone1: string = "";
  txtOLCallbackNumber: string = "";
  txtePALUUID: string = "";
  alarmStatusStyle: boolean;
  lblAlarmStatusMessage: any;
  claims: any;
  txtTestStationPhoneNumber: string = "";
  txtPTestStationNum: string = "";
  lblErrorTestStationNum: string = "";
  testStationPhNum = {} as CPIBTestStationPhNumber;
  lblTwilioErrorMessage: string = "";
  tempAreaCode: string = "";
  txtElevatorDeviceId: number = 0;

  constructor(private apiService: Service, private modalService: NgbModal, private toastr: FCToasterService,
    private oauthService: OAuthService, private unitSearch: unitSearchService) { }
  ngOnInit(): void {
    this.bindDefaultValuesOnLoad();
    this.bindMasterDataFilters();
    this.bindGridOnPageLoad();
    this.getTestStationOtisLinePhonenumbers()

    this.gridOptions = {
      defaultColDef: { filter: true },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true,
      rowClassRules: {
        "row-dd": params => params.data["deckType"] != null,
        "row-sd": params => params.data["deckType"] == null
      },
    }

    this.claims = this.oauthService.getIdentityClaims();
    if (this.claims !== null && this.claims !== undefined) {
      this.apiService
        .getUserDetails(this.claims["oid"])
        .subscribe((data: any) => {
          if (data != null) {
            this.isAdmin = this.apiService.isAdmin();
          }
        }, (error) => {
        });
    }
  }

  bindVVConfigDefaultValues() {
    this.CCTypes = [
      { id: "1", name: "Disabled" },
      { id: "3", name: "AP Calling Enabled" }
    ];
    this.chkDNCOL = false;
    if (this.otisLinePhNum != undefined) {
      this.txtOLPhone1 = this.otisLinePhNum.otisLinePhone1;
      this.txtOLCallbackNumber = this.otisLinePhNum.otisLineCallbackNumber;
    }
  }

  //Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "", field: "cbox", checkboxSelection: true, width: 70 },
      { field: "elevatorUnitNumber", hide: "true" },
      { headerName: "Unit Number", field: "elevatorUnitNumberDeckType", sortable: true, width: 190, tooltipField: "elevatorUnitNumberDeckType", headerTooltip: "Unit Number" },
      { field: "deckType", hide: "true" },
      { headerName: "Elevator Type", field: "elevatorType", sortable: true, width: 190, tooltipField: "elevatorType", headerTooltip: "Elevator type" },
      { headerName: "Building Name", field: "buildingName", sortable: true, width: 200, tooltipField: "buildingName", headerTooltip: "Building Name" },
      { headerName: "CPIB Phone Number", field: "phoneNumber", sortable: true, width: 250, tooltipField: "phoneNumber", headerTooltip: "CPIB Phone Number" },
      { headerName: "Building Address", field: "buildingAddress", sortable: true, width: 300, resizable: true, tooltipField: "buildingAddress", headerTooltip: "Building Address" },
      { headerName: "CARLET", field: "carlet", sortable: true, width: 250, resizable: true, tooltipField: "CARLET", headerTooltip: "CARLET" },
      { headerName: "Gateway Mac Address", field: "gatewayMacAddress", sortable: true, width: 250, tooltipField: "gatewayMacAddress", headerTooltip: "Gateway Mac Address" },
      { headerName: "CPIB2 MAC Address", field: "cpibHwid", sortable: true, width: 220, tooltipField: "cpibHwid", headerTooltip: "CPIB2 MAC Address" },
      { headerName: "SIM Card ICCID", field: "gatewayIccId", sortable: true, width: 250, tooltipField: "gatewayIccId", headerTooltip: "SIM Card ICCID" },
      { headerName: "SIM Card ICCID2", field: "gatewayIccId2", sortable: true, width: 250, tooltipField: "gatewayIccId2", headerTooltip: "SIM Card ICCID2" },
      { headerName: "Manufacturer", field: "manufacturer", sortable: true, width: 200, tooltipField: "manufacturer", headerTooltip: "Manufacturer" },
      { headerName: "CP-ID/UUID", field: "cpidUuid", sortable: true, width: 250, resizable: true, tooltipField: "cpidUuid", headerTooltip: "CP-ID/UUID" }
    ];
  }

  //Bind master data in filters on page load
  bindMasterDataFilters() {
    this.getProductTypes();
    this.getCPIBFirmwares();
    this.getSearchTypes();
    this.getOtisLinePhonenumbers();
  }

  //Get Product Types Master Data
  getProductTypes() {
    this.apiService
      .getProductTypes()
      .subscribe((data: any) => {
        if (data != null) {
          this.productTypes = data;
        }
      });
  }

  //Get Otisline Phonenumbers
  getOtisLinePhonenumbers() {
    this.apiService
      .getOtisLinePhonenumbers()
      .subscribe((data: any) => {
        if (data != null && data.length > 0) {
          this.otisLinePhNum = data[0];
        }
        this.bindVVConfigDefaultValues();
      });
  }

  //Get Search Types Master Data for Twilio Phonumber Search
  getSearchTypes() {
    this.searchTypes = [
      { id: 0, name: "Region" },
      { id: 1, name: "Area Code" }
    ];
  }

  // Assign default values to variables on Page load
  bindDefaultValuesOnLoad() {
    this.txtCOMMlCPIBHwId = "";
    this.txtCOMGWMacAdd = "";
    this.txtCOMSrchUnit = "";
    this.txtCOMMLUnitNum = "";
    this.txtUnitNumNDeck = "";
    this.txtElevatorDeviceId = 0;
    this.txtCOMMLCountryName = "";
    this.txtSid = "";
    this.lblErrorAreaCode = "";
    this.lblErrorPhoneNum = "";
    this.txtPhoneNumber = "";
    this.txtCOMMLGwMacAdd = "";
    this.txtCOMMlManfr = "";
    this.txtCOMMlIccId = "";
    this.txtCOMMlUuid = "";
    this.rowSelectedData = {};
    this.isRowSelected = false;
    this.lblErrorCOMMLCPIBHwId = "";
    this.selectedFileType = { id: 0, name: "--- Select File Type ---" };
    this.rowData = [];
    this.selectedUnits = [];
    this.commission.cpidUuid = "";
    this.commission.cpibMacAddress = "";
    this.commission.elevatorDeviceId = 0;
    this.productTypeSelectedId = 3;
    this.isCommissionDisabled = false;
    this.releaseBtnDisable = false;
    this.purchaseBtnDisable = true;
    this.lblErrorCOMMLCPIBVersion = "";
    this.isBtnGetFirmware = false;
    this.lblWriteConfigMessage = "";
    this.tempCounter = 0;
    this.searchTypes = [];
    this.defaultSearchType = { id: 0, name: "Region" };
    this.searchTypeSelectedId = this.defaultSearchType.id;
    this.searchTypeSelectedValue = this.defaultSearchType.name;
    this.CCTypeSelectedId = 1;
    this.chkDNCOL = false;
    this.txteViewOPN = "";
    this.txtOLPhone1 = "";
    this.txtOLCallbackNumber = "";
    this.txtePALUUID = "";
    this.lblAlarmStatusMessage = "";
    this.lblTwilioErrorMessage = "";
  }

  //Get the data from API for selected filters
  getGridDataByFilterSearch() {
    this.srchUnitNumber = (this.txtCOMSrchUnit !== null && this.txtCOMSrchUnit !== undefined) ? this.txtCOMSrchUnit.trim() : "";

    if (this.srchUnitNumber !== "" && this.srchUnitNumber !== null) {
      this.srchUnitNumber = this.unitSearch.getUpdatedUnitSearch(this.srchUnitNumber);
      this.selectedUnits = [];
      this.apiService
        .getCommissionUnitsByFilterSearch(this.srchUnitNumber)
        .subscribe((data: any) => {
          if (data != null) {
            this.rowData = data;
          }
          else {
            this.rowData = [];
          }
        });
    }
    else {
      this.toastr.showError("Please enter the Unit Number or Building Name or Address");
    }
  }

  //Reset Filters
  resetFilters($event) {
    this.bindDefaultValuesOnLoad();
    this.bindGridOnPageLoad();
    this.getSearchTypes();
    this.getOtisLinePhonenumbers();
  }

  // Commission Modal Popup
  open(content) {
    if (content._declarationTContainer.localNames[0] == 'commModal') {
      this.modalService.open(content, { windowClass: "myCustomCommModalClass", ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      if (this.isCommissionDisabled) {
        this.displayCPIBStatusAndStyles();
        this.getVVConfigDetails();
      }      
    }
    else {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });

      if (content._declarationTContainer.localNames[0] == 'phnModal') {
        this.getUnitInfo();
        if (this.purchaseBtnDisable === true) {
          if (this.tempAreaCode != "") { this.searchTypeSelectedId = 1; }
        }
      }
    }
  }

  // Close Modal Popup
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //Grid row selection event
  onRowSelected(event) {
    if (event.node.selected) {
      this.selectedUnits.push(event.node.data);
    }
    else {
      var index = this.selectedUnits.indexOf(event.node.data);
      this.selectedUnits.splice(index, 1);
    }

    if (this.selectedUnits.length === 1) {
      this.isRowSelected = true;
      this.rowSelectedData = this.selectedUnits[0];
      if (this.rowSelectedData["cpibHwid"] !== null && this.rowSelectedData["cpibHwid"] !== "") {
        this.isCommissionDisabled = true;
      }
      else {
        this.isCommissionDisabled = false;
      }
      this.CommModalLoad(this.rowSelectedData);
      this.twilioButtonsDisable();
    }
    else {
      this.isRowSelected = false;
      this.rowSelectedData = {};
    }
    this.productTypeSelectedId = this.rowSelectedData["productTypeId"] !== null ? this.rowSelectedData["productTypeId"] : 3;
  }

  twilioButtonsDisable() {
    if (this.txtPhoneNumber.length > 0) {
      this.releaseBtnDisable = false;
      this.purchaseBtnDisable = true;
    }
    else {
      this.releaseBtnDisable = true;
      this.purchaseBtnDisable = false;
    }
  }

  CommModalLoad(rowSelectedData) {
    this.txtCOMMLUnitNum = (rowSelectedData["elevatorUnitNumber"] !== null && rowSelectedData["elevatorUnitNumber"] !== undefined) ? rowSelectedData["elevatorUnitNumber"] : "";
    this.txtUnitNumNDeck = (rowSelectedData["elevatorUnitNumberDeckType"] !== null && rowSelectedData["elevatorUnitNumberDeckType"] !== undefined) ? rowSelectedData["elevatorUnitNumberDeckType"] : "";
    this.txtElevatorDeviceId = (rowSelectedData["elevatorDeviceId"] !== null && rowSelectedData["elevatorDeviceId"] !== undefined) ? rowSelectedData["elevatorDeviceId"] : "";
    this.txtCOMMLGwMacAdd = (rowSelectedData["gatewayMacAddress"] !== null && rowSelectedData["gatewayMacAddress"] !== undefined) ? rowSelectedData["gatewayMacAddress"] : "";
    this.txtCOMMlManfr = (rowSelectedData["manufacturer"] !== null && rowSelectedData["manufacturer"] !== undefined) ? rowSelectedData["manufacturer"] : "";
    this.txtCOMMlIccId = (rowSelectedData["gatewayIccId"] !== null && rowSelectedData["gatewayIccId"] !== undefined) ? rowSelectedData["gatewayIccId"] : "";
    this.txtCOMMlUuid = (rowSelectedData["cpidUuid"] !== null && rowSelectedData["cpidUuid"] !== undefined) ? rowSelectedData["cpidUuid"] : "";
    this.txtCOMMlCPIBHwId = (this.rowSelectedData["cpibHwid"] !== null && this.rowSelectedData["cpibHwid"] !== "") ? this.rowSelectedData["cpibHwid"] : "00D085";
    this.txtCOMMLCountryName = (this.rowSelectedData["countryName"] !== null && this.rowSelectedData["countryName"] !== "") ? this.rowSelectedData["countryName"] : "US";
    this.txtPhoneNumber = (this.rowSelectedData["phoneNumber"] !== null && this.rowSelectedData["phoneNumber"] !== "") ? this.rowSelectedData["phoneNumber"] : "";
    this.txtSid = (this.rowSelectedData["sid"] !== null && this.rowSelectedData["sid"] !== "") ? this.rowSelectedData["sid"] : "";
    this.txtAreaOrRegion = this.tempAreaCode = (this.rowSelectedData["areaCode"] !== null && this.rowSelectedData["areaCode"] !== "") ? this.rowSelectedData["areaCode"] : "";
  }

  // Close Modal Popup
  modalClose(content) {
    this.modalService.dismissAll();
    this.lblErrorCOMMLCPIBHwId = "";
    this.lblErrorCOMMLCPIBVersion = "";
    this.lblErrorAreaCode = "";
    this.lblErrorPhoneNum = "";
    this.txteViewOPN = "";
    if (!this.isCommissionDisabled) {
      this.txtCOMMlCPIBHwId = "00D085";
    }
    if (this.purchaseBtnDisable === false) {
      this.txtAreaOrRegion = "";
      this.txtPhoneNumber = "";
    }
    
    this.gridReload();
    this.lblVersionMessage = "";
    this.txtSuggestedVersion = "";
    this.isSavedUpdated = false;
    this.lblWriteConfigMessage = "";
    this.lblAlarmStatusMessage = "";
    this.lblTwilioErrorMessage = "";
    this.tempCounter = 0;
    this.chkDNCOL = false;
    this.CCTypeSelectedId = 1;
    this.txtePALUUID = "";
    if (this.writeStatusTimer != undefined) { this.writeStatusTimer.unsubscribe(); }
    this.txtPTestStationNum = "";
    this.lblErrorTestStationNum = "";
    this.searchTypeSelectedId = this.defaultSearchType.id;
  }

  onSearchKeyEnter() {
    this.getGridDataByFilterSearch();
  }

  onPhoneSearchKeyEnter() {
    this.onSearchPhoneNumber(this.txtAreaOrRegion);
  }

  //Commission Button event in popupModal
  onStartCommission(event) {
    if (this.txtCOMMlCPIBHwId === null || this.txtCOMMlCPIBHwId === undefined || this.txtCOMMlCPIBHwId.trim() === "") {
      this.lblErrorCOMMLCPIBHwId = "Enter CPIB2 MAC Address"
    }
    else if (this.txtCOMMVersion === null || this.txtCOMMVersion === undefined || this.txtCOMMVersion.trim() === "") {
      this.lblErrorCOMMLCPIBVersion = "Please contact admin to add firmware version."
    }
    else {
      this.lblErrorCOMMLCPIBHwId = "";
      this.lblErrorCOMMLCPIBVersion = "";
      this.commission.cpidUuid = this.txtCOMMlUuid;
      this.commission.cpibMacAddress = this.txtCOMMlCPIBHwId.trim();
      this.commission.ePALUUID = this.txtePALUUID;
      this.commission.elevatorUnitNumber = this.txtCOMMLUnitNum;
      this.commission.otislinePhone1 = this.txtTestStationPhoneNumber;
      this.commission.deckType = this.rowSelectedData["deckType"];
      this.commission.elevatorDeviceId = this.txtElevatorDeviceId;
      this.apiService
        .startCPIBTest(this.commission)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Successfully Commissioned !!");
            this.isCommissionDisabled = true;
            this.isSavedUpdated = true;
            setTimeout(() => {
              this.displayCPIBStatusAndStyles();
            }, 10000);
            if(this.commission.deckType != "UD")
            {
              this.getUnitAreaCode();
            }
          }
          else {
            this.toastr.showError("Commission Failed !!");
          }
        });
    }
  }
  getUnitAreaCode() {
    if (this.txtAreaOrRegion == "" && this.txteViewOPN == "") {
      this.apiService
        .getUnitInfo(this.txtCOMMLUnitNum)
        .subscribe((data: any) => {
          if (data != null) {
            this.txtAreaOrRegion = data.city + ' ' + data.stateShortCode;
            if (this.txtAreaOrRegion != "") {
              this.apiService
                .getSearchTwilioPhoneNumber("", this.txtCOMMLCountryName, this.txtAreaOrRegion, this.txtCOMMLUnitNum)
                .subscribe((data: any) => {
                  if (data != null) {
                    this.txteViewOPN = data.phoneNumber;
                  }
                });
            }
          }
          else {
            this.txtAreaOrRegion = "";
          }
        });
    }
  }

  onEndCPIBTest(event) {
    if (this.txtCOMMlCPIBHwId === null || this.txtCOMMlCPIBHwId === undefined || this.txtCOMMlCPIBHwId.trim() === "") {
      this.lblErrorCOMMLCPIBHwId = "Enter CPIB2 MacAddress"
    }
    else {
      this.lblErrorCOMMLCPIBHwId = "";
      this.lblErrorCOMMLCPIBVersion = "";
      this.commission.cpidUuid = this.txtCOMMlUuid;
      this.commission.cpibMacAddress = this.txtCOMMlCPIBHwId.trim();
      this.commission.ePALUUID = this.txtePALUUID;
      this.commission.elevatorUnitNumber = this.txtCOMMLUnitNum;
      this.commission.elevatorDeviceId = this.txtElevatorDeviceId;
      this.commission.deckType = this.rowSelectedData["deckType"];
      this.commission.customerCallType = this.CCTypeSelectedId.toString();
      if(this.commission.deckType != "UD")
      {
        this.commission.eViewOtisPhoneNumber = this.txteViewOPN !== null ?  this.txteViewOPN.trim() : this.txteViewOPN;
      }
      this.commission.otislineCallMethod = this.chkDNCOL ? "5" : "3";
      this.commission.otislinePhone1 = this.txtOLPhone1;
      this.commission.otislineCallbackNumber = this.txtOLCallbackNumber;
      this.commission.buildingAddress = (this.rowSelectedData["buildingAddress"] !== null && this.rowSelectedData["buildingAddress"] !== "") ? this.rowSelectedData["buildingAddress"] : "";
      this.apiService
        .endCPIBTest(this.commission)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("End CPIB2 Test is Successful !! Updated Voice & Video Configurations in G3MS!!");
            this.isCommissionDisabled = true;
            this.isSavedUpdated = true;
            if(this.commission.deckType != "UD")
            {
              this.getTwilioPhNumStatus();
            }
          }
          else {
            this.toastr.showError("End Test Failed !!");
          }
        });
    }
  }

  getVVConfigDetails() {
    this.apiService
      .getunitvvconfiguration(this.txtCOMMLUnitNum, this.txtElevatorDeviceId)
      .subscribe((vvConfig: Commission) => {
        if (vvConfig != null) {
          this.CCTypeSelectedId = Number(vvConfig.customerCallType);
          this.txteViewOPN = vvConfig.eViewOtisPhoneNumber;
          this.chkDNCOL = vvConfig.otislineCallMethod == "5" ? true : false;
        }
      });
  }

  OnDecommissioinCPIB() {
    if (this.rowSelectedData["cpibHwid"] !== "" && this.rowSelectedData["cpibHwid"] !== null && this.rowSelectedData["cpibHwid"] !== undefined) {
      this.commission.cpidUuid = this.rowSelectedData["cpidUuid"];
      this.commission.cpibMacAddress = this.rowSelectedData["cpibHwid"];
      this.commission.elevatorUnitNumber = this.rowSelectedData["elevatorUnitNumber"];
      this.commission.elevatorDeviceId = this.txtElevatorDeviceId;
      this.commission.deckType = this.rowSelectedData["deckType"];
      this.commission.eViewOtisPhoneNumber = this.rowSelectedData["phoneNumber"];
      this.apiService
        .deCommissioning(this.commission)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Successfully Decommissioned !!");
            this.isSavedUpdated = true;
            this.modalClose("modal close");
            this.gridReload();
          }
          else {
            this.toastr.showError("Decommission Failed !!");
          }
        });
    }
    else {
      this.toastr.showError("Please select the CPIB Commissioned Unit !!");
    }
  }

  gridReload() {
    if (this.isSavedUpdated == true) {
      this.bindGridOnPageLoad();
      this.getGridDataByFilterSearch();
      this.isRowSelected = false;
    }
    this.isSavedUpdated = false;
  }

  productTypeSelectChanged($event) {
    if ($event !== null && $event !== undefined) {
      this.productTypeSelectedId = $event.target.value;
    }
  }

  onSearchPhoneNumber(txtAreaOrRegion) {
    this.lblErrorPhoneNum = "";
    this.lblErrorAreaCode = "";
    this.lblErrorSearchType = "";

    if (this.searchTypeSelectedId === 1) {
      if ((txtAreaOrRegion === null || txtAreaOrRegion === undefined || txtAreaOrRegion.trim() == "")) {
        this.lblErrorAreaCode = "Please Enter Area Code";
      }
      else {
        var patt = new RegExp(/^-?([0-9]\d*)?$/);
        var res = patt.test(txtAreaOrRegion);
        if ((res === false && this.searchTypeSelectedId === 1) || txtAreaOrRegion.trim().length === 0) {
          this.lblErrorAreaCode = "Please Enter Area Code In Number Format";
          return false;
        }
        else {
          this.apiService
            .getSearchTwilioPhoneNumber(txtAreaOrRegion, this.txtCOMMLCountryName, "", "")
            .subscribe((data: any) => {
              if (data != null) {
                this.txtPhoneNumber = data.phoneNumber;
              }
              else {
                this.txtPhoneNumber = "";
                this.lblErrorPhoneNum = "Twilio Phone Number is not available for requested Area Code " + txtAreaOrRegion;
              }
            });
        }
      }
    }
    else if (this.searchTypeSelectedId === this.defaultSearchType.id) {
      if ((this.txtAreaOrRegion === null || this.txtAreaOrRegion === undefined || this.txtAreaOrRegion.trim() == "")) {
        this.lblErrorAreaCode = "Please Enter Region";
      }
      else {
        this.apiService
          .getSearchTwilioPhoneNumber("", this.txtCOMMLCountryName, txtAreaOrRegion, this.txtCOMMLUnitNum)
          .subscribe((data: any) => {
            if (data != null) {
              this.txtPhoneNumber = data.phoneNumber;
            }
            else {
              this.txtPhoneNumber = "";
              this.lblErrorPhoneNum = "Twilio Phone Number is not available for requested Region " + txtAreaOrRegion;
            }
          });
      }
    }
  }

  onPurchaseTwilioPhoneNumber(event) {
    if ((this.txtAreaOrRegion === null || this.txtAreaOrRegion === undefined || this.txtAreaOrRegion.trim() == "")) {
      if (this.searchTypeSelectedId === 1) { this.lblErrorAreaCode = "Please Enter Area Code"; return; }
      else { this.lblErrorAreaCode = "Please Enter Region"; return; }
    }
    this.twilioPhoneNumber.countryCode = this.txtCOMMLCountryName;
    this.twilioPhoneNumber.phoneNumber = this.txtPhoneNumber;
    this.twilioPhoneNumber.elevatorUnitNumber = this.txtCOMMLUnitNum;
    this.twilioPhoneNumber.elevatorDeviceId = this.txtElevatorDeviceId;
    this.twilioPhoneNumber.deckType = this.rowSelectedData["deckType"];
    if (this.searchTypeSelectedId === 0) {
      this.twilioPhoneNumber.region = this.txtAreaOrRegion;
      this.twilioPhoneNumber.areaCode = "";
    }
    else {
      this.twilioPhoneNumber.region = "";
      this.twilioPhoneNumber.areaCode = this.txtAreaOrRegion;
    }

    this.lblErrorPhoneNum = "";
    if (this.txtPhoneNumber === "" || this.txtPhoneNumber === null) {
      this.lblErrorPhoneNum = "Twilio Phone Number is not available for requested Area Code " + this.txtAreaOrRegion;
    }
    else {
      this.apiService
        .PurchaseTwilioPhoneNumber(this.twilioPhoneNumber)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Purchased Twilio Phone Number Successfully!!");
            this.modalService.dismissAll();
            this.txtAreaOrRegion = "";
            this.isSavedUpdated = true;
            this.gridReload();
            this.searchTypeSelectedId = this.defaultSearchType.id;
          }
          else {
            this.toastr.showError("Twilio Phone Number Purchase Failed !!");
          }
        });
    }
  }

  onReleaseTwilioPhoneNumber(event) {
    this.twilioReleasePhNum.countryCode = this.txtCOMMLCountryName;
    this.twilioReleasePhNum.phoneNumber = this.txtPhoneNumber;
    this.twilioReleasePhNum.sid = this.txtSid;
    this.twilioReleasePhNum.elevatorUnitNumber = this.txtCOMMLUnitNum;
    this.twilioReleasePhNum.elevatorDeviceId = this.txtElevatorDeviceId;
    this.twilioReleasePhNum.deckType = this.rowSelectedData["deckType"];
    this.apiService
      .ReleaseTwilioPhoneNumber(this.twilioReleasePhNum)
      .subscribe((data: any) => {
        if (data == true) {
          this.toastr.showSuccess("Twilio Phone Number Released Successfully!!");
          this.modalService.dismissAll();
          this.txtAreaOrRegion = "";
          this.txtPhoneNumber = "";
          this.isSavedUpdated = true;
          this.gridReload();
          this.searchTypeSelectedId = this.defaultSearchType.id;
        }
        else {
          this.toastr.showError("Twilio Phone Number Release Failed !!");
        }
      });
  }

  onSaveCPIBFirmware() {
    if (this.txtCPIBFirmwareVersion !== undefined)
      this.txtCPIBFirmwareVersion = this.txtCPIBFirmwareVersion.replace(/^\s+|\s+$/g, "");
    else
      this.lblErrorCPIBFWVersionAdd = "Please provide New Firmware version";

    if (!!this.txtCPIBFirmwareVersion) {
      this.addCPIBFirmware.Name = "CPIB2";
      this.addCPIBFirmware.FirmwareVersion = this.txtCPIBFirmwareVersion;
      this.addCPIBFirmware.IsActive = true;
      this.apiService
        .addCPIBGatewayFirmware(this.addCPIBFirmware)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Firmware version saved successfully!!");
            this.getCPIBFirmwares();
            this.modalService.dismissAll();
            this.txtCPIBFirmwareVersion = "";
          }
          else {
            this.toastr.showError("Firmware version saved Failed !!");
          }
        });
    } else {
      this.lblErrorCPIBFWVersionAdd = "Please provide Firmware version";
    }
  }

  clearErrorMessages() {
    this.lblErrorCPIBFWVersionAdd = "";
  }

  getCPIBFirmwares() {
    this.apiService
      .getCPIBFirmware()
      .subscribe((data: any) => {
        this.CPIBFirmware = data;
        this.txtCOMMVersion = data[0].firmwareVersion;
      });
  }

  onGetRunningFirmwareAndtWriteConfigStatus(event) {
    this.displayCPIBStatusAndStyles();
  }

  displayCPIBStatusAndStyles() {
    this.lblWriteConfigMessage = "";
    this.getWriteConfigStatus();
    this.lblVersionMessage = "";
    this.getCPIBRunningFirmwareVersion();
    this.lblAlarmStatusMessage = "";
    this.lblTwilioErrorMessage = "";
    this.getAlarmStatus();
  }
  showMessages() {
    if (this.txtSuggestedVersion == "" || this.txtSuggestedVersion == null) {
      this.toastr.showInformation("Firmware version not reported to CPIB. Make sure device is online.");
    }
    else {
      this.toastr.showSuccess("Running Firmware Version from CPIB Updated successfully !!");
    }
  }

  getCPIBRunningFirmwareVersion() {
    if (this.txtCOMMlCPIBHwId.length > 0 && this.txtCOMMVersion.length > 0) {
      this.apiService
        .getCPIBRunningFirmware(this.txtCOMMlCPIBHwId)
        .subscribe((data: any) => {
          this.currentRcn = '';
          this.objCPIBRcnInfo = data;
          if (this.objCPIBRcnInfo != null && this.objCPIBRcnInfo.currentRCN.length > 0) {
            this.currentRcn = this.objCPIBRcnInfo.currentRCN;
            if (this.currentRcn.indexOf("/") > 0) { this.txtSuggestedVersion = this.currentRcn.split("/")[1]; }
            else { this.txtSuggestedVersion = this.currentRcn; }
          }
          if (this.currentRcn == '' || this.currentRcn == null) {
            setTimeout(() => {
              this.lblVersionMessage = "Firmware version not reported to CPIB. Make sure device is online. Click on Get CPIB2 Details to check device status.";
              this.isBtnGetFirmware = false;
            }, 5000);
            this.showMessages();
          }
          else if (this.txtSuggestedVersion == this.txtCOMMVersion) {
            this.lblVersionMessage = "This Firmware has compatible firmware listed in G3MS.";
            this.isBtnGetFirmware = true;
          }
          else {
            setTimeout(() => {
              this.lblVersionMessage = "Running Firmware Version from CP Dashboard & Otis ONE Compatible Firmware versions are different. Manually update firmware if needed. Click on Get CPIB2 Details to check CPIB2 reported version to CP Dashboard.";
              this.isBtnGetFirmware = false;
            }, 5000);
            this.showMessages();
          }
        });
    }
  }

  getAlarmStatus() {
    if (this.txtCOMMLUnitNum.length > 0) {
      this.apiService
        .getVVAlarmStatus(this.txtCOMMLUnitNum, this.txtCOMMLCountryName, this.txtElevatorDeviceId)
        .subscribe((data: string) => {
          if (data.length > 0 && data != "") {
            let replaceFail = /\Fail/gi; let replaceOk = /\Pass/gi;
            this.lblAlarmStatusMessage = data.replace(replaceFail, '<b>Fail</b>').replace(replaceOk, '<b>Pass</b>');
            this.alarmStatusStyle = (this.lblAlarmStatusMessage.indexOf('Fail') > 0 ? false : true);
          }
        });
    }
  }
  getWriteConfigStatus() {
    if (this.txtCOMMLUnitNum.length > 0) {
      this.apiService
        .getCPIBWriteConfigStatus(this.txtCOMMLUnitNum, this.rowSelectedData["deckType"])
        .subscribe((data: any) => {
          if (data.length > 0 && data != "") {
            this.lblWriteConfigMessage = data;
            this.writeConfigStyle = (data.toUpperCase() == 'PASSED' ? true : false);
          }
          if (this.writeStatusTimer != undefined && (this.writeConfigStyle || this.tempCounter > 5)) {
            this.writeStatusTimer.unsubscribe();
          }
        });
    }
  }

  searchTypeSelectChanged($event) {
    this.searchTypeSelectedId = $event.target.selectedIndex;
    this.searchTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
    this.txtAreaOrRegion = "";
    this.lblErrorAreaCode = "";
    this.txtPhoneNumber = "";
    this.lblErrorPhoneNum = "";
    if (this.searchTypeSelectedValue.toUpperCase() == "REGION") {
      this.getUnitInfo()
    }
  }

  getUnitInfo() {
    if (this.txtAreaOrRegion == "") {
      this.apiService
        .getUnitInfo(this.txtCOMMLUnitNum)
        .subscribe((data: any) => {
          if (data != null) {
            this.txtAreaOrRegion = data.city + ' ' + data.stateShortCode;
            this.txtCOMMLCountryName = data.countryCode;
          }
          else {
            this.txtAreaOrRegion = "";
            this.toastr.showError("Unit information doesn't exist in FC backend.")
          }
        });
    }
  }

  onSaveOtisLinePhNumbers() {
    if (this.txtOtisLinePhone1 !== undefined && this.txtOtisLinePhone1.trim() !== "") {
      this.txtOtisLinePhone1 = this.txtOtisLinePhone1.replace(/^\s+|\s+$/g, "");
      this.lblErrorOtisLinePhone1 = "";
    }
    else
      this.lblErrorOtisLinePhone1 = "Please provide OtisLine Phone1";

    if (this.txtOtisLineCallbackNum !== undefined && this.txtOtisLineCallbackNum.trim() !== "") {
      this.txtOtisLineCallbackNum = this.txtOtisLineCallbackNum.replace(/^\s+|\s+$/g, "");
      this.lblErrorOtisLineCallbackNum = "";
    }
    else
      this.lblErrorOtisLineCallbackNum = "Please provide OtisLine CallbackNumber";

    if (!!this.txtOtisLinePhone1 && !!this.txtOtisLineCallbackNum) {
      this.otisLinePhNum.otisLinePhone1 = this.txtOtisLinePhone1
      this.otisLinePhNum.otisLineCallbackNumber = this.txtOtisLineCallbackNum;
      this.otisLinePhNum.IsActive = true;

      this.apiService
        .saveOtisLinePhonenumbers(this.otisLinePhNum)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("OtisLine Phonenumbers saved successfully!!");
            this.getOtisLinePhonenumbers();
            this.ClearOtisPhNumModal();
          }
          else {
            this.toastr.showError("OtisLine Phonenumbers saved Failed !!");
          }
        });
    }
  }

  ClearOtisPhNumModal() {
    this.txtOtisLinePhone1 = "";
    this.txtOtisLineCallbackNum = "";
    this.lblErrorOtisLinePhone1 = "";
    this.lblErrorOtisLineCallbackNum = "";
    this.modalService.dismissAll();
  }

  ClearTestStationOtisPhNumModal() {
    this.txtPTestStationNum = "";
    this.lblErrorTestStationNum = "";
    this.modalService.dismissAll();
  }
  onDeleteTestStationPhoneNum() {
    if (confirm("Are you sure want to to Delete Test Station OtisLine Phone Number: " + this.txtTestStationPhoneNumber)) {
      {
        this.testStationPhNum.testStationPhoneNumber = "";
        this.apiService
          .deleteTestStationOtisLinePh()
          .subscribe((data: any) => {
            if (data == true) {
              this.toastr.showSuccess("Successfully deleted Test Station Phone Number!!");
              this.getTestStationOtisLinePhonenumbers();
              this.ClearTestStationOtisPhNumModal();
            }
            else {
              this.toastr.showError("Failed to delete Test Station Phone Number!!");
            }
          });
      }
    }
  }

  getTestStationOtisLinePhonenumbers() {
    this.apiService
      .getTestStationOtisLinePh()
      .subscribe((testStationPhnum: CPIBTestStationPhNumber) => {
        if (testStationPhnum != null && testStationPhnum.testStationPhoneNumber != null) {
          this.txtTestStationPhoneNumber = testStationPhnum.testStationPhoneNumber;
        }
        else {
          this.txtTestStationPhoneNumber = "";
        }
      });
  }

  onSaveTestStationNumber() {
    if (this.txtPTestStationNum !== undefined && this.txtPTestStationNum.trim() !== "") {
      this.lblErrorTestStationNum = "";
    }
    else
      this.lblErrorTestStationNum = "Please provide Test Station OtisLine Number";

    if (this.txtPTestStationNum !== undefined && this.txtPTestStationNum.trim() !== "") {
      this.testStationPhNum.testStationPhoneNumber = this.txtPTestStationNum

      this.apiService
        .saveTestStationOtisLinePh(this.testStationPhNum)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Test Station Phone Number saved successfully!!");
            this.getTestStationOtisLinePhonenumbers();
            this.ClearTestStationOtisPhNumModal();
          }
          else {
            this.toastr.showError("Failed to save Test Station Phone Number!!");
          }
        });
    }
  }
  getTwilioPhNumStatus() {
    if (this.txtCOMMLUnitNum.length > 0) {
      this.apiService
        .getCommissionUnitsByFilterSearch(this.txtCOMMLUnitNum)
        .subscribe((data: any) => {
          if (data != null && (data[0].phoneNumber == null || data[0].phoneNumber == '')) {
            this.lblTwilioErrorMessage = "Twilio Phone Number is unavailable for the imported XML or Excel files City/State.  Please manually purchase it using a large nearby City/State and report the issue to FC Team.";
          }
          else if (data != null && data[0].phoneNumber != null) {
            this.txteViewOPN = data[0].phoneNumber;
          }
        });
    }
  }
}