import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GatewayComponent } from './gateway/gateway.component';
import { CommissioningComponent } from './commissioning/commissioning.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { HomeComponent } from './home/home.component'
import { UploadsidebarComponent } from './common/uploadsidebar/uploadsidebar.component';
import { AgGridModule } from 'ag-grid-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProvisioningComponent } from './provisioning/provisioning.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Service } from './service';
import { NgbStringAdapter } from './common/convertors/ngbstringadapter';
import { ToastrModule } from 'ngx-toastr';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { OpenlicenseComponent } from './openlicense/openlicense.component';
import { AppConfigService } from './services/app-config.service';
import { G3msunitsearchComponent } from './g3msunitsearch/g3msunitsearch.component';
import { FCToasterService } from './common/toaster/FCToasterService';
import { InterceptorService } from './services/interceptorservice';
import { FCError } from './common/models/fcerror';
import { EpalcommissionComponent } from './epalcommission/epalcommission.component';
import { BiprovisionComponent } from './biprovision/biprovision.component';
import { ReportsComponent } from './reports/reports.component';
import { EdiordersComponent } from './ediorders/ediorders.component';
import { LoggingService } from './services/logging.service';
import { unitSearchService } from './common/unitSearchService';
import { UnithistoryComponent } from './unithistory/unithistory.component';
import { NumberDirective } from './common/numberDirective';
import { DraggableModalComponent } from './common/popupmodel/draggable-modal.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    AppComponent,
    GatewayComponent,
    CommissioningComponent,
    UsermanagementComponent,
    HomeComponent,
    UploadsidebarComponent,
    ProvisioningComponent,
    OpenlicenseComponent,
    G3msunitsearchComponent,
    EpalcommissionComponent,
    BiprovisionComponent,
    ReportsComponent,
    EdiordersComponent,
    UnithistoryComponent,
    NumberDirective,
    DraggableModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    BrowserAnimationsModule,    
    AgGridModule.withComponents([]),
    ToastrModule.forRoot(
    ),
    OAuthModule.forRoot({
      resourceServer:
      {
        sendAccessToken: true
      }
    }),
    ToastrModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    BrowserModule,
    MatTabsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: InterceptorService
    },
    Service,
    NgbStringAdapter,
    FCToasterService,
    FCError,
    LoggingService,
    unitSearchService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
