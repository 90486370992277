<div id="usermanagement-main-wraper">
  <div class="col-12 page-heading p-0">
    <h5>User Management</h5>
  </div>
  <div class="col-12 search-ft p-0 mt-3">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label name="lblUMFName" class="control-label">First Name</label>
          <input type="text" class="form-control" name="txtUMFName" [(ngModel)]="txtUMFName" maxlength="20" (keydown.enter)="onKeyEnter()" />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblUMLName" class="control-label">Last Name</label>
          <input type="text" class="form-control" name="txtUMLName" [(ngModel)]="txtUMLName" maxlength="20" (keydown.enter)="onKeyEnter()"/>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <div class="form-group">
            <label name="lblUMEmail" class="control-label">Email Id</label>
            <input type="text" class="form-control" name="txtUMEmail" [(ngModel)]="txtUMEmail" maxlength="40" (keydown.enter)="onKeyEnter()"/>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblUMRole" class="control-label">Role</label>
          <select name="ddUMUserRole" class="form-control" [(ngModel)]="selectedUserRole.id"
            (change)="userRoleSelectChanged($event)">
            <option value="0" selected>--- Select Role ---</option>
            <option *ngFor="let userRole of userRoles" [ngValue]="userRole.id">
              {{ userRole.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblUMStatus" class="control-label">Status</label>
          <select name="ddUMUserStatus" class="form-control" [(ngModel)]="selectedUserStatus.id"
            (change)="userStatusSelectChanged($event)">
            <option value="-1" selected>--- Select Status ---</option>
            <option *ngFor="let userStatus of userStatus" [ngValue]="userStatus.id">
              {{ userStatus.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 d-inline-flex p-0">
        <div class="col d-flex align-items-start">
          <div class="form-group">
            <button class="btn btn-primary mr-2" name="btnUMSearch" (click)="getGridDataByFilterSearch()">
              Search
            </button>
            <button class="btn btn-outline-primary mr-2" name="btnCOMReset" (click)="resetFilters()">
              Reset
            </button>
          </div>
        </div>
        <div id="gridActions" class="ml-auto">
          <label class="mr-2" name="lblUMAddUser" (click)="open(addUserModal)"><span> <em
                class="fa fa-user-plus"></em></span> Add User</label>
          <label *ngIf="isRowSelected" class="mr-2" name="lblUMActiveUser" (click)="open(activeModal)"><span> <em
                class="fa fa-check"></em></span> Active</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblUMActiveUser" title="Select the user to Active"><span> <em
                class="fa fa-check"></em></span> Active</label>
          <label *ngIf="isRowSelected" class="mr-2" name="lblUMInActiveUser" (click)="open(inActiveModal)"><span> <em
                class="fa fa-user-times"></em></span> InActive</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblUMInActiveUser" title="Select the user to InActive"><span>
              <em class="fa fa-user-times"></em></span> InActive</label>
          <label *ngIf="isRowSelected" class="mr-2" name="lblUMAUpdateUser" (click)="open(userUpdateModal)"><span> <em
                class="fa fa-pencil"></em></span> Update Role</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblUMUpdateUser" title="Select the user to Change Role"><span>
              <em class="fa fa-pencil"></em></span> Update Role</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <ag-grid-angular #agGridUserManagement [enableCellTextSelection]="true" class="ag-theme-material mt-2 usermanagement-layout" [rowData]="rowData"
    [columnDefs]="columnDefs" [gridOptions]="gridOptions" (rowSelected)="onRowSelected($event)">
  </ag-grid-angular>
</div>

<!-- Add User Modal Popup HTML -->
<ng-template #addUserModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Add User</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
      <span aria-hidden="true">
        <em class="fa fa-window-close"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-0 mt-3">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label name="lblUMMLFName" class="control-label required">First Name</label>
            <input type="text" class="form-control" name="txtUMMLFName" [(ngModel)]="txtUMMLFName" maxlength="20" />
          </div>
          <div class="lblerror" *ngIf="lblErrMLFName">
            {{ this.lblErrMLFName }}
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label name="lblUMMLLName" class="control-label required">Last Name</label>
            <input type="text" class="form-control" name="txtUMMLLName" [(ngModel)]="txtUMMLLName" maxlength="20" />
          </div>
          <div class="lblerror" *ngIf="lblErrMLLName">
            {{ this.lblErrMLLName }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label name="lblUMMLDName" class="control-label required">Display Name</label>
            <input type="text" class="form-control" name="txtUMMLDName" [(ngModel)]="txtUMMLDName" maxlength="20" />
          </div>
          <div class="lblerror" *ngIf="lblErrMLDName">
            {{ this.lblErrMLDName }}
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label name="lblUMMLEmailId" class="control-label required">Email Id</label>
            <input type="text" class="form-control" name="txtUMMLEmailId" [(ngModel)]="txtUMMLEmailId" maxlength="40" />
          </div>
          <div class="lblerror" *ngIf="lblErrMLEmail">
            {{ this.lblErrMLEmail }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label name="lblUMMLRole" class="control-label required">Role</label>
            <select name="ddUMMLRole" class="form-control" [(ngModel)]="selectedMLUserRole.id"
              (change)="userRoleModalSelectChanged($event)">
              <option value="0" selected>--- Select Role ---</option>
              <option *ngFor="let useRole of userRoles" [ngValue]="useRole.id">
                {{ useRole.name }}
              </option>
            </select>
          </div>
          <div class="lblerror" *ngIf="lblErrMLRole">
            {{ this.lblErrMLRole }}
          </div>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" name="btnUMMLCreate" class="btn btn-primary mr-2" (click)="createUser()">
      Create
    </button>
    <button type="button" name="btnUMMLCancel" class="btn btn-outline-primary mr-2" (click)="modalClose('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<!-- User Active Modal Popup HTML -->
<ng-template #activeModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Active</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-2 mt-3">
      <div class="row">
        <div class="col">
          <div class="form-group">
            Are you sure want to Active the selected User :
            <strong>{{ rowSelectedData["firstName"] }}</strong> ..?
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" name="btnUMMLYes" class="btn btn-primary mr-2" (click)="userActive()">
      Yes
    </button>
    <button type="button" name="btnUMMLNo" class="btn btn-outline-primary mr-2" (click)="modalClose('Save click')">
      No
    </button>
  </div>
</ng-template>

<!-- User InActive Modal Popup HTML -->
<ng-template #inActiveModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">InActive</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-2 mt-3">
      <div class="row">
        <div class="col">
          <div class="form-group">
            Are you sure want to InActive the selected User :
            <strong>{{ rowSelectedData["firstName"] }}</strong> ..?
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" name="btnUMMLYes" class="btn btn-primary mr-2" (click)="userInActive()">
      Yes
    </button>
    <button type="button" name="btnUMMLNo" class="btn btn-outline-primary mr-2" (click)="modalClose('Save click')">
      No
    </button>
  </div>
</ng-template>

<!-- User Role Update Modal Popup HTML -->
<ng-template #userUpdateModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Change Role</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-2 mt-3">
      <div class="row change-role-wrapper pb-2">
        <div class="col">
            Are you sure want to Change Role for the selected User :
            <strong>{{ rowSelectedData["firstName"] }}</strong> ..?
          </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label name="lblUpdateRole" class="control-label">Role</label>
          </div>
        </div>
        <div class="col">  
          <div class="form-group">
            <select name="ddUpdateRole" class="form-control" [(ngModel)]="updatedRoleId"
              (change)="updatedUserRoleModalSelectChanged($event)">
              <option *ngFor="let useRole of userRoles" [ngValue]="useRole.id">
                {{ useRole.name }}
              </option>
            </select>
          </div>
          </div>
          <div class="col"></div>
      </div>
      <div class="lblerror" *ngIf="lblErrMLRole">
        {{ this.lblErrMLRole }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="isUserActive" name="btnURLYes" class="btn btn-primary mr-2" (click)="userRoleUpdate()">
      Yes
    </button>
    <button type="button" name="btnURLNo" class="btn btn-outline-primary mr-2" (click)="modalClose('Save click')">
      No
    </button>
  </div>
</ng-template>