import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { FCToasterService } from '../common/toaster/FCToasterService';
import { Service } from '../service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from "xlsx";
import { ediorderpriority } from '../common/models/ediorderpriority';
import { MatTabChangeEvent,MatTabGroup } from '@angular/material/tabs';
import { ediorderpriorityList } from '../common/models/ediorderpriorityList';

@Component({
  selector: 'app-ediorders',
  templateUrl: './ediorders.component.html',
  styleUrls: ['./ediorders.component.scss']
})
export class EdiordersComponent implements OnInit {
  title = 'EDI Order Search';
  @ViewChild("agGridEdiOrders", { static: false }) agGridGateway: AgGridAngular;
  @ViewChild("agPGridEdiOrders", { static: false }) agPGridEdiOrders: AgGridAngular;
  @ViewChild('tabGroup',{static:false}) tabGroup: MatTabGroup;

  rowData: any[];
  columnDefs: any[];
  UpdatePriorityRowData: ediorderpriority[] 
  PriorityRowData: ediorderpriorityList[];
  PriorityLoadData: ediorderpriorityList[];
  columnPDefs: any[];  
  fileTypes: any[];
  txtEdiOrdersPOSrch: any;
  txtEdiOrdersUNSrch: any;
  gridOptions: GridOptions;
  PrioritygridOptions: GridOptions;
  createdFromDate: Date;
  closeResult: string;
  fileToUpload: File;
  excelUnitTypeError: string = "";
  txtPriEdiOrdersSrch: any;
  matTabs = [1,2,3,4]; 
  lblEdiOrderCount: number = 0;  

  constructor(private apiService: Service, private toastr: FCToasterService,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.bindGridOnPageLoad();
    this.gridOptions = {
      defaultColDef: { filter: true },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true
    }

    this.PrioritygridOptions = {
      defaultColDef: { filter: true },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      paginationAutoPageSize: true
    }
    this.bindDefaultValuesOnPageLoad();
  }
  
  //Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "PO Number", field: "poNumber", sortable: true, width: 180, tooltipField: "purchaseOrderNumber", headerTooltip: "PO Number" },
      { headerName: "Contract", field: "contract", sortable: true, width: 180, tooltipField: "contract", headerTooltip: "Contract" },
      { headerName: "Branch", field: "branch", sortable: true, width: 160, tooltipField: "branch", headerTooltip: "Branch" },
      { headerName: "Order Number", field: "orderNumber", sortable: true, width: 200, tooltipField: "orderNumber", headerTooltip: "Order Number" },
      { headerName: "Unit Number", field: "unitNumber", sortable: true, width: 150, tooltipField: "unitNumber", headerTooltip: "Unit Number" },
      { headerName: "PO Date", field: "poDate", sortable: true, width: 180, tooltipField: "poDate", headerTooltip: "PO Date" },
      { headerName: "Building Address", field: "buildingAddress", sortable: true, width: 250, resizable: true, tooltipField: "buildingAddress", headerTooltip: "Building Address" },
      { headerName: "Shipper Name", field: "shipperName", sortable: true, width: 180, tooltipField: "shipperName", headerTooltip: "Shipper Name" },
      { headerName: "Shipper Phone", field: "shipperPhone", sortable: true, width: 180, tooltipField: "shipperPhone", headerTooltip: "Shipper Phone" },
      { headerName: "Part Number", field: "partNumber", sortable: true, width: 180, tooltipField: "partNumber", headerTooltip: "Part Number" },
      { headerName: "Qty", field: "quantity", sortable: true, width: 140, tooltipField: "quantity", headerTooltip: "Qty" },
      { headerName: "Price", field: "price", sortable: true, width: 150, tooltipField: "price", headerTooltip: "Price" },
      { headerName: "Unit Status", field: "unitStatus", sortable: true, width: 180, tooltipField: "unitStatus", headerTooltip: "Unit Status" },
      { headerName: "JobSite", field: "jobSite", sortable: true, width: 180, tooltipField: "jobSite", headerTooltip: "JobSite" }
    ];
    this.rowData = [];

    this.columnPDefs = [
      { headerName: "Priority", field:"priority",sortable: true,width: 130, cellEditor: "agTextCellEditor", editable : true },      
      { headerName: "PO", field: "poNumber", sortable: true, width: 180, tooltipField: "poNumber", headerTooltip: "PO Number" },
      { headerName: "Order #", field: "orderNumber", sortable: true, width: 130, tooltipField: "orderNumber", headerTooltip: "Order" },
      { headerName: "Machine", field: "machine", sortable: true, width: 130, resizable: true, tooltipField: "machine", headerTooltip: "Machine" },
      { headerName: "Need By", field: "needBy", sortable: true, width: 180, tooltipField: "needBy", headerTooltip: "Need By" },
      { headerName: "BI Data State", field: "biDataState", sortable: true, width: 160, tooltipField: "biDataState", headerTooltip: "BI Data State" },
      { headerName: "G3MS State", field: "g3MSState", sortable: true, width: 150, tooltipField: "g3MSState", headerTooltip: "G3MS State" },
      { headerName: "Rel", field: "rel", sortable: true, width: 100, tooltipField: "rel", headerTooltip: ""},
      { headerName: "Altek Reference", field: "altekRef", sortable: true, width: 200, tooltipField: "altekRef", headerTooltip: "Altek Reference" },
      { headerName: "Type", field: "type", sortable: true, width: 100, tooltipField: "type", headerTooltip: "Type" },
      { headerName: "GL ID", field: "glid", sortable: true, width: 110, tooltipField: "glid", headerTooltip: "GL ID" },
      { headerName: "Req. Machines", field: "reqMachines", sortable: true, width: 170, tooltipField: "reqMachines", headerTooltip: "Required Machines" },
      { headerName: "Found Machines", field: "foundMachines", sortable: true, width: 190, tooltipField: "foundMachines", headerTooltip: "Found Machines" },
      { headerName: "PriorityHidden", field:"priorityHidden",sortable: true,width: 130, hide : true }
      
    ];    
  }

  // Assign default values to variables on Page load
  bindDefaultValuesOnPageLoad() {
    this.txtEdiOrdersPOSrch = "";
    this.txtEdiOrdersUNSrch = "";
    this.txtPriEdiOrdersSrch = "";
    this.rowData = [];
    this.getPriorityGridDataOnSearch();    
  }

  onKeyEnter() {
    this.getGridDataOnSearch();    
  }

  //Get the g3ms unit data on search
  getGridDataOnSearch() {
    if ((this.txtEdiOrdersPOSrch === null || this.txtEdiOrdersPOSrch === "" || this.txtEdiOrdersPOSrch === undefined)
      && (this.txtEdiOrdersUNSrch === null || this.txtEdiOrdersUNSrch === "" || this.txtEdiOrdersUNSrch === undefined)) {
      this.toastr.showError("Please enter PO Number or Unit Number");
      this.rowData = [];
    }
    else if (this.txtEdiOrdersPOSrch !== "" && this.txtEdiOrdersUNSrch !== "") {
      this.toastr.showError("Please enter either PO Number or Unit Number");
      this.rowData = [];
    }
    else {
      this.apiService
        .getEDIOrdersData(this.txtEdiOrdersPOSrch, this.txtEdiOrdersUNSrch)
        .subscribe((data: any) => {
          if (data != null) {
            this.rowData = data;
          }
          else {
            this.rowData = [];
          }
        });
    }
  }

  //Reset filters
  resetFilters() {
    this.bindDefaultValuesOnPageLoad();
    this.bindGridOnPageLoad();
  }

  onChange(event: MatTabChangeEvent) {
    this.tabGroup._tabs.toArray()[2].disabled = true;
    this.tabGroup._tabs.toArray()[3].disabled = true;
    const tab = event.tab.textLabel;    
    if(tab ==="EDI Orders Search")
     {
      this.tabGroup._tabs.toArray()[0].disabled = true;  
      this.tabGroup._tabs.toArray()[1].disabled = false;        
     }
     else
     {
      this.tabGroup._tabs.toArray()[0].disabled = false;  
      this.tabGroup._tabs.toArray()[1].disabled = true;
     }
  }

  getPriorityGridDataOnSearch()
  {
    this.apiService
    .getEDIOrdersPrirorityData()
    .subscribe((data: ediorderpriorityList[]) => {
      if (data != null) {
        this.PriorityLoadData = data;       
        this.PriorityRowData = this.PriorityLoadData;    
        this.lblEdiOrderCount = this.PriorityLoadData.length;   
        this.PrioritygridOptions.api.setFilterModel(null);                            
      }
      else{
        this.PriorityLoadData = [];                
      }
    });    
  }

  onFileUpload() {
    if (this.fileToUpload !== null && this.fileToUpload !== undefined) {
      if (this.onFileUploadValidations()) {
        const reader: FileReader = new FileReader();              
        reader.readAsArrayBuffer(this.fileToUpload);
        reader.onload = (e: any) => {
          const bstr: string = e.target.result;
          const ediWorkBook: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          if(ediWorkBook.SheetNames.length < 3)
          {
            this.toastr.showError("Please upload a valid file");
            return;
          }
          const biWSName = ediWorkBook.SheetNames[2];
          if (biWSName !== undefined && biWSName.toUpperCase() === "GL STATUS REPORT" ) {
              this.apiService.uploadEDIPriorityFile(this.fileToUpload).subscribe(data => {  if (data > 0) {
                this.modalService.dismissAll();
                this.clearModalPopupClose();
                this.getPriorityGridDataOnSearch();
                this.toastr.showSuccess("Successfully Uploaded!!");
              }
              else {
                this.toastr.showError("Failed to upload the file");
                this.modalService.dismissAll();
              }
            });
          }
          else {
            this.toastr.showError("Sheet Name should be GL Status Report in the selected file");
          }
        }       
      }      
    }
    else {
      this.excelUnitTypeError = "Please select a file to upload"
    }
  }

  clearModalPopupClose() {
    this.excelUnitTypeError = "";
  }

  onFileUploadValidations(): boolean {
    if (this.fileToUpload["type"] != ("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {      
        this.excelUnitTypeError = "Please select only Excel File";
        return false;
    }        
    else {
        return true;
    }
  }

  modalClose(content) {
    this.modalService.dismissAll();
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.excelUnitTypeError = "";    
  }

  exportExcel() {
    const dateObj = new Date();
    if (this.PriorityLoadData !== null && this.PriorityLoadData.length > 0) {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: "Otis_One_Report-" + dateObj.getFullYear() + '_' + (String(dateObj.getMonth() + 1).padStart(2, '0')) + '_' + String(dateObj.getDate()).padStart(2, '0')
      };
      this.PrioritygridOptions.api.exportDataAsCsv(params);
    }
    else{
      this.toastr.showError("No Data exists to export");
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  onPriorityKeyEnter()
  {
    this.getFilteredSortedGrid();
  }

  getFilteredSortedGrid()
  {   
    if(this.txtPriEdiOrdersSrch == null || this.txtPriEdiOrdersSrch == "")
    {
      this.getPriorityGridDataOnSearch();
      if(this.PriorityLoadData.length == 0)
      {
        this.toastr.showError("No records found");
      }
    }
    else {
      if (this.PriorityLoadData != null) {
        this.PriorityRowData = this.PriorityLoadData.filter(item => item.poNumber.toUpperCase().includes(this.txtPriEdiOrdersSrch.toUpperCase()) 
                        || item.orderNumber.includes(this.txtPriEdiOrdersSrch) 
                        || item.machine.toUpperCase().includes(this.txtPriEdiOrdersSrch.toUpperCase()));                      
      } 
    }
  }
  
  btnPrioritySave()
  {    
     if(this.PriorityRowData.length == 0 || this.PriorityRowData == null){
        this.toastr.showError("No data to save, upload EDI orders data");
     }
     else{
      this.agPGridEdiOrders.api.stopEditing();
      this.UpdatePriorityRowData = this.PriorityRowData.filter(x=>x.priority != x.priorityHidden);
      this.apiService
          .updateEDIPriorityData(this.UpdatePriorityRowData)
          .subscribe((updatedCount: any) => {            
              this.getPriorityGridDataOnSearch();
              this.toastr.showSuccess("Priority saved Successfully");            
          });
      }
  }

  resetPFilters()
  {
    this.txtPriEdiOrdersSrch = "";
    this.getPriorityGridDataOnSearch();
  }
}