import { Component, OnInit } from '@angular/core';
import { OSSoftware } from '../common/models/ossoftware';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-openlicense',
  templateUrl: './openlicense.component.html',
  styleUrls: ['./openlicense.component.scss']
})
export class OpenlicenseComponent implements OnInit {
  softwareList: OSSoftware[];
  selectedSoftware: any = {};
  licenseInfo: any = [];
  softwareSelectedId: number;
  softwareSelectedValue: string;
  licenseFileName: string;
  licenseName: string;

  constructor(private httpClient: HttpClient) {
    this.selectedSoftware = { id: 0, name: "--- Select Open Source Software ---" };
    this.licenseName = "List Of Open Source Softwares";
  }

  ngOnInit(): void {
    this.getSoftwareList();
    this.readfile("Licenses_All.txt");
  }

  getSoftwareList() {
    this.softwareList = [
      { id: 1, name: "AutoMapper", fileName: "AutoMapper_LICENSE.txt" },
      { id: 2, name: "EPPLUS Core", fileName: "EPPlus.Core_LICENSE.txt" },
      { id: 3, name: "Google protobuf", fileName: "Google.protobuf_LICENSE.txt" },
      { id: 4, name: "ApplicationInsights ASP.NETCore", fileName: "ApplicationInsightsASP.NETCore_License.txt" },
      { id: 5, name: "Microsoft Identity Web", fileName: "MIT_License.txt" },
      { id: 6, name: "Microsoft AspNetCore Authentication AzureADB2C UI", fileName: "ASP.NETCoreAzureActiveDirectoryB2C_LICENSE.txt" },
      { id: 7, name: "Microsoft AspNetCore Razor Design", fileName: "Microsoft.AspNetCore.Razor.Design.txt" },
      { id: 8, name: "Microsoft Azure Functions Extensions", fileName: "MIT_License.txt" },
      { id: 9, name: "Microsoft Azure WebJobs Extensions Storage", fileName: "MIT_License.txt" },
      { id: 10, name: "Microsoft EntityFrameworkCore", fileName: "MIT_License.txt" },
      { id: 11, name: "Microsoft EntityFrameworkCore SqlServer", fileName: "MIT_License.txt" },
      { id: 12, name: "Microsoft Extensions Http", fileName: "MIT_License.txt" },
      { id: 13, name: "Microsoft Graph", fileName: "Microsoft.Graph.txt" },
      { id: 14, name: "Microsoft Graph Auth", fileName: "Microsoft.GraphAuth.txt" },
      { id: 15, name: "System Buffers", fileName: "MIT_License.txt" },
      { id: 16, name: "Microsoft IO RecyclableMemoryStream", fileName: "Microsoft.IO.RecyclableMemoryStream.txt" },
      { id: 17, name: "Microsoft .NET Sdk Functions", fileName: "Microsoft.NET.Sdk.Functions.txt" },
      { id: 18, name: "Microsoft .NET Test Sdk", fileName: "Microsoft.NET.Test.Sdk.txt" },
      { id: 19, name: "Microsoft .NETCore App", fileName: "MicrosoftDotNetCoreApp_license.txt" },
      { id: 20, name: "Microsoft VisualStudio Web CodeGeneration Design", fileName: "Microsoft.VisualStudio.Web.CodeGeneration.Design.txt" },
      { id: 21, name: "MSTest TestAdapter", fileName: "MSTest.TestAdapter.txt" },
      { id: 22, name: "MSTest TestFramework", fileName: "MSTest.TestFramework.txt" },
      { id: 23, name: "System Data SqlClient", fileName: "System.data.sqlclient.txt" },
      { id: 24, name: "Newtonsoft Json", fileName: "Newtonsoft.Json.txt" },
      { id: 25, name: "Microsoft Azure Functions Worker Extensions Http", fileName: "MIT_License.txt" },
      { id: 26, name: "Swashbuckle AspNetCore", fileName: "Swashbuckle.AspNetCore.txt" },
      { id: 27, name: "bootstrap", fileName: "bootstrap_License.txt" },
      { id: 28, name: "ng-bootstrap", fileName: "ng-bootstarp_license.txt" },
      { id: 29, name: "font awesome", fileName: "LICENSE_Font Awesome.txt" },
      { id: 30, name: "angular fontawesome", fileName: "angular_fontawesome.txt" },
      { id: 31, name: "ag-grid community", fileName: "ag-grid-community.txt" },
      { id: 32, name: "ag-grid angular", fileName: "ag_grid_angular_LICENSE.txt" },
      { id: 33, name: "ngx-toastr", fileName: "ngx-toastr_LICENSE.txt" },
      { id: 34, name: "jquery", fileName: "jquery_LICENSE.txt" },
      { id: 35, name: "ng-http-loader", fileName: "ng-http-loader_LICENSE.txt" },
      { id: 36, name: "angular-oauth2-oidc", fileName: "angular-oauth2-oidc_LICENSE.txt" },
      { id: 37, name: "rxjs", fileName: "rxjs.txt" },
      { id: 38, name: "popperjs", fileName: "popper_LICENSE.md.txt" },
      { id: 39, name: "Angular CLI", fileName: "angularcli.txt" },
      { id: 40, name: "angular material", fileName: "angularmaterial.txt" },
      { id: 41, name: "Dapper", fileName: "Dapper_License.txt" },
      { id: 42, name: "Moq", fileName: "Moq_License.txt" },
      { id: 43, name: "Azure Storage Blobs", fileName: "MIT_License.txt" },
      { id: 44, name: "Azure Storage Queues", fileName: "MIT_License.txt" },
      { id: 45, name: "Microsoft AspnetCore Http", fileName: "Microsoft.AspNetCore.Http.txt" },
      { id: 46, name: "Microsoft Extensions Caching Abstractions", fileName: "MIT_License.txt" },
      { id: 47, name: "Microsoft Extensions Caching Memory", fileName: "MIT_License.txt" },
      { id: 48, name: "Microsoft Extensions Configuration Binder", fileName: "MIT_License.txt" },
      { id: 49, name: "Microsoft Extensions DependencyInjection Abstractions", fileName: "MIT_License.txt" },
      { id: 50, name: "Microsoft Extensions Logging Abstractions", fileName: "MIT_License.txt" },
      { id: 51, name: "Microsoft Extensions Primitives", fileName: "MIT_License.txt" },
      { id: 52, name: "Microsoft Identity Client Extensions Msal", fileName: "MIT_License.txt" },
      { id: 53, name: "System Collections Immutable", fileName: "MIT_License.txt" },
      { id: 54, name: "System ComponentModel Annotations", fileName: "MIT_License.txt" },
      { id: 55, name: "System Configuration ConfigurationManager", fileName: "MIT_License.txt" },
      { id: 56, name: "System Memory", fileName: "MIT_License.txt" },
      { id: 57, name: "System Numerics Vectors", fileName: "MIT_License.txt" },
      { id: 58, name: "System Runtime CompilerServices Unsafe", fileName: "MIT_License.txt" },
      { id: 59, name: "System Security AccessControl", fileName: "MIT_License.txt" },
      { id: 60, name: "System Security Permissions", fileName: "MIT_License.txt" },
      { id: 61, name: "System Security Principal Windows", fileName: "MIT_License.txt" },
      { id: 62, name: "Azure.Communication.Email", fileName: "MIT_License.txt" },
      { id: 63, name: "GrapeCity.Documents.Pdf", fileName: "GrapeCity.txt"}  
    ]
  }

  readfile(fileName) {
    this.httpClient.get("assets/license/" + fileName, { responseType: 'text' }).subscribe(data => {
      if (data != null) {
        this.licenseInfo = data;
      }
      else {
        this.licenseInfo = "";
      }
    })
  }

  // Software dropdown changed
  ossoftwareSelectChanged($event) {
    this.softwareSelectedId = $event.target.selectedIndex;
    if (this.softwareSelectedId !== null && this.softwareSelectedId !== undefined && this.softwareSelectedId > 0) {
      this.licenseName = this.softwareList.find(x => x.id == this.softwareSelectedId).name;
      this.licenseFileName = this.softwareList.find(x => x.id == this.softwareSelectedId).fileName;
      this.readfile(this.licenseFileName);
    }
    else {
      this.licenseName = "List Of Open Source Softwares";
      this.licenseFileName = "Licenses_All.txt";
      this.readfile(this.licenseFileName);
    }
  }
}