<div class="modal-header">
    <span>{{ title }}</span>
    <button type="button" class="close" (click)="close()" aria-hidden="true">
        X
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div [innerHTML]='message'>
          <!-- Message -->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <span class="pull-right" style="padding-right:2%">
      <button type="button" class="btn btn-default btn-sm" (click)="close()" id="btnCancel">Close</button>
    </span>
  </div>