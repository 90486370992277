<div id="provisioning-main-wraper">
  <div class="col-12 page-heading p-0">
    <h5>Provisioning</h5>
  </div>
  <div class="col-12 search-ft p-0 mt-3">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label name="lblPRFileName" class="control-label">File Name</label>
          <input type="text" class="form-control" name="txtPRFileName" [(ngModel)]="txtPRFileName" maxlength="40"
            tabindex="1" (keydown.enter)="onKeyEnter()">
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblPRFileType" class="control-label">File Type </label>
          <select name="ddPRFileType" class="form-control" [(ngModel)]="selectedFileType.id"
            (change)="fileTypeSelectChanged($event)" tabindex="2">
            <option value="0" selected> --- Select File Type --- </option>
            <option *ngFor="let fileType of fileTypes" [ngValue]="fileType.id">{{fileType.name}}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblPRFileStatus" class="control-label">File Status </label>
          <select name="ddPRFileStatus" class="form-control" [(ngModel)]="selectedFileStatus.id"
            (change)="fileStatusSelectChanged($event)" tabindex="3">
            <option value="0" selected> --- Select File Status --- </option>
            <option *ngFor="let status of fileStatus" [ngValue]="status.id">{{status.name}}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <div class="input-group">
            <label class="control-label col-12 p-0" name="lblPRUploadFrmDt">
              Uploaded From Date </label>
            <input class="form-control" placeholder="Select From Date" name="dpPRFrmDt" ngbDatepicker
              [(ngModel)]="dpPRFrmDate" #d1="ngbDatepicker">
            <div class="input-group-append dtToggle">
              <button class="btn btn-outline-secondary calendar" type="button" (click)="d1.toggle()" tabindex="4">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <div class="input-group">
            <label class="control-label col-12 p-0" name="lblPRUploadToDt">
              Uploaded To Date </label>
            <input class="form-control" placeholder="Select To Date" name="dpPRToDt" ngbDatepicker
              [(ngModel)]="dpPRToDate" #d2="ngbDatepicker">
            <div class="input-group-append dtToggle">
              <button class="btn btn-outline-secondary calendar" type="button" (click)="d2.toggle()" tabindex="5">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 d-inline-flex p-0">
        <div class="col d-flex align-items-start">
          <div class="form-group">
            <button class="btn btn-primary mr-2" name="btnPRSearch" (click)="getGridDataByFilterSearch()"
              tabindex="6">Search</button>
            <button class="btn btn-outline-primary mr-2" name="btnPRReset" (click)="onResetFilters()"
              tabindex="7">Reset</button>
          </div>
        </div>
        <div id="gridActions" class="ml-auto">
          <label class="mr-2" *ngIf="isBulkUpload" name="lblBulkUpload" title="Click on Bulk Upload"
           (click)="open(bulkUploadModal)"><span>
              <em class="fa fa-upload"></em></span>
            Bulk Upload</label>          
          <label class="mr-2" name="lblPRUpload" (click)="open(uploadModal)" tabindex="9"><span>
              <em class="fa fa-upload"></em></span> Upload</label>
          <label *ngIf="isRowSelected" class="mr-2" name="lblPRProcess" (click)="open(processModal)"><span>
              <em class="fa fa-angle-double-right"></em></span>
            Process</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblPRProcess" title="Select the File to process"
            tabindex="10"><span>
              <em class="fa fa-angle-double-right"></em></span>
            Process</label>
          <label *ngIf="isRowSelected" class="mr-2" name="lblPRDelete" (click)="open(deleteModal)" tabindex="11"><span>
              <em class="fa fa-trash"></em></span> Delete</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblPRDelete" title="Select the File to delete"><span>
              <em class="fa fa-trash"></em></span> Delete</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <ag-grid-angular #agGridProvision [enableCellTextSelection]="true" class="ag-theme-material mt-2 provision-layout"
    [rowData]="rowData" [columnDefs]="columnDefs" [gridOptions]="gridOptions" (rowSelected)="onRowSelected($event)">
  </ag-grid-angular>
</div>

<!-- Provisioning Upload Modal Popup HTML -->
<ng-template #uploadModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">File Upload</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalClose('cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-2 mt-3">
     
        <div class="col-12">
          <div class="form-group">
            <input type="file" title="Choose a file" id="fileUpload" name="fileUpload"
              accept=".xml, text/xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              (change)="handleFileInput($event.target.files)">
          </div>
          <div class="lblerror" *ngIf="responseMessage">
            {{ this.responseMessage }}
          </div>
        </div>
        <div class="col-12">
          <div class="row">
          <div class="form-group col-6" *ngIf="showExcelType">
            <label name="lblExcelUnitType" class="control-label required">Excel Unit Type
            </label>
            <select name="ddExcelUnitType" class="form-control" [(ngModel)]="excelUnitType.id"
            (change)="excelTypeSelectChanged($event)">
              <option value="0" selected>--- Select ---</option>
              <option *ngFor="let excelType of excelUnitTypes" [selected]="excelType.id === excelUnitTypeSelectedId"
                [value]="excelType.id">
                {{ excelType.excelUnitType }}
              </option>
            </select>
            <div class="lblerror">
              {{ this.excelUnitTypeError }}
            </div>
          </div>
          <div class="form-group col-6" *ngIf="!isDoubleDeckUnit">
            <label name="lblDeviceType" class="control-label">Device Type
            </label>
            <select name="ddDeviceType" class="form-control" (change)="deviceTypeSelectChanged($event)"
              [(ngModel)]="defaultDeviceType.id">
              <option value="0" selected>--- Select ---</option>
              <option *ngFor="let deviceType of deviceTypes"
                [selected]="deviceType.devicetypeid === deviceTypeSelectedId" [value]="deviceType.deviceTypeId">
                {{ deviceType.deviceType }}
              </option>
            </select>
            <div class="lblerror">
              {{ this.deviceTypeError }}
            </div>
          </div>
          </div>
        </div>
        <div class="row" *ngIf="isDoubleDeckUnit">
          <div class="col">
            <div class="card">
              <div class="card-header"> 
                <h5>Lower Deck</h5>
              </div>
              <div class="card-body d-inline-flex">
              <div class="col-12">
                  <label name="lblLDDeviceType" class="control-label">Device Type
                  </label>
                    
                  <select name="ddLDDeviceType" class="form-control" (change)="LDdeviceTypeSelectChanged($event)">
                    <option *ngFor="let deviceType of LDdeviceTypes">
                      {{ deviceType.deviceType }}
                    </option>
                  </select>
                  <div class="lblerror">
                    {{ this.LDdeviceTypeError }}
                  </div>
                </div>                                                      
                  
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div class="card-header"> 
                  <h5>Upper Deck</h5>
                </div>
                <div class="card-body d-inline-flex">
                <div class="col-12">
                    <label name="lblUDDeviceType" class="control-label">Device Type
                    </label>
                      
                    <select name="ddUDDeviceType" class="form-control" (change)="UDDeviceTypeSelectChanged($event)">
                      <option *ngFor="let deviceType of UDdeviceTypes">
                        {{ deviceType.deviceType }}
                      </option>
                    </select>
                    <div class="lblerror">
                      {{ this.UDdeviceTypeError }}
                    </div>
                  </div>                                                      
                    
                  </div>
                </div>
              </div>
        </div>      
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" (click)="onFileUpload()">Upload</button>
    <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('cancel')">Cancel</button>
  </div>
</ng-template>

<!-- Provisioning Process Modal Popup HTML -->
<ng-template #processModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">File Process</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-2 mt-3">
      <div class="row">
        <div class="col">
          <div class="form-group">
            Are you sure want to process the file : <strong>{{ processFileName }}</strong> ..?
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" (click)="onFileProcess($event)">Yes</button>
    <button type="button" class="btn btn-outline-primary mr-2" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>

<!-- Provisioning Delete Modal Popup HTML -->
<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">File Delete</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-2 mt-3">
      <div class="row">
        <div class="col">
          <div class="form-group">
            Are you sure want to delete the selected file : <strong>{{ rowSelectedData["fileName"] }}</strong> ..?
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" (click)="onFileDelete()">Yes</button>
    <button type="button" class="btn btn-outline-primary mr-2" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>

<!-- To Upload Multiple files from ContractsNEB folder -->
<ng-template #bulkUploadModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Bulk Upload</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-2 mt-3">
      <div class="row">
        <div class="col">
          <label name="lblPRFileName" class="control-label">Unit Numbers</label>
          <div style="display: flex;">
            <input type="text" class="bulktext" maxlength="30" name="txtUnitNumbers" [(ngModel)]="txtUnitNumbers"
              style="width:50%;margin-right: 20px;margin-bottom: 5px;">
            <button type="button" class="btn btn-primary mr-2" (click)="onBulkUpload()">Upload</button>
          </div>
          <div style="padding-bottom: 10px;"></div>
          <div class="lblerror" *ngIf="bulkUploadMessage">
            {{ this.bulkUploadMessage }}
           </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" (click)="onClearBulkUpload()">Reset</button>
    <button type="button" class="btn btn-outline-primary mr-2" (click)="modal.close()">Close</button>
  </div>
</ng-template>