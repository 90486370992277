<ng-http-loader [backdrop]="true" [backgroundColor]="'#292a54'" [debounceDelay]="100" [extraDuration]="1000"
  [minDuration]="2000" [opacity]=".6" spinner="sk-wave" #spinner></ng-http-loader>

<div *ngIf="!allClaims" id="fc-login-div">
  <div class="main-logo">
        <img src=" /assets/images/otis-logo.png">
      </div>
  <h1 class="mt-5">
    Factory Commissioning !
  </h1>
  <div>
    <button class="btn btn-primary btn-lg mt-2" (click)="login()">Login</button>
  </div>
</div>
<div *ngIf="isAutenticate" style="height: 100%;">
  <header class="col-12 d-inline-flex p-0">
    <div id="hamburger" [class.change]="isSortMenu" (click)="isSortMenu=!isSortMenu">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <span id="projectName" class="col-8">OTIS | Factory Commissioning</span>
    <div class="col text-right">
      <div ngbDropdown class="d-inline-block lt-hd-dropdown">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle> {{ loginName }}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem> {{ loginEmail }}</button>
          <button ngbDropdownItem (click)="open(versionModal)">Version Info</button>
          <button ngbDropdownItem (click)="openUserManual()">User Manual</button>
          <button ngbDropdownItem (click)="logout()">Logout</button>
        </div>
      </div>
    </div>
  </header>
  <section class="col-12 p-0 d-inline-flex">
    <nav [class.iconsMenu]="isSortMenu" id="fcMenu" class="col p-0">
      <li class="nav-item" routerLink="/home" title="Home">
        <span>
          <em class="fa fa-home"></em>
        </span>
        <a class="nav-link">Home</a>
      </li>
      <li class="nav-item" routerLink="/provisioning" title="Provisioning">
        <span>
          <em class="fa fa-bolt"></em>
        </span>
        <a class="nav-link">Provisioning</a>
      </li>
      <li class="nav-item" routerLink="/biprovision" title="Service & MOD BI Data Provisioning">
        <span>
          <em class="fa fa-angle-double-right"></em>
        </span>
        <a class="nav-link">Service & MOD BI Data Provisioning</a>
      </li>
      <li class="nav-item" routerLink="/gateway" title="Gateway">
        <span>
          <em class="fa fa-link"></em>
        </span>
        <a class="nav-link">Assign Gateway</a>
      </li>
      <li class="nav-item" routerLink="/commissioning" title="CPIB2 Commissioning">
        <span>
          <em class="fa fa-tasks" aria-hidden="true"></em>
        </span>
        <a class="nav-link">CPIB Commission</a>
      </li>
      <li class="nav-item" routerLink="/epalcommission" title="ePAL Commissioning">
        <span>
          <em class="fa fa-empire"></em>
        </span>
        <a class="nav-link"> ePAL Commission</a>
      </li>
      <li class="nav-item" routerLink="/g3msunitsearch" title="G3MS Unit Search">
        <span>
          <em class="fa fa-search"></em>
        </span>
        <a class="nav-link"> G3MS Units</a>
      </li>
      <li class="nav-item" routerLink="/ediorders" title="EDI Orders">
        <span>
          <em class="fa fa-first-order"></em>
        </span>
        <a class="nav-link">EDI Orders</a>
      </li>
      <li *ngIf="isAdmin" [routerLinkActive]="['active']" class="nav-item" routerLink="/usermanagement"
        title="User Management">
        <span>
          <em class="fa fa-user"></em>
        </span>
        <a class="nav-link">User Management</a>
      </li>
      <li class="nav-item" routerLink="/reports" title="Reports">
        <span>
          <em class="fa fa-file"></em>
        </span>
        <a class="nav-link">Reports</a>
      </li>
      <li class="nav-item" routerLink="/history" title="Unit History">
        <span>
          <em class="fa fa-history"></em>
        </span>
        <a class="nav-link">Unit History</a>
      </li>
      <li [routerLinkActive]="['active']" class="nav-item" routerLink="/license" title="License">
        <span>
          <em class="fa fa-book"></em>
        </span>
        <a class="nav-link">License</a>
      </li>
    </nav>
    <main id="fcMainContent" class="col p-0">
      <router-outlet></router-outlet>
    </main>
  </section>
  <footer class="col-12">
    <img class="fc-footer-img"  src="../assets/images/otis-logo-white.png">
  </footer>
</div>

<!-- Version Modal  Popup HTML -->
<ng-template #versionModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Version Info</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-2 mt-3">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <p class="text-style">App Name : {{ appVersion["AppName"] }}</p>
            <p class="text-style">App Version : {{ appVersion["AppVersion"] }}</p>
            <p class="text-style">G3MS Version : {{ appVersion["G3MSVersion"] }}</p>
            <p class="text-style">CIMS Version : {{ appVersion["CIMSVersion"] }}</p>
            <p class="text-style">Build Date : {{ appVersion["BuildDate"] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('Save click')">Close</button>
  </div>
</ng-template>