import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { Service } from '../service';
import { GridOptions } from '@ag-grid-community/all-modules';
import { FCToasterService } from '../common/toaster/FCToasterService';

@Component({
  selector: 'app-g3msunitsearch',
  templateUrl: './g3msunitsearch.component.html',
  styleUrls: ['./g3msunitsearch.component.scss']
})
export class G3msunitsearchComponent implements OnInit {
  title = 'G3MS Unit Search';
  @ViewChild("agGridGateway", { static: false }) agGridGateway: AgGridAngular;
  rowData: any[];
  columnDefs: any[];
  fileTypes: any[];
  txtG3MSSrch: any;
  gridOptions: GridOptions;
  
  constructor(private apiService: Service, private toastr: FCToasterService) { }

  ngOnInit(): void {
    this.bindGridOnPageLoad();
    this.bindDefaultValuesOnPageLoad();
    this.gridOptions = {
      defaultColDef: { filter: true },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true
    }    
  }

      //Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "Unit Number", field: "elevatorUnitNumber", sortable: true, width: 220, tooltipField: "elevatorUnitNumber", headerTooltip: "Unit Number" },
      { headerName: "Elevator Type", field: "elevatorType", sortable: true, width: 190, tooltipField: "elevatorType", headerTooltip: "Elevator type" },
      { headerName: "RemType Id", field: "remTypeId", sortable: true, width: 200, tooltipField: "remTypeId", headerTooltip: "RemType Id" },
      { headerName: "Building Name", field: "buildingName", sortable: true, width: 300, tooltipField: "buildingName", headerTooltip: "Building Name" },
      { headerName: "Building Address", field: "address", sortable: true, width: 350, resizable: true, tooltipField: "address", headerTooltip: "Building Address" },
      { headerName: "CP-ID/UUID", field: "cpibUUId", sortable: true, width: 250, resizable: true, tooltipField: "cpibUUId", headerTooltip: "CPID/UUID" },
      { headerName: "Country Id", field: "countryId", sortable: true, width: 150, tooltipField: "countryId", headerTooltip: "Country Id", hide: "true" },
      { headerName: "Country Name", field: "countryName", sortable: true, width: 200, tooltipField: "countryName", headerTooltip: "Country Name" }
    ];

    this.rowData = [];
  }

  // Assign default values to variables on Page load
  bindDefaultValuesOnPageLoad() {
    this.txtG3MSSrch = "";
  }

  onKeyEnter() {
    this.getGridDataOnSearch();    
  }
  //Get the g3ms unit data on search
  getGridDataOnSearch() {
    if (this.txtG3MSSrch === null || this.txtG3MSSrch === undefined || this.txtG3MSSrch === "") {
      this.toastr.showError("Please enter the Unit Number or Building Name or Address");
    }
    else {
      this.apiService
        .getG3MSUnitData(this.txtG3MSSrch)
        .subscribe((data: any) => {
          if (data != null) {
            this.rowData = data;
          }
          else {
            this.rowData = [];
          }
        });
    }
  }

  //Reset filters
  resetFilters() {
    this.bindDefaultValuesOnPageLoad();
    this.bindGridOnPageLoad();
  }
}
