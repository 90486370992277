<div id="epalcommission-main-wraper">
  <div class="col-12 page-heading p-0">
    <h5>ePAL Commission</h5>
  </div>
  <div class="col-12 search-ft p-0 mt-3">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label name="lblePALSrchBldId" class="control-label">Building Id</label>
          <input type="text" class="form-control" name="txtePALSrchBldId" [(ngModel)]="txtePALSrchBldId" maxlength="20"
            tabindex="1" (keydown.enter)="onKeyEnter()"/>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblePALSrchBldName" class="control-label">Building Name</label>
          <input type="text" class="form-control" name="txtePALSrchBldName" [(ngModel)]="txtePALSrchBldName"
            maxlength="30" tabindex="2" (keydown.enter)="onKeyEnter()"/>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblePALSrchBldAdd" class="control-label">Building Address</label>
          <input type="text" class="form-control" name="txtePALSrchBldAdd" [(ngModel)]="txtePALSrchBldAdd"
            maxlength="30" tabindex="3" (keydown.enter)="onKeyEnter()" />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label name="lblePALSrchUnitNum" class="control-label">Unit Number</label>
          <input type="text" class="form-control" name="txtePALSrchUnitNum" [(ngModel)]="txtePALSrchUnitNum"
            maxlength="30" tabindex="4" (keydown.enter)="onKeyEnter()"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 d-inline-flex p-0">
        <div class="col d-flex align-items-start">
          <div class="form-group">
            <button class="btn btn-primary mr-2" name="btnCOMSearch" (click)="getGridDataByFilterSearch()" tabindex="5">
              Search
            </button>
            <button class="btn btn-outline-primary mr-2" name="btnCOMReset" (click)="resetFilters($event)" tabindex="6">
              Reset
            </button>
          </div>
        </div>
        <div id="gridActions" class="ml-auto">
          <label *ngIf="isRowSelected" class="mr-2" name="lblEnaCom" (click)="open(ePalCommModal)"><span> <em
                class="fa fa-link"></em></span>&nbsp;ePAL Commission</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblDisCom"
            title="Select the Building to Commission ePAL HardwareId"><span>
              <em class="fa fa-link"></em></span>&nbsp;ePAL Commission</label>
        </div>
        <div id="gridActions" class="ml-auto">
          <label class="mr-2" name="lblEnaDecom" (click)="open(DecommModal)"
          title="Click here to Decommission ePAL HardwareId"><span> <em
                class="fa fa-unlink"></em></span>&nbsp;ePAL Decommission</label>
          </div>
        <div id="gridActions" class="ml-auto">
          <label *ngIf="isRowSelected" class="mr-2" name="lblEnaEpalCom" (click)="open(ePalGatewayModal)"><span> <em
                class="fa fa-tasks"></em></span>&nbsp;ePAL Gateway</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblDisEpalCom"
            title="Select the Building to Commission ePAL Gateway"><span> <em class="fa fa-tasks"></em></span>&nbsp;ePAL
            Gateway</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <ag-grid-angular #agGridCommission [enableCellTextSelection]="true" class="ag-theme-material mt-2 epalcommission-layout" [rowData]="rowData"
    [columnDefs]="columnDefs" [gridOptions]="gridOptions" (rowSelected)="onRowSelected($event)">
  </ag-grid-angular>
</div>

<div id="epalcommissionmodel">
  <!-- ePALHardwareId Commission Modal Popup HTML -->
  <ng-template #ePalCommModal let-modal>    
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">ePAL Commission</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-0 mt-3">
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label class="control-label l-block">Production BuildingId</label>
              <input for="lblMdlePALSrchBldId" name="lblMdlePALSrchBldId" class="form-control" tabindex="2" [(ngModel)]="lblMdlBldId"
                readonly />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="control-label l-block">Building Name</label>
              <input for="lblMdlePALSrchBldName" name="lblMdlePALSrchBldName" class="form-control" tabindex="3"
                [(ngModel)]="lblMdlBldName" readonly />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label name="lblMdlePALHwIdSrc" class="control-label l-block required">ePAL
                UUID</label>
              <input type="text" class="form-control" tabindex="4" [(ngModel)]="txtMLePALHardwareId" maxlength="32" />
            </div>
            <div class="lblerror" *ngIf="lblComErrorePALHardwareId">
              {{ this.lblComErrorePALHardwareId }}
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="control-label l-block required">Test Building Id</label>
              <input id="lblMdlePALTestBldId" type="text" class="form-control" name="lblMdlePALTestBldId"
                [(ngModel)]="txtMdlePALTestBldId" maxlength="32" tabindex="5"/>
            </div>
            <div class="lblerror" *ngIf="lblComErrorTestBldId">
              {{ this.lblComErrorTestBldId }}
            </div>
          </div>
        </div>
        <div class="row"> 
          <div class="col-3">
            <div class="form-group">
              <label name="lblMdlEloViewSerNum" class="control-label l-block required">EloView
                Serial Number</label>
              <input type="text" class="form-control" [(ngModel)]="txtMLEloviewSerialNum" maxlength="20" tabindex="6" (blur)="onEpalDeviceSearch()"/>
            </div>
            <div class="lblerror" *ngIf="lblMLEloviewSerialNum">
              {{ this.lblMLEloviewSerialNum }}
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label name="lblAppContent" class="control-label required">Device Application
                Type</label>
              <select name="ddAppContent" class="form-control" tabindex="7" (change)="onContentSelectChanged($event)">
                <option value="0" selected>--- Select ---</option>
                <option *ngFor="let content of appContent" [selected]="content.contentId === appContentSelectedId"
                  [value]="content.contentId">
                  {{ content.applicationName }}
                </option>
              </select>
            </div>
            <div class="lblerror" *ngIf="appContentError">
              {{ this.appContentError }}
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label name="lblEloViewDevice" class="control-label required">EloView
                Device</label>
              <input disabled type="text" class="form-control" [(ngModel)]="txtEloViewDevice" maxlength="20" tabindex="8" [title]="txtEloViewDevice"/>
              <label name="lblEloViewDeviceID" hidden>{{eloViewDeviceID}}</label>              
            </div>
            <div class="lblerror" *ngIf="eloViewDeviceError">
              {{ this.eloViewDeviceError }}
            </div>
          </div>
          <div class="col-3" *ngIf="txtEloViewDevice">
            <div class="form-group" style="height: 20px;">
            </div>
            <div class="pad-5">
              <label name="lblEloViewDevice" class="control-label">Device Status</label>&nbsp;
              <i class="fa fa-circle cGreen" *ngIf="eloviewDeviceStatus"></i>
              <i class="fa fa-circle cRed" *ngIf="!eloviewDeviceStatus"></i>              
            </div>           
          </div>
          
        </div> 
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label name="lblMdlUnitNum" class="control-label">Elevator Unit Number(s)</label>
              <div>
                <table aria-label="table" class="table-ml">
                  <thead>
                    <th id="unitNum">Unit Number</th>
                    <th id="contract">Contract</th>
                    <th id="stops">Stops</th>
                    <th id="city">City</th>
                  </thead>
                  <tr *ngFor="let unitNum of unitNumbers">
                    <td>{{ unitNum.elevatorUnitNumber }}</td>
                    <td>{{ unitNum.contract }}</td>
                    <td>{{ unitNum.stops }}</td>
                    <td>{{ unitNum.city }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <span style="font-size: small;padding-right: 175px;">
        <i class="fa fa-circle cGreen"></i> : Online |
        <i class="fa fa-circle cRed"></i> : Offline
      </span>
      
      <button [disabled]="!isBtnStartEpal" type="button" class="form-group btn btn-primary mr-1"
        (click)="onEpalCommForTestBld(true)" tabindex="11">
        Start ePAL Test
      </button>
      <button type="button" class="form-group btn btn-primary" (click)="onInstallApkToDevice()" tabindex="13">
        Install Content To Device
      </button>
      <button type="button" class="form-group btn btn-primary" (click)="onDeviceReboot()" tabindex="14">
        Reboot Device
      </button>      
      <button [disabled]="!isBtnEndEpal" type="button" class="form-group btn btn-primary mr-1"
        (click)="onEPALCommAndDeComm()" tabindex="12">
        End ePAL Test
      </button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('Cross click')" tabindex="15">
        Close
      </button>
    </div>      
    <label class="mr-2" id="lblUpdDevice" name="lblPRProcess" (click)="open(UpdateEloViewDevice)" tabindex="4" [hidden]="true"><span>
      <em class="fa fa-angle-double-right"></em></span>
    Open</label>
  </ng-template>
</div>

<!-- ePALHardwareId Decommission Modal Popup HTML -->
<ng-template #DecommModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">ePAL Decommission</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12">
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label name="lblDMLePALHwId" class="control-label">ePAL HardwareId :</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <input id="txtDMLePALHWId" type="search" class="form-control" name="txtDMLePALHWId"
              [(ngModel)]="txtEpalHardwareId" (keydown.enter)="getBuildingUnitsSearch()" maxlength="32" tabindex="1" />
          </div>
          <div class="lblerror" *ngIf="lblDeComErrorePALHardwareId">
            {{ this.lblDeComErrorePALHardwareId }}
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <button class="btn btn-primary" name="btnDMLePALHWIDSearch" (click)="getBuildingUnitsSearch()" tabindex="2">
              Search
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label name="lblMdlUnitNum" class="control-label"> <strong><u> Building And Unit
                Details</u></strong></label>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label name="lblePALSrchBldId" class="control-label">Building Id : </label>
            <label name="lblePALSrchBldId" class="control-label">{{ePALBuildingDetails.buildingId}}</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label name="lblePALSrchBldId" class="control-label">Building Name : </label>
            <label name="lblePALSrchBldId" class="control-label">{{ePALBuildingDetails.buildingName}}</label>
          </div>
        </div>
        <div class="col-5">
          <div class="form-group">
            <label name="lblePALSrchBldId" class="control-label">Eloview Serial Number : </label>
            <label name="lblePALSrchBldId" class="control-label">{{ePALBuildingDetails.eloviewSerialNumber}}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div>
              <table aria-label="table" class="table-ml">
                <thead>
                  <th id="unitNumber">Unit Number</th>
                  <th id="cpiduuid">CP-ID/UUID</th>
                  <th id="iotComm">Iot Commissioned</th>
                </thead>
                <tr *ngFor="let unitNum of ePALBuildingDetails.Units">
                  <td>{{ unitNum.unitNumber }}</td>
                  <td>{{ unitNum.cpidUuid }}</td>
                  <td>{{ unitNum.isIotCommissioned }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" name="btnDecommission" class="btn btn-primary mr-2" (click)="onDecommissionePALHardwareId()">
      Decommission
    </button>
    <button type="button" name="btnDMLClose" class="btn btn-outline-primary mr-2" (click)="modalClose('Save click')">
      Close
    </button>
  </div>
</ng-template>

<!-- ePAL Gateway Commission Modal Popup HTML -->
<ng-template #ePalGatewayModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">ePAL Gateway</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalClose('Cross click')">
      <em class="fa fa-window-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12 search-ft p-0 mt-3">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label name="lblGWDeviceMLType" class="control-label required">Device Gateway Type
            </label>
            <select name="ddGWDeviceMLType" [(ngModel)]="devicegatewayTypeSelId" class="form-control">
              <option *ngFor="let type of Devicegateway" [value]="type.id"
                [selected]="type.id === devicegatewayTypeSelId" [selected]="type.value === gatewayTypeSelectedValue">
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label name="lblGWMLType" class="control-label required">Gateway Type
            </label>
            <select name="ddEGWMLType" class="form-control" (change)="gatewayTypeSelectChanged($event)">
              <option *ngFor="let type of gatewayTypes" [value]="type.id"
                [selected]="type.id === gatewayTypeSelectedId">
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label name="lblGWMlMacAdd" class="control-label required">Gateway Mac Address</label>
            <input type="text" class="form-control" [disabled]="gatewayUpdateEnabled" name="txtGWMlMacAdd"
              [(ngModel)]="txtGWMlMacAdd" maxlength="20" />
          </div>
          <div class="lblerror" *ngIf="lblErrorGWMLMacAdd">
            {{ this.lblErrorGWMLMacAdd }}
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label name="lblGWMlIccId" class="control-label">SIM Card ICC ID</label>
            <input type="text" class="form-control" name="txtGWMlIccId" [(ngModel)]="txtGWMlIccId" maxlength="30"
              (focus)="clearErrorMessages()" />
          </div>
        </div>
        <div class="col">
          <label name="lblGWPwd" class="control-label">Gateway Password </label>
          <input type="password" class="form-control" name="txtGWPwd" [(ngModel)]="txtGWPwd" maxlength="20"
            (focus)="clearErrorMessages()" />
        </div>
      </div>
      <div classs="col-12">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label name="lblGWMlMacAdd" class="control-label">Otis ONE Compatible Firmware</label>
              <input type="text" readonly class="form-control" name="TxtECfwVersion" maxlength="32"
                [(ngModel)]="TxtECfwVersion" />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label name="lblSuggestedVersion" class="control-label">Running Firmware Version
                from DRM </label>
              <input type="text" disabled="true" class="form-control" name="txtECSuggestedVersion"
                [(ngModel)]="txtECSuggestedVersion" maxlength="15" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group" *ngIf="lblVersionMessage" [ngClass]="isVersionMatch ? 'greenlabel' : 'redlabel'">
          <label name="lblmessage">{{ lblVersionMessage }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label name="lblMdlUnitNum" class="control-label">Elevator Unit Number(s)</label>
            <div>
              <table aria-label="table" class="table-ml">
                <thead>
                  <th id="unitNum">Unit Number</th>
                  <th id="contract">Contract</th>
                  <th id="stops">Stops</th>
                  <th id="city">City</th>
                </thead>
                <tr *ngFor="let unitNum of unitNumbers">
                  <td>{{ unitNum.elevatorUnitNumber }}</td>
                  <td>{{ unitNum.contract }}</td>
                  <td>{{ unitNum.stops }}</td>
                  <td>{{ unitNum.city }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label name="lblMdlEpalGateway" class="control-label">ePAL Gateway(s)</label>
            <div>
              <table aria-label="table" class="table-ml">
                <thead>
                  <th id="buildingId">Building Id</th>
                  <th id="buildingName">Building Name</th>
                  <th id="deviceGatewayType">Device Gateway Type</th>
                  <th id="gatewayType">Gateway Type</th>
                  <th id="ePALMacAddress">ePAL Gateway MacAddress</th>
                  <th id="ePALGatewayDelink">Action</th>
                </thead>
                <tr *ngFor="let gatewayInfo of ePALCommissionedGateways">
                  <td>{{ gatewayInfo.buildingId }}</td>
                  <td>{{ gatewayInfo.buildingName }}</td>
                  <td>{{ gatewayInfo.deviceGatewayType }}</td>
                  <td>{{ gatewayInfo.gatewayType }}</td>
                  <td>{{ gatewayInfo.gatewayMacAddress }}</td>
                  <td> <a href="javascript:void(0);" (click)="onEpalDecommission(gatewayInfo)">Decommission</a></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- Buttons -->
    <button type="button" class="btn btn-primary mr-2" (click)="AssignEpalGateway($event)">
      Assign ePAL Gateway
    </button>
    <button type="button" class="btn btn-primary mr-2" (click)="onUpdateFirmware($event)"
      [disabled]="!gatewayUpdateEnabled">
      Get Firmware Version
    </button>
    <button type="button" class="btn btn-primary mr-2" (click)="onReset($event)">
      Reset
    </button>
    <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose('Cross click')">
      Close
    </button>
  </div>
</ng-template>

<ng-template #UpdateEloViewDevice let-modal>  
  <div id="updateDevice" class="modal-body eloUpdateStyle">
    <div class="col-12 search-ft p-2 mt-3">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="lblInfo"> ePAL Device Already Exists in EloView as:</label>
            <br>
            <label class="lblDevInfo">{{ temptxtEloViewDevice }}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="lblInfo">Would you like to update the device info to:</label> 
            <br> 
            <label class="lblDevInfo">{{ lblNewDeviceName }}</label> 
          </div>
        </div>
      </div>
      <div class="row col-6 mx-auto d-flex justify-content-center">
        <button type="button" class="btn btn-primary mr-2 btnwidth" (click)="onEpalCommForTestBld(false)">Yes</button>
        <button type="button" id="btnClose" class="btn btn-outline-primary mr-2 btnwidth" (click)="modal.close('Save click')">No</button>
      </div>
    </div>
  </div>  
</ng-template>
