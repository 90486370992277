<div id="gateway-main-wraper">
  <div class="col-12 page-heading p-0">
    <h5>Gateway</h5>
  </div>
  <div class="col-12 search-ft p-0 mt-3">
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label name="lblGWSrchUnit" class="control-label">Unit Number / Building Name /
            Address</label>
          <input type="text" class="form-control" name="txtGWSrchUnit" [(ngModel)]="txtGWSrchUnit" maxlength="20"
            tabindex="1" (keydown.enter)="onKeyEnter()" />
        </div>
      </div>
      <div class="col d-flex align-items-end">
        <div class="form-group">
          <button class="btn btn-primary mr-2" name="btnGWSearch" (click)="getGridDataByFilterSearch()" tabindex="2">
            Search
          </button>
          <button class="btn btn-outline-primary mr-2" name="btnGWReset" (click)="resetFilters()" tabindex="3">
            Reset
          </button>
        </div>
        <div id="gridActions" class="ml-auto">
          <label class="mr-2" tabindex="8" name="firmware" *ngIf="isAdmin" (click)="open(firmwareModal)"><span><em
                class="fa fa-download"></em></span> Firmware</label>
          <label *ngIf="isRowSelected" class="mr-2" name="lblAsgnGateway" (click)="open(gatewayModal)"
            tabindex="8"><span><em class="fa fa-link"></em></span> Assign Gateway</label>
          <label *ngIf="!isRowSelected" class="mr-2" name="lblAsgnGateway"
            title="Select the unit to assign gateway"><span> <em class="fa fa-link"></em></span> Assign Gateway</label>
          <label *ngIf="isRowSelected" class="mr" name="lblAsgnGateway" (click)="open(decommModal)"
            tabindex="8"><span><em class="fa fa-unlink"></em></span> Decommission</label>
          <label *ngIf="!isRowSelected" class="mr" name="lblAsgnGateway"
            title="Select the unit to decommission gateway"><span> <em class="fa fa-unlink"></em></span>
            Decommission</label>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ag-grid-angular [enableCellTextSelection]="true" class="ag-theme-material mt-2 gateway-layout" [rowData]="rowData"
      rowSelection="multiple" [columnDefs]="columnDefs" [gridOptions]="gridOptions"
      (rowSelected)="onRowSelected($event)">
    </ag-grid-angular>
    <span style="position: absolute;bottom:10px;font-size: small;">
      <i class="fa fa-circle cGreen"></i> : Online |
      <i class="fa fa-circle cRed"></i> : Offline
    </span>
  </div>
  

  <!-- Gateway Modal Popup HTML -->
  <ng-template #gatewayModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Assign Gateway</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-0 mt-0">
        <div class="row" id="divUnitGateway">
          <div class="col-6">
            <div class="form-group">
              <label name="lblUDGW" class="control-label">Device Using Gateway : {{ unitdeviceGatewayType
                }}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label name="lblGWMlUnitNum" class="control-label">Elevator Unit Number(s)</label>
              <div style="width: 100%; overflow: auto;max-height: 80px;">
                <table class="table-ml">
                  <tr>
                    <th id="unitNum">Unit Number</th>
                    <th id="bldName">Building Name</th>
                    <th id="cpiduuid">CP-ID/UUID</th>
                    <th id="cpiduuid2" *ngIf="isDoubleDeck">CP-ID/UUID2</th>
                  </tr>
                  <tr *ngFor="let row of rowSelectedData">
                    <td>{{ row["elevatorUnitNumber"] }}</td>
                    <td>{{ row["buildingName"] }}</td>
                    <td>{{ row["cpidUuid"] }}</td>
                    <td *ngIf="isDoubleDeck">{{ row["cpidUuid2"] }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label name="lblGWMLType" class="control-label required">Type
              </label>
              <select [disabled]="!gatewayUpdateEnable" name="ddGWMLType" class="form-control"
                (change)="gatewayTypeSelectChanged($event)">
                <option *ngFor="let type of gatewayTypes" [selected]="type.id === gatewayTypeSelectedId"
                  [value]="type.id">
                  {{ type.name }}
                </option>
              </select>
            </div>
            <div class="lblerror" *ngIf="lblErrorGWMLType">
              {{ this.lblErrorGWMLType }}
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblGWMlMacAdd" class="control-label required">Gateway Mac Address</label>
              <input [disabled]="!gatewayUpdateEnable" type="text" class="form-control" name="txtGWMlMacAdd"
                [(ngModel)]="txtGWMlMacAdd" maxlength="20" />
            </div>
            <div class="lblerror" *ngIf="lblErrorGWMLMacAdd">
              {{ this.lblErrorGWMLMacAdd }}
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblGWPwd" class="control-label">Gateway Password </label>
              <input type="password" class="form-control" name="txtGWPwd" [(ngModel)]="txtGWPwd" maxlength="20"
                (focus)="clearErrorMessages()" />
            </div>
          </div>          
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label name="lblGWMlIccId" class="control-label">SIM Card ICC ID1 : AT&T</label>
              <input type="text" class="form-control" name="txtGWMlIccId" [(ngModel)]="txtGWMlIccId" maxlength="30"
                (focus)="clearErrorMessages()" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblGWMlIccId2" class="control-label">SIM Card ICC ID2 : Verizon</label>
              <input type="text" class="form-control" name="txtGWMlIccId" [(ngModel)]="txtGWMlIccId2" maxlength="30"
                (focus)="clearErrorMessages()" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblGWMlMacAdd" class="control-label">Otis ONE Compatible Firmware</label>
              <input type="text" readonly class="form-control" name="TxtGMfwVersion" maxlength="32"
                [(ngModel)]="TxtGMfwVersion" />
            </div>
          </div>                 
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label name="lblSuggestedVersion" class="control-label">Running Firmware Version
                from DRM </label>
              <input type="text" disabled="true" class="form-control" name="txtSuggestedVersion"
                [(ngModel)]="txtSuggestedVersion" maxlength="15" />
            </div>
          </div> 
          <div class="col">
            <div class="form-group">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                </div>
              </div>
        </div>
        <div class="row">
          <div class="form-group" *ngIf="lblVersionMessage" [ngClass]="isBtnEndGateway ? 'greenlabel' : 'redlabel'">
            <label name="lblmessage">{{ lblVersionMessage }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="onAssignGateway($event)">
        Assign Gateway
      </button>
      <button type="button" class="btn btn-primary mr-2" (click)="onUpdateFirmware($event)"
        [disabled]="gatewayUpdateEnable">
        Get Firmware Version
      </button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose()">
        Close
      </button>
    </div>
  </ng-template>

  <!-- Decommission Modal Popup HTML -->
  <ng-template #decommModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Gateway Decommission</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose()">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-2 mt-3">
        <div class="row">
          <div class="col">
            <div class="form-group">
              Are you sure want to Gateway Decommission for the selected Unit
              ..?
              <p class="text-style"></p>
              <p class="text-style">
                Unit Number : {{ rowSelectedData[0]["elevatorUnitNumber"] }}
              </p>
              <p class="text-style">
                CP-ID/UUID : {{ rowSelectedData[0]["cpidUuid"] }}
              </p>
              <p class="text-style" *ngIf="isDoubleDeck">
                CP-ID/UUID2 : {{ rowSelectedData[0]["cpidUuid2"] }}
              </p>
              <p class="text-style">
                Device Using Gateway :
                {{ unitdeviceGatewayType }}
              </p>
              <p class="text-style">
                Gateway Mac Address :
                {{ rowSelectedData[0]["gatewayMacAddress"] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" name="btnUMMLYes" class="btn btn-primary mr-2" (click)="OnDecommissioinGateway()">
        Decommission
      </button>
      <button type="button" name="btnUMMLNo" class="btn btn-outline-primary mr-2" (click)="modalClose()">
        Close
      </button>
    </div>
  </ng-template>

  <!-- Firm ware Modal Popup HTML -->
  <ng-template #firmwareModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Firmware</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalClose(true)">
        <em class="fa fa-window-close"></em>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12 search-ft p-0 mt-6">
        <div class="row">
          <div class="col">
            <label name="lblGWMlUnitNum" class="control-label">Current Firmware Versions</label>
            <div style="width: 100%; overflow: auto">
              <table class="table-ml">
                <tr>
                  <th id="unitNum">Gateway Type</th>
                  <th id="bldName">Firmware Version</th>
                  <th id="bldName">Created By</th>
                </tr>
                <tr *ngFor="let row of gatewayTypes">
                  <td>{{ row.name }}</td>
                  <ng-container *ngFor="let fmv of GWFMVersion">
                    <td *ngIf="fmv.gatewayTypeId == row.id">{{fmv.firmwareVersion}}</td>
                    <td *ngIf="fmv.gatewayTypeId == row.id">{{fmv.createdBy}}</td>
                  </ng-container>
                </tr>
              </table>
              <br>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label name="lblGWMlMacAdd" class="control-label required">New Firmware
                Version</label>
              <input type="text" class="form-control" name="txtGWFirmwareVersion" [(ngModel)]="txtGWFirmwareVersion"
                maxlength="32" (focus)="clearErrorMessages()" />
            </div>
            <div class="lblerror" *ngIf="lblErrorGWFWVersionAdd">
              {{ this.lblErrorGWFWVersionAdd }}
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label name="lblGWMLType" class="control-label required">Type
              </label>
              <select name="ddGWMLType" class="form-control" (change)="fmGatewayTypeSelectChanged($event)">
                <option *ngFor="let type of gatewayTypes" [value]="type.id"
                  [selected]="type.id === gatewayTypeSelectedId">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="gatewayFirmwareSubmit()">
        Submit
      </button>
      <button type="button" class="btn btn-outline-primary mr-2" (click)="modalClose(true)">
        Close
      </button>
    </div>
  </ng-template>
</div>