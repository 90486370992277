import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Config } from '../common/models/config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  appConfig = new Config();
  loaded:boolean = false;  
  constructor(private httpClient: HttpClient) { }

  loadAppConfig() {    
    this.appConfig.ClientId=environment.ClientId;
    this.appConfig.Issuer=environment.Issuer;
    this.appConfig.TokenEndpoint=environment.TokenEndpoint;
    this.appConfig.LoginUrl=environment.LoginUrl;
    this.appConfig.Scope=environment.Scope;
    this.appConfig.PasswordResetUrl=environment.PasswordResetUrl;
    this.appConfig.PasswordResetErrorCode=environment.PasswordResetErrorCode;
    this.appConfig.DiscoveryDocumentUrl=environment.DiscoveryDocumentUrl;
    this.appConfig.ResponseType=environment.ResponseType;
    this.appConfig.SkipIssuerCheck=environment.SkipIssuerCheck;
    this.appConfig.ClearHashAfterLogin=environment.ClearHashAfterLogin;
    this.appConfig.Oidc=environment.Oidc;
    this.appConfig.ApiUrl=environment.ApiUrl;
    this.appConfig.APPINSIGHTS_INSTRUMENTATIONKEY=environment.APPINSIGHTS_INSTRUMENTATIONKEY;
    this.loaded=true;    
  }

  get clientId() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.ClientId;
  }

  get issuer() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.Issuer;
  }

  get tokenEndpoint() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.TokenEndpoint;
  }

  get loginUrl() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.LoginUrl;
  }

  get scope() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.Scope;
  }

  get passwordResetUrl() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.PasswordResetUrl;
  }

  get passwordResetErrorCode() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.PasswordResetErrorCode;
  }

  get DiscoveryDocumentUrl() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.DiscoveryDocumentUrl;
  }

  get redirectUri() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return window.location.origin;
  }

  get responseType() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.ResponseType;
  }

  get strictDiscoveryDocumentValidation() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return false;
  }

  get skipIssuerCheck() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.SkipIssuerCheck;
  }

  get clearHashAfterLogin() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.ClearHashAfterLogin;
  }

  get oidc() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.Oidc;
  }

  get serverUrl() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.ApiUrl;
  }

  get AppName() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.AppName;
  }

  get AppVersion() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.AppVersion;
  }

  get CIMSVersion() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.CIMSVersion;
  }

  get G3MSVersion() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.G3MSVersion;
  }

  get BuildDate() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.BuildDate;
  }
  get GetAIInstrKey() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.APPINSIGHTS_INSTRUMENTATIONKEY;
  }
}

