import { Injectable } from '@angular/core';

@Injectable()
export class unitSearchService {
  constructor() { }

  getUpdatedUnitSearch(txtUnitSearch: string): string {
    var unitTypes = [] = ['HYD', 'G2S', 'G2O', 'G3C', 'MOD'];
    let i = 0;
    var updatedUnit = txtUnitSearch;

    while (unitTypes[i]) {
      if (txtUnitSearch.indexOf('-') > -1 && txtUnitSearch.toUpperCase().indexOf(unitTypes[i]) > -1) {
        updatedUnit = txtUnitSearch.split('-')[1].trim().substring(0, 6).trim().toUpperCase();
        if (updatedUnit.startsWith('N')) {
          updatedUnit = 'U' + updatedUnit.trim().substring(1, 6).trim().toUpperCase()
        }
      }
      i++;
    }
    return updatedUnit;
  }
}
