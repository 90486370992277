import { Injectable } from "@angular/core";
@Injectable()
export class FCError {
    code?: number;
    message?: string;
    status?: string;
    error?: string;
    errorid? : string;
    trackingid? : string;
}
