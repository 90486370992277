<div class="row">
  <div class="col-12">
    <div class="page-heading mt-2">
      <h5>Open Source Software Licenses</h5>
    </div>
  </div>
  <div class="col-12" id="licence-page">
    <div class="col-3 mt-3 p-0">
      <div class="form-group">
        <select name="ddLicenseName" class="form-control" [(ngModel)]="selectedSoftware.id" tabindex="2"
          (change)="ossoftwareSelectChanged($event)">
          <option value="0" selected> --- Select Open Source Software --- </option>
          <option *ngFor="let listItem of softwareList" [ngValue]="listItem.id">{{listItem.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-12 licence-cnt">
    <div class="card">
      <div class="card-header">
        <strong>{{ licenseName }}</strong>
      </div>
      <div class="card-body licence-cnt-cus">
        <p class="text-style">{{ licenseInfo }}</p>
      </div>
    </div>
  </div>
</div>
