<div id="g3msunitsearch-main-wraper">
  <div class="col-12 page-heading p-0">
    <h5>G3MS Unit Search</h5>
  </div>
  <div class="col-12 search-ft p-0 mt-3">
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label name="lblG3MSSrch" class="control-label">Unit Number / Building Name /
            Address</label>
          <input type="text" class="form-control" name="txtG3MSSrch" [(ngModel)]="txtG3MSSrch" maxlength="20"
            tabindex="1" (keydown.enter)="onKeyEnter()">
        </div>
      </div>
      <div class="col-sm-3 d-inline-flex p-0">
        <div class="col d-flex align-items-start">
          <div class="form-group">
            <button class="btn btn-primary mr-2" name="btnG3MSSearch" (click)="getGridDataOnSearch()"
              tabindex="2">Search</button>
            <button class="btn btn-outline-primary mr-2" name="btnG3MSReset" (click)="resetFilters()"
              tabindex="3">Reset</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <ag-grid-angular #agGridGateway [enableCellTextSelection]="true" class="ag-theme-material mt-2 g3msunitsearch-layout" [rowData]="rowData"
    [columnDefs]="columnDefs" [gridOptions]="gridOptions" tabindex="4">
  </ag-grid-angular>
</div>