import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';

@Injectable()
export class NgbStringAdapter extends NgbDateAdapter<Date> {

    fromModel(date: Date): NgbDateStruct {
        return date ? {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        } : null;
    }

    toModel(date: NgbDateStruct): Date {
        return date ? new Date(date.year, date.month - 1, date.day) : null;
    }

    toMinDate(date: NgbDateStruct): Date {
        return date ? new Date(date.year, date.month - 1, date.day, 0, 0, 0) : null;
    }

    toMaxDate(date: NgbDateStruct): Date {
        var now = new Date();
        var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

        if (now >= utc)
            return date ? new Date(Date.UTC(date.year, date.month - 1, date.day, 23, 59, 59)) : null;
        else
            return date ? new Date(Date.UTC(date.year, date.month - 1, date.day + 1, 23, 59, 59)) : null;
    }

    formatToMinDate(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd 00:00:00', 'en');
    }

    formatToMaxDate(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd hh:mm:ss', 'en');
    }
}