export class EpalUnitDetails {
  buildingId: string;
  buildingName : string;
  eloviewSerialNumber : string;
  Units : UnitDetails[];
}

class UnitDetails
{
  unitNumber?: string;
  cpidUuid?: string;
  isIotCommissioned?: string;
}
