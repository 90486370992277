import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { OAuthService } from 'angular-oauth2-oidc';
import { map } from 'rxjs/internal/operators/map';
import { FCError } from '../common/models/fcerror';
import { FCToasterService } from '../common/toaster/FCToasterService';
import { LoggingService } from './logging.service';
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { AppConfigService } from './app-config.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    properties:any=[];
    constructor(private oauthService: OAuthService, private fcerror: FCError, 
        private toastr: FCToasterService, private loggingService: LoggingService, private appConfigService: AppConfigService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {       
        let claims = this.oauthService.getIdentityClaims();
        if(claims != null && claims !== undefined)
        {
          this.properties["request-uid"]=claims["oid"];
        }
        if(this.appConfigService.loaded)
        {
          this.loggingService.logTrace(req.body,this.properties);
        }
        return next.handle(req).pipe(
            map((response: HttpEvent<any>) => {
                if (response instanceof HttpResponse) {
                    const responseBody = response.clone({ body: response.body });
                    return responseBody;
                }
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.oauthService.logOut();
                }
                return throwError(this.handleError(error));
            })
        );
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // client-side error
            this.fcerror.message = `Error: ${error.error.message}`;
        }
        else {
            // server-side error
            this.fcerror.code = error.status;
            this.fcerror.status = error.statusText;
            this.fcerror.error = error.error !== null ? error.error : "";
            this.fcerror.errorid = error.headers.get('x-error-id')
            this.fcerror.trackingid = error.headers.get('x-correlation-id');
            this.fcerror.message = `Error Id: ${this.fcerror.errorid !== null ? this.fcerror.errorid : ""} <br/> Error Message: ${this.fcerror.error["error"] ? this.fcerror.error["error"] : (this.fcerror.error !== "" ? this.fcerror.error : this.fcerror.status)}`;
        }
        this.loggingService.logException(error.error, SeverityLevel.Error);
        if (this.fcerror && this.fcerror.message.length > 0 && this.fcerror.code !== 401) {
            this.toastr.showError(this.fcerror.message);
        }
    }
}
